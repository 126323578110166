import React, { Component, useEffect, useState } from 'react';
import Loading from '../Loading';
import Modal from 'react-modal';
import { APP_OPTIONS, Colors, EXTERNAL_ORGS, EXTERNAL_APPROVALS, ORGANIZATION_TYPES, validateEmail, validatePhone, validateOfficeOrCellPhone } from '../../constants';
import { Fragment } from 'react';
import { GetLoggedInUserInfo, GetLoggedInUserToken, HttpRequest, HttpRequestTypes } from '../../config/GeneralMethods';
import { ENV } from '../../config';
import { confirmAlert } from 'react-confirm-alert';
import AdminDocumentsList from './AdminDocumentsList';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import axios from 'axios';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import AdminExternalOrgsList from './AdminExternalOrgsList';

const animatedComponents = makeAnimated();


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 500,
        maxWidth: '45vw'
    }
};

// class AdminExternalOrgs extends Component {
//     state = {
//         documents: [],
//         categories: [],
//         isLoading: true,
//         isModalVisible: false,
//         successMessage: "",
//         errorMessage: "",
//         selectedDocument: null,
//         name: "",
//         council: "",
//         url: "",
//         categoriesSelected: [],
//         isSaving: false,
//         selectedConstructionStep: [],
//         selectedConstructionType: [],
//         file: null,
//         fileName: "",
//         councils: [],
//         allCouncils: [],
//         region: "",
//         regions: [],
//         constructionSteps: [],
//         constructionTypes: [],
//         selectedOrganizationTypes: [{ label: ORGANIZATION_TYPES[0], value: ORGANIZATION_TYPES[0] }],
//         externalOrgs: [{ label: EXTERNAL_ORGS[0], value: EXTERNAL_ORGS[0] }],
//         externalApprovals: [{ label: EXTERNAL_APPROVALS[0], value: EXTERNAL_APPROVALS[0] }],
//         certificates: [],
//         categoriesTitles: []
//     }

//     componentDidMount() {
//         this.loadDocuments();
//         this.loadCategories();
//         this.loadCategoriesTitles();
//         this.loadConcils();
//         this.loadConstructionTypes();
//         this.loadConstructionSteps();
//     }

//     loadDocuments = async () => {
//         try {
//             let documents = [];

//             let url = `${ENV.API_URL}/${ENV.FILES_URL}/get-all-admin`;
//             let res = await HttpRequest(url, null, HttpRequestTypes.GET, GetLoggedInUserToken());

//             if (res && res.success) {
//                 let data = res.data || [];

//                 if (data.length > 0) {
//                     documents = data;
//                 }
//             }

//             this.setState({ documents, isLoading: false });
//         } catch (e) {
//             console.log(e);
//         }
//     }

//     loadCategories = async () => {
//         try {
//             let categories = [];

//             let url = `${ENV.API_URL}/${ENV.CATEGORIES_URL}/get-all`;
//             let res = await HttpRequest(url, null, HttpRequestTypes.GET, GetLoggedInUserToken());

//             if (res && res.success) {
//                 let data = res.categories || [];

//                 if (data.length > 0) {
//                     categories = [
//                         { name: "כללי" },
//                         ...data
//                     ];
//                 }
//             }

//             this.setState({ categories })

//         } catch (e) {
//             console.log(e);
//         }
//     }

//     loadCategoriesTitles = async () => {
//         try {
//             let categories = [];

//             let url = `${ENV.API_URL}/${ENV.CATEGORIES_URL}/get-all-freelancers-titles`;
//             let res = await HttpRequest(url, null, HttpRequestTypes.GET, GetLoggedInUserToken());

//             if (res && res.success) {
//                 categories = res.categories || [];
//             }

//             this.setState({ categoriesTitles: categories })

//         } catch (e) {
//             console.log(e);
//         }
//     }

//     loadConcils = async () => {
//         try {
//             let url = `${ENV.API_URL}/${ENV.CONCILS_URL}/get-list-by-admin`;
//             let res = await HttpRequest(url, null, HttpRequestTypes.GET, GetLoggedInUserToken());

//             if (res) {
//                 let { regions, councils } = res;

//                 let allCouncils = [...councils];
//                 let region = regions && regions.length > 0 ? regions[0]._id : "";
//                 councils = councils.filter(val => val.regionId === region);
//                 let council = councils && councils.length > 0 ? councils[0].name : "";

//                 this.setState({ councils, council, allCouncils, region, regions });
//             }
//         } catch (e) {
//             console.log(e);
//         }
//     }

//     loadConstructionSteps = async () => {
//         try {
//             let url = `${ENV.API_URL}/${ENV.CONSTRUCTION_STEPS_URL}/get-all`;
//             let res = await HttpRequest(url, null, HttpRequestTypes.GET, GetLoggedInUserToken());

//             if (res) {
//                 let { constructionSteps } = res;
//                 let selectedConstructionStep = constructionSteps && constructionSteps.length > 0 ? constructionSteps[0].name : "";

//                 this.setState({ constructionSteps, selectedConstructionStep });
//             }
//         } catch (e) {
//             console.log(e);
//         }
//     }

//     loadConstructionTypes = async () => {
//         try {
//             let url = `${ENV.API_URL}/${ENV.CONSTRUCTION_TYPES_URL}/get-all`;
//             let res = await HttpRequest(url, null, HttpRequestTypes.GET, GetLoggedInUserToken());

//             if (res) {
//                 let { constructionTypes } = res;
//                 let selectedConstructionType = constructionTypes && constructionTypes.length > 0 ? constructionTypes[0].name : "";

//                 this.setState({ constructionTypes, selectedConstructionType });
//             }
//         } catch (e) {
//             console.log(e);
//         }
//     }

//     addNewDocument = () => {
//         this.setState({
//             selectedDocument: null, selectedConstructionStep: [], selectedConstructionType: [], categoriesSelected: [], name: "",
//             selectedOrganizationTypes: [{ label: ORGANIZATION_TYPES[0], value: ORGANIZATION_TYPES[0] }],
//             externalOrgs: [{ label: EXTERNAL_ORGS[0], value: EXTERNAL_ORGS[0] }],
//             externalApprovals: [{ label: EXTERNAL_APPROVALS[0], value: EXTERNAL_APPROVALS[0] }],
//             certificates: []
//         });

//         setTimeout(() => {
//             this.setState({ isModalVisible: true })
//         }, 300);
//     }

//     editDocument = (id) => {

//         let { documents } = this.state;

//         documents = documents.filter(val => val._id === id);
//         let currentDocument = documents.length > 0 ? documents[0] : null;

//         if (currentDocument) {
//             let url = currentDocument.url || "";
//             let name = currentDocument.name || "";
//             let categoriesSelected = currentDocument.categories.map(val => { return { label: val, value: val } });
//             let council = currentDocument.council || "";
//             let selectedConstructionStep = currentDocument.constructionStep || [];
//             let selectedConstructionType = currentDocument.constructionType || [];
//             let selectedOrganizationTypes = currentDocument.selectedOrganizationTypes || [];
//             let externalOrgs = currentDocument.externalOrgs || [];
//             let externalApprovals = currentDocument.externalApprovals || [];
//             let certificates = currentDocument.certificates || [];

//             selectedConstructionStep = selectedConstructionStep.map(val => { return { label: val, value: val } });
//             selectedConstructionType = selectedConstructionType.map(val => { return { label: val, value: val } });
//             selectedOrganizationTypes = selectedOrganizationTypes.map(val => { return { label: val, value: val } });
//             externalOrgs = externalOrgs.map(val => { return { label: val, value: val } })
//             externalApprovals = externalApprovals.map(val => { return { label: val, value: val } })
//             certificates = certificates.map(val => { return { label: val, value: val } })

//             let { allCouncils } = this.state;
//             let councilObjArray = allCouncils.filter(val => val.name === council);

//             if (councilObjArray && councilObjArray.length > 0) {
//                 let councilObj = councilObjArray[0];

//                 let region = councilObj.regionId;
//                 let councils = allCouncils.filter(val => val.regionId === region);

//                 this.setState({ region, councils });
//             }

//             this.setState({ selectedDocument: currentDocument, url, name, categoriesSelected, council, selectedConstructionStep, selectedConstructionType, selectedOrganizationTypes, externalOrgs, externalApprovals, certificates });

//             setTimeout(() => {
//                 this.setState({ isModalVisible: true })
//             }, 300);
//         }
//     }

//     deleteDocument = async (id) => {
//         //SHOW POPUP MESSAGE
//         confirmAlert({
//             title: "מחיקת מסמך",
//             message: "פעולה זו תמחק את המסמך ללא אפשרות שחזור",
//             buttons: [
//                 {
//                     label: "לא, סגור",
//                     onClick: () => { },
//                 },
//                 {
//                     label: "כן",
//                     onClick: () => {
//                         this.removeDocument(id);
//                     },
//                 },
//             ],
//         });
//     }

//     removeDocument = async (id) => {
//         let url = `${ENV.API_URL}/${ENV.FILES_URL}/${id}`;
//         let res = await HttpRequest(url, null, HttpRequestTypes.DELETE, GetLoggedInUserToken());

//         if (res && res.success) {
//             this.loadDocuments();
//             this.loadCategories();
//             this.loadConcils();
//         }
//     }

//     closeModal = () => {
//         this.setState({ isModalVisible: false, successMessage: "" });
//     }

//     setSelectedCategories = (selected) => {
//         this.setState({ categoriesSelected: selected })
//     }

//     fileChanged = (event) => {
//         let selectedFile = event.target.files[0];
//         let fileName = selectedFile.name || "";

//         this.setState({ file: selectedFile, fileName });
//     }

//     submit = async (withTemplateCreation = false) => {
//         try {
//             this.setState({ errorMessage: "", successMessage: "", isSaving: true });

//             let { name, categoriesSelected, council, selectedConstructionStep, file, selectedDocument, selectedConstructionType, selectedOrganizationTypes, externalOrgs, externalApprovals, certificates } = this.state;

//             let isAdd = selectedDocument === null;

//             if (name.trim().length === 0) {
//                 this.setState({ errorMessage: "**חובה להזין שם המסמך" });
//             } else if (categoriesSelected.length === 0) {
//                 this.setState({ errorMessage: "**חובה לבחור קטגוריה אחת לפחות" });
//             } else if (council.length === 0) {
//                 this.setState({ errorMessage: "**חובה לבחור מועצה אחת לפחות" });
//             } else if (selectedConstructionStep.length === 0) {
//                 this.setState({ errorMessage: "**חובה לבחור שלב בניה אחד לפחות" });
//             } else if (selectedConstructionType.length === 0) {
//                 this.setState({ errorMessage: "**חובה לבחור סוג מבנה אחד לפחות" });
//             } else if (selectedOrganizationTypes.length === 0) {
//                 this.setState({ errorMessage: "**חובה לבחור מחלקה אחת לפחות" });
//             } else if (isAdd && file === null) {
//                 this.setState({ errorMessage: "**חובה להעלות קובץ" });
//             } else if (file !== null && file.type !== "application/pdf") {
//                 this.setState({ errorMessage: "**סוג קובץ לא נתמך, אפשר להעלות קבצי PDF בלבד" });
//             } else {

//                 let params = {
//                     name,
//                     categories: categoriesSelected.map(val => val.value),
//                     council,
//                     constructionStep: selectedConstructionStep.map(val => val.value),
//                     constructionType: selectedConstructionType.map(val => val.value),
//                     selectedOrganizationTypes: selectedOrganizationTypes.map(val => val.value),
//                     externalOrgs: externalOrgs.length === 0 ? [] : externalOrgs.map(val => val.value),
//                     externalApprovals: externalApprovals.length === 0 ? [] : externalApprovals.map(val => val.value),
//                     certificates: certificates.length === 0 ? [] : certificates.map(val => val.value)
//                 };

//                 let formData = new FormData();

//                 if (file) {
//                     formData.append("data", file)
//                 }

//                 for (const [key, value] of Object.entries(params)) {
//                     formData.append(key, value);
//                 }

//                 //Add
//                 if (isAdd) {
//                     let requestUrl = `${ENV.API_URL}/${ENV.FILES_URL}/add`;

//                     let response = await axios.post(requestUrl, formData, {
//                         headers: {
//                             "Content-Type": "multipart/form-data",
//                             'x-auth-token': GetLoggedInUserToken()
//                         }
//                     })

//                     let data = response.data;

//                     if (data) {
//                         if (data.success) {
//                             this.setState({ successMessage: "המסמך נוצר בהצלחה", name: "", file: null, fileName: "", url: "", categoriesSelected: [] });

//                             this.loadCategories();
//                             this.loadDocuments();

//                             setTimeout(() => {
//                                 this.setState({ successMessage: "" });

//                                 if (!withTemplateCreation) {
//                                     this.setState({ isModalVisible: false })
//                                 }
//                             }, 2000);

//                             if (withTemplateCreation) {
//                                 await this.createURLAndOpenInNewTab(data.result._id);
//                                 this.setState({ isModalVisible: false })
//                             }
//                         } else {
//                             this.setState({ errorMessage: data.message || "אירעה שגיאה, נא לנסות שנית" });
//                         }
//                     } else {
//                         this.setState({ errorMessage: "אירעה שגיאה, נא לנסות שנית" });
//                     }

//                 } else {
//                     //Edit
//                     let requestUrl = `${ENV.API_URL}/${ENV.FILES_URL}/edit`;

//                     formData.append("_id", selectedDocument._id);

//                     let response = await axios.put(requestUrl, formData, {
//                         headers: {
//                             "Content-Type": "multipart/form-data",
//                             'x-auth-token': GetLoggedInUserToken()
//                         }
//                     })

//                     let data = response.data;

//                     if (data) {
//                         if (data.success) {
//                             this.setState({ successMessage: "המסמך נערך בהצלחה", name: "", file: null, fileName: "", url: "", categoriesSelected: [] });

//                             this.loadCategories();
//                             this.loadDocuments();

//                             setTimeout(() => {
//                                 this.setState({ successMessage: "" });

//                                 if (!withTemplateCreation) {
//                                     this.setState({ isModalVisible: false })
//                                 }
//                             }, 2000);

//                             if (withTemplateCreation) {
//                                 await this.createURLAndOpenInNewTab(selectedDocument._id);
//                                 this.setState({ isModalVisible: false })
//                             }
//                         } else {
//                             this.setState({ errorMessage: data.message || "אירעה שגיאה, נא לנסות שנית" });
//                         }
//                     } else {
//                         this.setState({ errorMessage: "אירעה שגיאה, נא לנסות שנית" });
//                     }
//                 }
//             }

//         } catch (e) {
//             console.log(e);
//             let message = e.message || "";

//             if (typeof message !== typeof "") {
//                 message = JSON.stringify(message);
//             }

//             this.setState({ errorMessage: message });
//         } finally {
//             this.setState({ isSaving: false });
//         }
//     }

//     createURLAndOpenInNewTab = async (id) => {
//         let token = await this.makeTokenFromJson(id);
//         //Open new Tab with this token and Close Popup
//         let url = `${ENV.ELECTRONIC_SIGNATURE_URL}?t=${token}`;
//         window.open(url, "_blank");
//     }

//     regionChanged = (regionId) => {
//         let { allCouncils } = this.state;
//         let councils = allCouncils.filter(val => val.regionId === regionId);
//         let council = councils && councils.length > 0 ? councils[0].name : "";

//         this.setState({ council, councils, region: regionId })
//     }

//     renderModalContent = () => {
//         return (
//             <Fragment>

//                 <label style={{ color: 'black', marginLeft: 10 }}>
//                     שם המסמך:
//                 </label>

//                 <input type="text" name="name" className="form-control form-control-user" style={{ textAlign: 'right' }}
//                     aria-describedby="emailHelp" placeholder="שם המסמך  "
//                     onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} />

//                 <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
//                     בחירת קטגוריה:
//                 </label>

//                 <Select
//                     closeMenuOnSelect={false}
//                     components={animatedComponents}
//                     options={this.state.categories.map(item => { return { label: item.name, value: item.name } })}
//                     isMulti
//                     value={this.state.categoriesSelected}
//                     onChange={this.setSelectedCategories}
//                     placeholder="בחר קטגוריה/ות"
//                 />

//                 <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
//                     בחירת איזור:
//                 </label>

//                 <select className="form-control" onChange={(e) => this.regionChanged(e.target.value)}
//                     value={this.state.region}>
//                     {
//                         this.state.regions.map((val, ind) =>
//                             <option key={ind} value={val._id}>{val.name}</option>
//                         )
//                     }
//                 </select>

//                 <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
//                     בחירת מועצה:
//                 </label>

//                 <select className="form-control" onChange={(e) => this.setState({ council: e.target.value })}
//                     value={this.state.council}>
//                     {
//                         this.state.councils.map((val, ind) =>
//                             <option key={ind} value={val.name}>{val.name}</option>
//                         )
//                     }
//                 </select>

//                 <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
//                     בחירת שלב בנייה:
//                 </label>

//                 <Select
//                     closeMenuOnSelect={false}
//                     components={animatedComponents}
//                     options={this.state.constructionSteps.map(item => { return { label: item.name, value: item.name } })}
//                     isMulti
//                     value={this.state.selectedConstructionStep}
//                     onChange={(selected) => this.setState({ selectedConstructionStep: selected })}
//                     placeholder="בחירת שלב בנייה"
//                 />

//                 <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
//                     בחירת סוג מבנה:
//                 </label>

//                 <Select
//                     closeMenuOnSelect={false}
//                     components={animatedComponents}
//                     options={this.state.constructionTypes.map(item => { return { label: item.name, value: item.name } })}
//                     isMulti
//                     value={this.state.selectedConstructionType}
//                     onChange={(selected) => this.setState({ selectedConstructionType: selected })}
//                     placeholder="בחירת סוג מבנה"
//                 />

//                 <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
//                     בחירת מחלקה:
//                 </label>

//                 <Select
//                     closeMenuOnSelect={false}
//                     components={animatedComponents}
//                     options={ORGANIZATION_TYPES.map(item => { return { label: item, value: item } })}
//                     isMulti
//                     value={this.state.selectedOrganizationTypes}
//                     onChange={(selected) => this.setState({ selectedOrganizationTypes: selected })}
//                     placeholder="בחירת מחלקה"
//                 />

//                 <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
//                     בחירת גורם חיצוני:
//                 </label>

//                 <Select
//                     closeMenuOnSelect={false}
//                     components={animatedComponents}
//                     options={EXTERNAL_ORGS.map(item => { return { label: item, value: item } })}
//                     isMulti
//                     value={this.state.externalOrgs}
//                     onChange={(selected) => this.setState({ externalOrgs: selected })}
//                     placeholder="בחירת גורם חיצוני"
//                 />

//                 <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
//                     בחירת גורם מאשר:
//                 </label>

//                 <Select
//                     closeMenuOnSelect={false}
//                     components={animatedComponents}
//                     options={EXTERNAL_APPROVALS.map(item => { return { label: item, value: item } })}
//                     isMulti
//                     value={this.state.externalApprovals}
//                     onChange={(selected) => this.setState({ externalApprovals: selected })}
//                     placeholder="בחירת גורם מאשר"
//                 />

//                 <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
//                     בחירת תעודות / אישורים:
//                 </label>

//                 <Select
//                     closeMenuOnSelect={false}
//                     components={animatedComponents}
//                     options={this.state.categoriesTitles.map(item => { return { label: item.name, value: item.name } })}
//                     isMulti
//                     value={this.state.certificates}
//                     onChange={(selected) => this.setState({ certificates: selected })}
//                     placeholder="בחירת תעודות / אישורים"
//                 />

//                 <div className="text-left">
//                     <input type="file" accept="application/pdf" id="fileUpload" style={{ visibility: "collapse", width: 0 }} onChange={this.fileChanged} />

//                     <input type="text" name="fileName" className="form-control form-control-user" style={{ textAlign: 'right' }} disabled
//                         placeholder="שם הקובץ  " value={this.state.fileName} />

//                     <button className="btn prim-btn no-animation custom-box-shadow  mt-3" type="button" onClick={() => document.getElementById("fileUpload").click()}>
//                         העלאת קובץ
//                     </button>
//                 </div>


//                 {this.state.selectedDocument !== null ? (
//                     <>
//                         <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
//                             קישור הקובץ
//                         </label>

//                         <input type="text" name="url" className="form-control form-control-user" style={{ textAlign: 'right' }} disabled
//                             placeholder="קישור הקובץ  " onChange={(e) => this.setState({ url: e.target.value })} value={this.state.url} />
//                     </>
//                 ) : null}

//                 {
//                     this.state.isSaving ?
//                         <div style={{ marginTop: 25 }}>
//                             <Loading />
//                         </div>
//                         :
//                         <div className="text-center w-50 btn-send-email" style={{ marginTop: 25 }}>
//                             <input type="button" className="btn btn-user btn-custom btn-block" style={{ backgroundColor: Colors.PRIMARY_YELLOW }} value={this.state.selectedDocument === null ? "הוספה" : "שמירת שינויים"} onClick={() => this.submit(false)} />
//                             <input type="button" className="btn btn-user btn-custom btn-block" style={{ backgroundColor: Colors.PRIMARY_YELLOW }} value={this.state.selectedDocument === null ? "הוספה ובניית תבנית" : "שמירת שינויים ובניית תבנית"} onClick={() => this.submit(true)} />
//                         </div>
//                 }

//                 <div className="mt-3 text-right mr-2"><span className="text-success">{this.state.successMessage}</span></div>
//                 <div className="mt-3 text-right mr-2"><span className="text-danger">{this.state.errorMessage}</span></div>
//             </Fragment>
//         );
//     }

//     makeTokenFromJson = async (docId) => {
//         try {

//             let user = await GetLoggedInUserInfo();
//             let userId = user?.user?._id;

//             let bodyObj = {
//                 docId,
//                 tokenId: null,
//                 userId,
//                 app: APP_OPTIONS.ESIGN,
//                 tmc: true
//             }

//             let url = `${ENV.API_URL}/${ENV.USERS_API_URL}/makeTokenFromJson`;
//             let res = await HttpRequest(url, bodyObj, HttpRequestTypes.POST, GetLoggedInUserToken());

//             if (res) {
//                 return res.token;
//             }
//         } catch (e) {
//             console.error(e);
//         }
//     }

//     render() {
//         return (
//             <>
//                 <div className="mt-3 d-flex justify-content-between align-items-center home-container">

//                     <div className="">
//                         <button className="btn prim-btn no-animation custom-box-shadow" style={{}}
//                             onClick={this.addNewDocument}>
//                             הוספת מסמך חדש
//                         </button>
//                     </div>

//                     <div className="text-right">
//                         <h4 style={{ fontWeight: 'bold' }}>רשימת המסמכים</h4>
//                     </div>
//                 </div>

//                 <div className="mt-3 mb-3 dir-rtl">
//                     {
//                         this.state.isLoading ?
//                             <Loading />
//                             : <AdminDocumentsList
//                                 data={this.state.documents}
//                                 editDocument={this.editDocument.bind(this)}
//                                 deleteDocument={this.deleteDocument.bind(this)}
//                                 openTemplateScreen={this.createURLAndOpenInNewTab.bind(this)} />
//                     }
//                 </div>

//                 <Modal
//                     ariaHideApp={false}
//                     isOpen={this.state.isModalVisible}
//                     style={customStyles}>

//                     <div className='modal-content-container'>
//                         <div className="d-flex justify-content-between align-items-center">
//                             <div></div>
//                             <div>
//                                 <label className="labelModal">
//                                     {this.state.selectedDocument === null ? "הוספת מסמך חדש" : "עריכת פרטי מסמך"}
//                                 </label>
//                             </div>
//                             <div>
//                                 <i className="fa fa-times pointer" onClick={this.closeModal}></i>
//                             </div>
//                         </div>

//                         <div style={{ direction: 'rtl', marginTop: 20, textAlign: 'right' }}>
//                             {this.renderModalContent()}
//                         </div>
//                     </div>
//                 </Modal>

//             </>
//         )
//     }
// }

const FIELD_TYPES = {
    TEXT: "text",
    DATE: "date",
    DROP_DOWN: "dropDown"
};

const EXTERNAL_ORGS_ITEMS = EXTERNAL_ORGS
    .filter((_, index) => index !== 0)
    .map((item) => ({ label: item, value: item }));

const AdminExternalOrgs = () => {

    const [isAddEditModalVisible, setIsAddEditModalVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isFormSaving, setIsFormSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [externalOrgsList, setExternalOrgsList] = useState([]);
    const [selectedOrgListItem, setSelectedOrgListItem] = useState(null);
    const [allCouncils, setAllCouncils] = useState([]);
    const [regions, setRegions] = useState([]);
    const [councils, setCouncils] = useState([]);
    const [externalFormData, setExternalFormData] = useState([
        { title: "בחר גורם חיצוני", name: "name", value: "", type: FIELD_TYPES.DROP_DOWN, items: EXTERNAL_ORGS_ITEMS, isMulti: false, requiredErrorMessage: "חובה לבחור גורם חיצוני", isCustomValidationRequired: false },
        { title: "בחר אזור", name: "region", value: "", type: FIELD_TYPES.DROP_DOWN, items: [], isMulti: false, requiredErrorMessage: "חובה לבחור אזור", isCustomValidationRequired: false },
        { title: "בחר מועצה", name: "council", value: "", type: FIELD_TYPES.DROP_DOWN, items: [], isMulti: false, requiredErrorMessage: "חובה לבחור מועצה", isCustomValidationRequired: false },
    ])
    const [userFormData, setUserFormData] = useState([
        { title: "שם מלא", name: "fullName", value: "", type: FIELD_TYPES.TEXT, requiredErrorMessage: "חובה להזין שם מלא", isCustomValidationRequired: false },
        { title: "שם החברה", name: "companyName", value: "", type: FIELD_TYPES.TEXT, requiredErrorMessage: "חובה להזין שם החברה", isCustomValidationRequired: false },
        { title: "דוא״ל", name: "email", value: "", type: FIELD_TYPES.TEXT, requiredErrorMessage: "חובה להזין דוא״ל", isCustomValidationRequired: true, customValidationErrorMessage: "חובה להזין דוא״ל תקין" },
        { title: "טלפון", name: "phone", value: "", type: FIELD_TYPES.TEXT, requiredErrorMessage: "חובה להזין מס׳ טלפון", isCustomValidationRequired: true, customValidationErrorMessage: "חובה להזין מס׳ טלפון תקין" },
    ])

    useEffect(() => { }, [externalFormData])

    useEffect(() => {
        loadScreenData();
    }, [])

    const loadScreenData = () => {
        loadAllExternalOrgs();
        loadConcils();
    }

    const loadAllExternalOrgs = async () => {
        try {
            let url = `${ENV.API_URL}/${ENV.EXTERNAL_ORGS_URL}/get-by-admin-flatten`;
            let { data } = await axios.get(url, {
                headers: {
                    "Content-Type": "application/json",
                    'x-auth-token': GetLoggedInUserToken()
                }
            })

            if (data && data.success) {
                let externalOrgs = data.externalOrgs || [];
                setExternalOrgsList(externalOrgs);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const loadConcils = async () => {
        try {
            let url = `${ENV.API_URL}/${ENV.CONCILS_URL}/get-list-by-admin`;
            let res = await HttpRequest(url, null, HttpRequestTypes.GET, GetLoggedInUserToken());

            if (res) {
                let { regions, councils } = res;

                let allCouncils = [...councils];

                let region = {};
                let council = {};

                if (regions && regions.length > 0) {
                    region = { label: regions[0].name, value: regions[0]._id }
                }

                councils = councils.filter(val => val.regionId === region?.value);

                if (councils && councils.length > 0) {
                    council = { label: councils[0].name, value: councils[0].name }
                }

                setAllCouncils(allCouncils);
                setRegions(regions);
                setCouncils(councils);

                let uptExternalFormData = externalFormData.map(item => {
                    let { name, items, value } = item;

                    if (name === "council") {
                        items = councils?.map(it => ({ label: it.name, value: it.name }));
                        value = council;
                    }
                    if (name === "region") {
                        items = regions?.map(it => ({ label: it.name, value: it._id }));
                        value = region;
                    }

                    return {
                        ...item,
                        items,
                        value
                    }
                })

                setExternalFormData(uptExternalFormData)
            }

            setLoading(false);
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    }

    const resetFormDatas = () => {
        setExternalFormData(prev => prev.map(item => ({ ...item, value: "" })))
        setUserFormData(prev => prev.map(item => ({ ...item, value: "" })))
        setSelectedOrgListItem(null);
    }

    const editExternalOrgItem = (userId) => {
        let selectedItem = externalOrgsList.find(item => item.uniqueId === userId);
        setSelectedOrgListItem(selectedItem);

        let selectedCouncil = allCouncils.find(it => it.name === selectedItem.council);
        let selectedRegion = selectedCouncil ? regions.find(it => it._id === selectedCouncil.regionId) : "";

        setExternalFormData(prev => prev.map(item => {
            let { name, value } = item;

            if (name === "name" && selectedItem.name) {
                value = { label: selectedItem.name, value: selectedItem.name };
            } else if (name === "council" && selectedCouncil) {
                value = { label: selectedCouncil.name, value: selectedCouncil.name };
            } else if (name === "region" && selectedRegion) {
                value = { label: selectedRegion.name, value: selectedRegion._id }
            }

            return {
                ...item,
                value
            }
        }))
        setUserFormData(prev => prev.map(item => {

            let value = item.value;
            let userObj = selectedItem.user || {};
            let objValue = userObj[item.name];

            if (objValue && objValue.length > 0) {
                value = objValue;
            }

            return {
                ...item,
                value
            }
        }))

        setTimeout(() => {
            setIsAddEditModalVisible(true);
        }, 300);
    }

    const deleteExternalOrgItem = (externalOrgId, userId) => {
        //SHOW POPUP MESSAGE
        confirmAlert({
            title: "מחיקת גורם מאשר",
            message: "האם אתה בטוח?",
            buttons: [
                {
                    label: "לא, סגור",
                    onClick: () => { },
                },
                {
                    label: "כן",
                    onClick: () => {
                        removeExternalOrgUser(externalOrgId, userId);
                    },
                },
            ],
        });
    }



    const removeExternalOrgUser = async (externalOrgId, userId) => {
        try {
            let url = `${ENV.API_URL}/${ENV.EXTERNAL_ORGS_URL}/delete-user/${externalOrgId}/${userId}`;
            let res = await HttpRequest(url, null, HttpRequestTypes.DELETE, GetLoggedInUserToken());

            if (res && res.success) {
                loadAllExternalOrgs();
            }
        } catch (e) {
            console.error(e);
        }
    }

    const closeModal = () => {
        setIsAddEditModalVisible(false);
        setSuccessMessage("");
    }

    const updateFormFieldValue = (targetName, newValue, setFormField) => {

        setFormField(prev => prev.map(item => {
            let { name, value } = item;

            if (name === targetName) {
                value = newValue;
            }

            return {
                ...item,
                value
            }
        }))

        if (targetName === "region") {
            let councils = allCouncils.filter(val => val.regionId === newValue.value).map(it => ({ label: it.name, value: it.name }))
            let council = councils && councils.length > 0 ? councils[0] : {};

            setFormField(prev => prev.map(item => {

                let { name, value, items } = item;

                if (name === "council") {
                    items = councils;
                    value = council;
                }

                return {
                    ...item,
                    items,
                    value
                }
            }))
        }
    }

    const renderRow = (item, setFormField, formDisabled = false) => {
        let { title, name, value, type, items = [], isMulti = false } = item;

        return (
            <Fragment key={item.name}>
                <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
                    {title}:
                </label>

                {type === FIELD_TYPES.TEXT && (
                    <input type="text" name={name} className="form-control form-control-user"
                        style={{ textAlign: 'right' }} placeholder={title} value={value}
                        disabled={formDisabled}
                        onChange={(e) => updateFormFieldValue(name, e.target.value, setFormField)}
                    />
                )}

                {type === FIELD_TYPES.DROP_DOWN && (
                    <Select
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        options={items}
                        isMulti={isMulti}
                        value={value}
                        isDisabled={formDisabled}
                        onChange={(newValue) => updateFormFieldValue(name, newValue, setFormField)}
                        placeholder={title}
                    />
                )}
            </Fragment>
        )
    }

    const renderModalContent = () => {
        let isEditFlow = selectedOrgListItem !== null;

        return (
            <Fragment>

                {externalFormData.map((item) => {
                    return renderRow(item, setExternalFormData, isEditFlow)
                })}

                {userFormData.map((item) => {
                    return renderRow(item, setUserFormData)
                })}

                {
                    isFormSaving ?
                        <div style={{ marginTop: 25 }}>
                            <Loading />
                        </div>
                        :
                        <div className="text-center w-50 btn-send-email" style={{ marginTop: 25 }}>
                            <input type="button" className="btn btn-user btn-custom btn-block" style={{ backgroundColor: Colors.PRIMARY_YELLOW }} value={selectedOrgListItem === null ? "יצירה" : "שמירת שינויים"} onClick={submit} />
                        </div>
                }

                {successMessage && successMessage.length > 0 && (<div className="mt-3 text-right mr-2"><span className="text-success">{successMessage}</span></div>)}
                {errorMessage && errorMessage.length > 0 && (<div className="mt-3 text-right mr-2"><span className="text-danger">{errorMessage}</span></div>)}
            </Fragment>
        );
    }

    const validateFields = () => {
        for (let item of externalFormData) {
            let itemValue = item.value;

            if (item.type === FIELD_TYPES.DROP_DOWN) {
                itemValue = itemValue?.value || itemValue;
            }

            if (itemValue?.length === 0) {
                return buildValidateResponse(false, item.requiredErrorMessage)
            }
        }

        for (let item of userFormData) {
            if (item.value?.length === 0) {
                return buildValidateResponse(false, item.requiredErrorMessage)
            } else if (item.isCustomValidationRequired && !isCustomValidationResponseValid(item)) {
                return buildValidateResponse(false, item.customValidationErrorMessage)
            }
        }

        return buildValidateResponse(true, "");
    }

    const buildValidateResponse = (isValid, errorMessage) => {
        return {
            isValid,
            errorMessage
        }
    }

    const isCustomValidationResponseValid = (item) => {
        let { name, value } = item;

        if (name === "email") {
            return validateEmail(value);
        } else if (name === "phone") {
            return validateOfficeOrCellPhone(value);
        }

        return true;
    }

    const buildRequestParams = () => {
        let params = {};

        for (let item of externalFormData) {
            let itemValue = item.value;

            if (item.type === FIELD_TYPES.DROP_DOWN) {
                itemValue = itemValue?.value || itemValue;
            }
            params[item.name] = itemValue;
        }

        let user = {};

        for (let item of userFormData) {
            user[item.name] = item.value;
        }

        params["user"] = user;

        return params;
    }

    const submit = async () => {
        try {
            setErrorMessage("");
            setSuccessMessage("");
            setIsFormSaving(true);

            let { isValid, errorMessage } = validateFields();

            if (!isValid) {
                setErrorMessage(errorMessage);
                setIsFormSaving(false);
                return;
            }

            let isAddFlow = selectedOrgListItem === null;
            let params = buildRequestParams();

            console.log("params: ", params)

            if (isAddFlow) {
                let url = `${ENV.API_URL}/${ENV.EXTERNAL_ORGS_URL}/create`;
                let { data } = await axios.post(url, params, {
                    headers: {
                        "Content-Type": "application/json",
                        'x-auth-token': GetLoggedInUserToken()
                    }
                })

                if (data && data.success) {
                    setSuccessMessage("גורם חיצוני נוצר בהצלחה");
                    loadAllExternalOrgs();

                    setTimeout(() => {
                        resetFormDatas();
                        setSuccessMessage("");
                        setIsFormSaving(false);
                        setIsAddEditModalVisible(false);
                    }, 2500);
                } else {
                    setIsFormSaving(false);
                    setErrorMessage(data.message || "אירעה שגיאה, יש לנסות שנית מאוחר יותר")
                }
            } else {
                //EDIT
                params.user["_id"] = selectedOrgListItem?.user?._id;
                params["_id"] = selectedOrgListItem?._id;

                let url = `${ENV.API_URL}/${ENV.EXTERNAL_ORGS_URL}/edit`;
                let { data } = await axios.put(url, params, {
                    headers: {
                        "Content-Type": "application/json",
                        'x-auth-token': GetLoggedInUserToken()
                    }
                })

                console.log("DATA:: ", data)

                if (data && data.success) {
                    setSuccessMessage("פרטי גורם חיצוני נערכו בהצלחה");
                    loadAllExternalOrgs();

                    setTimeout(() => {
                        resetFormDatas();
                        setSuccessMessage("");
                        setIsFormSaving(false);
                        setIsAddEditModalVisible(false);
                    }, 2500);
                } else {
                    setIsFormSaving(false);
                    setErrorMessage(data.message || "אירעה שגיאה, יש לנסות שנית מאוחר יותר")
                }
            }
        } catch (e) {
            console.log(e);
            let message = e.message || "";

            if (typeof message !== typeof "") {
                message = JSON.stringify(message);
            }

            setErrorMessage(message);
        } finally {
            setIsFormSaving(false);
        }
    }

    return (
        <>
            <div className="mt-3 d-flex justify-content-between align-items-center home-container">

                <div className="">
                    <button className="btn prim-btn no-animation custom-box-shadow" style={{}}
                        onClick={() => setIsAddEditModalVisible(true)}>
                        הוספת גורם חוץ חדש
                    </button>
                </div>

                <div className="text-right">
                    <h4 style={{ fontWeight: 'bold' }}>רשימת גורמי חוץ</h4>
                </div>
            </div>

            <div className="mt-3 mb-3 dir-rtl">
                {
                    loading ?
                        <Loading />
                        : <AdminExternalOrgsList
                            data={externalOrgsList}
                            edit={editExternalOrgItem}
                            delete={deleteExternalOrgItem} />
                }
            </div>

            <Modal
                ariaHideApp={false}
                isOpen={isAddEditModalVisible}
                style={customStyles}>

                <div className='modal-content-container'>
                    <div className="d-flex justify-content-between align-items-center">
                        <div></div>
                        <div>
                            <label className="labelModal">
                                {selectedOrgListItem === null ? "הוספת גורם חוץ חדש" : "עריכת פרטי גורם חוץ"}
                            </label>
                        </div>
                        <div>
                            <i className="fa fa-times pointer" onClick={closeModal}></i>
                        </div>
                    </div>

                    <div style={{ direction: 'rtl', marginTop: 20, textAlign: 'right' }}>
                        {renderModalContent()}
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default AdminExternalOrgs;