import React, { Component } from 'react';

import "../assets/styles/sb-admin-2.min.css";
import Loading from '../components/Loading';
import { ENV } from '../config';
import { HttpRequest, HttpRequestTypes } from '../config/GeneralMethods';
import { Colors, CONSTANT_KEYS, SCREEN_NAMES, validateEmail } from '../constants';

class Login extends Component {

    state = {
        email: '',
        password: '',
        successMessage: '',
        errorMessage: '',
        isLoading: false
    };

    getUserInfoFromLocalState = () => {
        let {email, password} = this.state;

        if (email) email = email.trim().toLowerCase();
        if (password) password = password.trim().toLowerCase();

        return {
            email,
            password
        };
    }

    submit = async () => {
        
        let userInfo = this.getUserInfoFromLocalState();

        this.setState({ successMessage: '', errorMessage: '' });

        if(userInfo.email.length === 0) {
            this.setState({ errorMessage: 'חובה להזין דוא"ל **' });
        } else if(userInfo.password.length === 0) {
            this.setState({ errorMessage: 'חובה להזין סיסמא **' });
        } else if(!validateEmail(userInfo.email)){
            this.setState({ errorMessage: 'נא להזין דוא"ל תקין' });
        } else {
            
            this.setState({isLoading: true})

            let url = `${ENV.API_URL}/${ENV.USERS_API_URL}/login`;
            let res = await HttpRequest(url, userInfo, HttpRequestTypes.POST);

            if(res){
                if(res.success){
                    this.setState({ successMessage: res.message });

                    let {
                        app, email, firstName, isActive, lastName, plan, planExpiry, role, _id, userSettings
                    } = res.user;

                    let customUserInfo = {
                        app,
                        email,
                        firstName,
                        lastName,
                        isActive,
                        plan,
                        planExpiry,
                        role,
                        _id,
                        userSettings
                    };

                    let userInfoExpiry = {
                        user: customUserInfo,
                        expiry: new Date().getTime() + (1000 * 60 * 60 * 24) //Expiry after One Day
                    };

                    localStorage.setItem(CONSTANT_KEYS.USER_INFO, JSON.stringify(userInfoExpiry));
                    localStorage.setItem(CONSTANT_KEYS.USER_TOKEN, res.token);

                    setTimeout(() => {

                        let searchParams = new URLSearchParams(window.location.search);
                        let returnUrl = searchParams.get("returnUrl");

                        if (!returnUrl) {
                            window.location.href = SCREEN_NAMES.DROPZONE;
                        } else {
                            window.location.href = returnUrl;
                        }

                    }, 1300);

                } else {
                    this.setState({ errorMessage: res.message });
                }
            } else {
                this.setState({ errorMessage: 'אירעה שגיאה, נא לנסות שוב בעוד כמה דקות' })
            }

            this.setState({ isLoading: false })
        }
    }

    render(){
        return (
            
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-xl-6 col-md-9">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row justify-content-center">
                                    <div className="col-12">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <img src="https://i.ibb.co/8NNd8st/logoPNG.png" alt="" style={{width: 100}} />
                                                <h1 className="h4 text-gray-900 mb-4">התחברות למערכת </h1>
                                            </div>
                                            <form className="user">
                                                <div className="form-group">
                                                    <input type="text" name="email" className="form-control form-control-user" style={{textAlign: 'right'}} id="email_in" aria-describedby="emailHelp" placeholder="דואר אלקטרוני  "
                                                        onChange={(e) => this.setState({email: e.target.value})} value={this.state.email} />
                                                </div>
                                                <div className="form-group">
                                                    <input type="password" name="password" className="form-control form-control-user" style={{textAlign: 'right'}} placeholder="סיסמא"
                                                        onChange={(e) => this.setState({password: e.target.value})} value={this.state.password} />
                                                </div>

                                                {
                                                    this.state.isLoading ? 
                                                    <Loading />
                                                    : <input type="button" className="btn btn-user btn-custom btn-block" value="כניסה" style={{backgroundColor: Colors.PRIMARY_YELLOW}} onClick={this.submit} />
                                                }

                                                <div className="mt-3 text-right mr-2"><span className="text-success">{this.state.successMessage}</span></div>
                                                <div className="mt-3 text-right mr-2"><span className="text-danger">{this.state.errorMessage}</span></div>
                                            </form>
                                            <hr />
                                            <div className="text-center">
                                                <a className="meduim" href={SCREEN_NAMES.FORGET_PASSWORD_SCREEN}>שכחתי סיסמא</a>
                                            </div>
                                            <div className="text-center">
                                                <a className="meduim" href={SCREEN_NAMES.REGISTER_SCREEN}>משתמש חדש? הירשם עכשיו</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;