import React, { Component } from 'react';
import "../assets/styles/home.css";
import DocumentsDataTable from '../components/DocumentsDataTable';
import { ENV } from '../config';
import { GetLoggedInUserToken, HttpRequest, HttpRequestTypes, IsNeedAPlan } from '../config/GeneralMethods';
import Loading from '../components/Loading';
import { SCREEN_NAMES } from '../constants';

class Home extends Component {

    constructor(props){
        super(props);
        this._isMounted = false;
    }

    state = {
        arr: [],
        isLoading: false,
    };

    componentDidMount(){

        if(IsNeedAPlan()){
            window.location.href = SCREEN_NAMES.PRICING_SCREEN;
        } else {
            this._isMounted = true;
            this._isMounted && this.loadDocuments();
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    loadDocuments = async () => {

        this._isMounted && this.setState({ isLoading: true });

        let url = `${ENV.API_URL}/${ENV.DOCUMENTS_API_URL}`;
        let res = await HttpRequest(url, null, HttpRequestTypes.GET , GetLoggedInUserToken());

        if(res && res.success){
            this._isMounted && this.setState({arr: res.data});
        }

        this._isMounted && this.setState({ isLoading: false });
    }

    openDocuments = () => {
        window.location.href = SCREEN_NAMES.DROPZONE;
    }

    render(){
        return (
            <div>
                <div className="container">

                    <div className="mt-3 d-flex justify-content-between align-items-center home-container">

                        <div className="">
                            <button className="btn prim-btn no-animation custom-box-shadow" style={{}}
                             onClick={this.openDocuments}>
                                הפקת מסמך חדש
                            </button>
                        </div>

                        <div className="">
                            <h4 style={{fontWeight: 'bold'}}>רשימת המסמכים</h4>
                        </div>
                    </div>

                    <div className="mt-3 mb-3">
                        {
                            this.state.isLoading ? 
                            <Loading />
                            : <DocumentsDataTable data={this.state.arr} />
                        }
                    </div>
                </div>

            </div>
        );
    }    
}

export default Home;