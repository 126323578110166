import React, { Component } from 'react';


import "../assets/styles/sb-admin-2.min.css";
import Loading from '../components/Loading';
import { ENV } from '../config';
import { GetLoggedInUserInfo, GetLoggedInUserToken, HttpRequest, HttpRequestTypes, IsNeedAPlan } from '../config/GeneralMethods';
import { Colors, CONSTANT_KEYS, SCREEN_NAMES, validateEmail, validatePhone } from '../constants';

class Profile extends Component{

    state = {
        name: '',
        email: '',
        phone: '',
        successMessage: '',
        errorMessage: '',
        isLoading: false
    };

    componentDidMount(){

        if(IsNeedAPlan()){
            window.location.href = SCREEN_NAMES.PRICING_SCREEN;
        } else {
            let userInfoObj = GetLoggedInUserInfo();

            let userInfo = userInfoObj.user || {};
            this.setState({name: userInfo.name, email: userInfo.email, phone: userInfo.phone});
        }
    }

    submit = async () => {
        let userInfo = {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
        };

        this.setState({ successMessage: '', errorMessage: '' });

        if(userInfo.name.length === 0) {
            this.setState({ errorMessage: 'חובה להזין שם מלא **' });
        } else if(userInfo.email.length === 0) {
            this.setState({ errorMessage: 'חובה להזין דוא"ל **' });
        } else if(userInfo.phone.length === 0) {
            this.setState({ errorMessage: 'חובה להזין מספר טלפון **' });
        }  else if(!validateEmail(userInfo.email)){
            this.setState({ errorMessage: 'נא להזין דוא"ל תקין' });
        } else if (!validatePhone(userInfo.phone)) {
            this.setState({ errorMessage: 'נא להזין מספר טלפון תקין' });
        }  else {

            this.setState({ isLoading: true })

            let url = `${ENV.API_URL}/${ENV.USERS_API_URL}/edit`;
            let res = await HttpRequest(url, userInfo, HttpRequestTypes.PATCH, GetLoggedInUserToken());

            if(res) {
                if(res.success){
                    this.setState({ successMessage: res.message });

                    let userInfoObj = GetLoggedInUserInfo();
                    let currUserInfo = userInfoObj.user;
                    currUserInfo.name = userInfo.name;
                    currUserInfo.email = userInfo.email;
                    currUserInfo.phone = userInfo.phone;

                    userInfoObj.user = currUserInfo;
                    localStorage.setItem(CONSTANT_KEYS.USER_INFO, JSON.stringify(userInfoObj));

                    setTimeout(() => {
                        window.location.reload();
                    }, 1400);

                } else {
                    this.setState({ errorMessage: res.message });
                }
            } else {
                this.setState({ errorMessage: 'אירעה שגיאה, נא לנסות שוב בעוד כמה דקות' })
            }

            this.setState({ isLoading: false })
        }
    }

    render(){
        return (
            
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-xl-6 col-md-9">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row justify-content-center">
                                    <div className="col-12">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h1 className="h4 text-gray-900 mb-4">עריכת פרטים אישיים </h1>
                                            </div>
                                            <form className="user">
                                                <div className="form-group" style={{textAlign: "right"}}>
                                                    <label style={{marginRight: 10}}>שם מלא</label>
                                                    <input type="text" name="name" className="form-control form-control-user" style={{textAlign: 'right'}} id="name_in" aria-describedby="name=Help" placeholder="שם מלא  "
                                                        onChange={(e) => this.setState({name: e.target.value})} value={this.state.name} />
                                                </div>
                                                <div className="form-group" style={{textAlign: "right"}}>
                                                    <label style={{marginRight: 10}}>דואר אלקטרוני</label>
                                                    <input type="text" name="email" className="form-control form-control-user" style={{textAlign: 'right'}} id="email_in" aria-describedby="emailHelp" placeholder="דואר אלקטרוני  "
                                                        onChange={(e) => this.setState({email: e.target.value})} value={this.state.email} />
                                                </div>
                                                <div className="form-group" style={{textAlign: "right"}}>
                                                    <label style={{marginRight: 10}}>מספר טלפון</label>
                                                    <input type="text" name="phone" className="form-control form-control-user" style={{textAlign: 'right'}} id="phone_in" aria-describedby="phoneHelp" placeholder="מס' טלפון  "
                                                        onChange={(e) => this.setState({phone: e.target.value})} value={this.state.phone} />
                                                </div>

                                                {
                                                    this.state.isLoading ? 
                                                    <Loading />
                                                    : <input type="button" style={{backgroundColor: Colors.PRIMARY_YELLOW}} className={"btn btn-user btn-custom btn-block "} value="שמירת שינויים" onClick={this.submit} />
                                                }
                                                
                                                <div className="mt-3 text-right mr-2"><span className="text-success">{this.state.successMessage}</span></div>
                                                <div className="mt-3 text-right mr-2"><span className="text-danger">{this.state.errorMessage}</span></div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Profile;