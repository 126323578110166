import React, { Component } from 'react';

import "../assets/styles/sb-admin-2.min.css";
import Loading from '../components/Loading';
import { ENV } from '../config';
import { HttpRequest, HttpRequestTypes } from '../config/GeneralMethods';
import { Colors, SCREEN_NAMES } from '../constants';

class NewPassword extends Component {

    state = {
        password: '',
        successMessage: '',
        errorMessage: '',
        isLoading: false,
        token: '',
        email: ''
    };

    componentDidMount(){

        let params = new URLSearchParams(window.location.search);

        let email = params.get("email") || "";
        let token = params.get("tk") || "";

        this.setState({email, token});
    }

    submit = async () => {

        let token = this.state.token;

        let userInfo = {
            email: this.state.email,
            password: this.state.password
        };

        this.setState({ successMessage: '', errorMessage: '' });

        if(!token || !userInfo.email || token.length === 0 || userInfo.email.length === 0){
            this.setState({errorMessage: 'אינך רשאי להחליף את הסיסמא, המערכת מעבירה אותך לדף התחברות'});

            setTimeout(() => {
                window.location.href = SCREEN_NAMES.LOGIN_SCREEN;
            }, 1500);
        } else {

            if(userInfo.password.length === 0) {
                this.setState({ errorMessage: 'חובה להזין סיסמא' });
            }  else if (userInfo.password.length < 6){
                this.setState({errorMessage: 'אורך הסיסמא המינמלי הוא 6 ספרות'})
            } else {
                this.setState({ isLoading: true })

                let url = `${ENV.API_URL}/${ENV.USERS_API_URL}/new-password`;
                let res = await HttpRequest(url, userInfo, HttpRequestTypes.POST, token);

                if(res) {
                    if(res.success){
                        this.setState({ successMessage: res.message });

                        setTimeout(() => {
                            this.setState({ successMessage: "" });
                            window.location.href = SCREEN_NAMES.LOGIN_SCREEN;
                        }, 2300);
                    } else {
                        this.setState({ errorMessage: res.message });
                    }
                } else {
                    this.setState({ errorMessage: 'אירעה שגיאה, נא לנסות שוב בעוד כמה דקות' })
                }

                this.setState({ isLoading: false })
            }
        }
    }

    render(){
        return (
            
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-xl-6 col-md-9">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row justify-content-center">
                                    <div className="col-12">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <img src="https://i.ibb.co/8NNd8st/logoPNG.png" alt="" style={{width: 100}} />
                                                <h1 className="h4 text-gray-900 mb-4">איפוס הסיסמא </h1>
                                            </div>
                                            <form className="user">
                                                <div className="form-group">
                                                    <input type="password" name="password" className="form-control form-control-user" style={{textAlign: 'right'}} id="email_in" aria-describedby="emailHelp" placeholder="סיסמא חדשה  "
                                                        onChange={(e) => this.setState({password: e.target.value})} value={this.state.password} />
                                                </div>

                                                {
                                                    this.state.isLoading ? 
                                                    <Loading />
                                                    : <input type="button" className="btn btn-user btn-custom btn-block" style={{backgroundColor: Colors.PRIMARY_YELLOW}} value="אפס סיסמא" onClick={this.submit} />
                                                }
                                                
                                                <div className="mt-3 text-right mr-2" style={{direction: 'rtl'}}><span className="text-success">{this.state.successMessage}</span></div>
                                                <div className="mt-3 text-right mr-2" style={{direction: 'rtl'}}><span className="text-danger">{this.state.errorMessage}</span></div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NewPassword;