import React, { Component } from 'react';
import { Colors, SCREEN_NAMES } from '../constants';

export default class Cancel extends Component{

    componentDidMount(){
        //remove the side bar
        const sidebarContainer = document.getElementsByClassName("sidebar-container");

        if(sidebarContainer && sidebarContainer.length > 0){
            sidebarContainer[0].style.display = "none";
        }

        //Make App-main Full Width
        const appMain = document.getElementById("appMain");

        if(appMain){
            appMain.style.width = "100%";
        }

        //Hide TopNavBar
        const topNavBar = document.getElementsByClassName("topnavbar-container");
        
        if(topNavBar && topNavBar.length > 0){
            topNavBar[0].style.display = "none";
        }
    }

    render(){
        return (
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>

            <img src={require("../assets/images/error_icon.png")} alt="" style={{width: 200, height: 200, marginTop: 20}} />

            <h2>התשלום נכשל</h2>
            <h5>פעולת התשלום נכשלה, נא לנסות שנית</h5>

            <button onClick={() => window.location.href = SCREEN_NAMES.HOME_SCREEN}
                style={{marginTop: 20, backgroundColor: Colors.PRIMARY_YELLOW, color: "white"}} className="btn custom-btn">
                חזור לדף הבית
            </button>
        </div>
        );
    }
}