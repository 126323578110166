import React, { Component, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import moment from "moment";
import { APP_OPTIONS } from '../../constants';

export default class AdminCompanies extends Component {

    state = {
        columns: [

            { name: 'שם הארגון', sortable: true, selector: 'name', center: true, style: { fontSize: 14, textAlign: "center" } },
            { 
                name: 'מספר מסמכים בחודש', sortable: true, selector: 'documentsPerMonth', center: true, style: { fontSize: 14, textAlign: "center" },
                cell: row => <label>{row.app === APP_OPTIONS.TOFES4 ? "" : row.documentsPerMonth}</label>
            },
            { 
                name: 'סה"כ מסמכים נותרו', sortable: true, selector: 'remainigDocs', center: true, style: { fontSize: 14, textAlign: "center" },
                cell: row => <label>{row.app === APP_OPTIONS.TOFES4 ? "" : (!row.remainigDocs ? row.documentsPerMonth : row.remainigDocs)}</label>
            },
            { name: 'מספר משתמשים בארגון', sortable: true, selector: 'numOfUsers', center: true, style: { fontSize: 14, textAlign: "center" } },
            { 
                name: 'מערכת', sortable: true, selector: 'app', center: true, style: { fontSize: 14, textAlign: "center" },
                cell: row => <label>{row.app === APP_OPTIONS.TOFES4 ? "טופס 4" : "איי סיין"}</label>
            },
            { 
                name: 'מועצה', sortable: true, selector: 'council', center: true, style: { fontSize: 14, textAlign: "center" },
                cell: row => <label>{row.app === APP_OPTIONS.TOFES4 ? row.council : ""}</label>
            },
            { 
                name: 'חבילה נוכחית', sortable: true, selector: 'plan', center: true, style: { fontSize: 14, textAlign: "center" },
                cell: row => <label>{row.app === APP_OPTIONS.TOFES4 ? "ללא חבילה" : row.plan}</label>
            },
            {
                name: 'החבילה תפוג בתאריך', sortable: true, selector: 'planExpiry', center: true, style: { fontSize: 14, textAlign: "center" },
                cell: row => <label>{row.app === APP_OPTIONS.TOFES4 ? "ללא תאריך תפוגה" : moment(row.planExpiry).format("DD/MM/yyyy")}</label>
            },
            {
                name: '', sortable: false, center: true, style: { fontSize: 14 },
                cell: row => <Fragment>
                    {row.app !== APP_OPTIONS.TOFES4 && <button className="icon-button" data-tooltip="שלם" onClick={() => this.props.payService(row._id)}
                            style={{ marginRight: 10 }}>
                            <span className="tooltiptext">שלם</span>
                            <i className="eye fa fa-credit-card"></i>
                        </button>
                    }
                    <button className="icon-button" data-tooltip="מחיקה" onClick={() => this.props.deleteCompany(row._id)}>
                        <span className="tooltiptext">מחיקה</span>
                        <i className="eye fa fa-trash text-danger"></i>
                    </button>
                    <button className="icon-button ml-2" data-tooltip="עריכה" onClick={() => this.props.editCompany(row._id)}>
                        <span className="tooltiptext">עריכה</span>
                        <i className="download fa fa-pencil"></i>
                    </button>
                </Fragment>
            },
            {
                name: "", center: true, style: {margin: "0px 20px"},
                cell: row => <div>
                    <button className="btn prim-btn no-animation custom-box-shadow custom-mg" data-tooltip="מחיקה" onClick={() => this.props.openCompany(row)}
                        style={{ marginTop: 20, marginBottom: 20, width: 140, fontSize: 14 }}>
                        ניהול משתמשי הארגון
                    </button>
                </div>
            }
        ],
        emptyColumns: [
            { name: 'שם הארגון', sortable: true, selector: 'name', center: true, style: { fontSize: 14, textAlign: "center" } },
            { name: 'מספר מסמכים בחודש', sortable: true, selector: 'documentsPerMonth', center: true, style: { fontSize: 14, textAlign: "center" } },
            { name: 'סה"כ מסמכים נותרו', sortable: true, selector: 'remainigDocs', center: true, style: { fontSize: 14, textAlign: "center" } },
            { name: 'מספר משתמשים בארגון', sortable: true, selector: 'numOfUsers', center: true, style: { fontSize: 14, textAlign: "center" } },
            { name: 'מערכת', sortable: true, selector: 'app', center: true, style: { fontSize: 14, textAlign: "center" } },
            { name: 'מועצה', sortable: true, selector: 'council', center: true, style: { fontSize: 14, textAlign: "center" } },
            { name: 'חבילה נוכחית', sortable: true, selector: 'plan', center: true, style: { fontSize: 14, textAlign: "center" } },
            { name: 'החבילה תפוג בתאריך', sortable: true, selector: 'planExpiry', center: true, style: { fontSize: 14, textAlign: "center" } },
            { name: '', sortable: false, selector: 'actions', center: true },
            { name: '', sortable: false, selector: 'actions', center: true },
        ],
        emptyColumnsData: [{ name: "", numOfUsers: "", plan: "", planExpiry: "", actions: '', documentsPerMonth: "", remainigDocs: "", council: "", system: "" }],
    }

    componentDidMount() {

        //Change Text OF "Rows per page: " by it's class
        let rowsPerPageText = document.getElementsByClassName("gckGBs");
        if (rowsPerPageText && rowsPerPageText.length > 0) {
            rowsPerPageText[0].innerHTML = "שורות בכל דף"
        }
    }

    render() {
        return (
            (!this.props.data || this.props.data.length === 0) ?
                <div className="empty-container custom-box-shadow">

                    <DataTable
                        columns={this.state.emptyColumns}
                        data={this.state.emptyColumnsData}
                        pagination={true}
                    />

                    <div className="text-center empty-container-inner">
                        <h3>לא נמצאו ארגונים</h3>
                    </div>
                </div>
                :
                <DataTable
                    columns={this.state.columns}
                    data={this.props.data}
                    pagination={true}
                />
        );
    }
}