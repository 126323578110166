import React from 'react';

import "../assets/styles/notfound.css";
import IMAGE from '../assets/images/690937.png';
import { SCREEN_NAMES } from '../constants';

const NotFound = (props) => {

    const openHome = () => {
        window.location.href = SCREEN_NAMES.HOME_SCREEN;
    }

    return (
        <>
            <main className='body-container'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <img alt="" src={IMAGE} />
                        </div>
                        <div className="col-md-6 align-self-center text-center">
                            <h1 className='very-lg-font'>404</h1>
                            <h2 className='medium-font'>אופס</h2>
                            <p>הדף שחיפשת לא נמצא <br />
                                ייתכן שזאת טעות או שאנחנו עובדים
                                <br />
                                על משהו קטנצ׳יק והדף הזה יהיה זמין בקרוב
                            </p>

                            <button type='button' onClick={openHome} className='btn prim-btn no-animation custom-box-shadow'>חזור לדף הבית</button>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default NotFound;