import React, { Component } from 'react';

import "../assets/styles/sb-admin-2.min.css";
import Loading from '../components/Loading';
import { ENV } from '../config';
import { HttpRequest, HttpRequestTypes } from '../config/GeneralMethods';
import { Colors, LOGIN_METHODS, SCREEN_NAMES, USERS_ROLES, validateEmail, validatePhone } from '../constants';

class Register extends Component{

    state = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        phone: '',
        successMessage: '',
        errorMessage: '',
        isLoading: false,
        isChecked: false
    };


    getUserInformationFromLocalState = () => {
        
        let {firstName, lastName, email, phone, password} = this.state;
        
        if (firstName) firstName = firstName.trim();
        if (lastName) lastName = lastName.trim();
        if (email) email = email.trim().toLowerCase();
        if (password) password = password.trim().toLowerCase();
        if (phone) phone = phone.trim();

        return {
            firstName,
            lastName,
            email,
            password,
            phone,
            role: USERS_ROLES.ESIGN_USER,
            loginMethod: LOGIN_METHODS.EMAIL_PASSWORD
        };
    }

    submit = async () => {
        let userInfo = this.getUserInformationFromLocalState();

        this.setState({ successMessage: '', errorMessage: '' });

        if(userInfo.firstName.length === 0) {
            this.setState({ errorMessage: 'חובה להזין שם פרטי **' });
        } else if(userInfo.lastName.length === 0) {
            this.setState({ errorMessage: 'חובה להזין שם משפחה **' });
        } else if(userInfo.email.length === 0) {
            this.setState({ errorMessage: 'חובה להזין דוא"ל **' });
        } else if(userInfo.phone.length === 0) {
            this.setState({ errorMessage: 'חובה להזין מספר טלפון **' });
        } else if(userInfo.password.length === 0) {
            this.setState({ errorMessage: 'חובה להזין סיסמא **' });
        } else if(!validateEmail(userInfo.email)){
            this.setState({ errorMessage: 'נא להזין דוא"ל תקין' });
        } else if (!validatePhone(userInfo.phone)) {
            this.setState({ errorMessage: 'נא להזין מספר טלפון תקין' });
        } else if (userInfo.password.length < 6){
            this.setState({errorMessage: 'אורך הסיסמא המינמלי הוא 6 ספרות'})
        } else {

            this.setState({ isLoading: true })

            let url = `${ENV.API_URL}/${ENV.USERS_API_URL}/register`;
            let res = await HttpRequest(url, userInfo, HttpRequestTypes.POST);

            if(res) {
                if(res.success){
                    this.setState({ successMessage: res.message });

                    this.setState({
                        firstName: "",
                        lastName: "",
                        email: "",
                        password: "",
                        phone: ""
                    });

                    setTimeout(() => {
                        window.location.href = SCREEN_NAMES.LOGIN_SCREEN;
                    }, 1400);
                } else {
                    this.setState({ errorMessage: res.message });
                }
            } else {
                this.setState({ errorMessage: 'אירעה שגיאה, נא לנסות שוב בעוד כמה דקות' })
            }

            this.setState({ isLoading: false })
        }
    }

    render(){
        return (
            
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-xl-6 col-md-9">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row justify-content-center">
                                    <div className="col-12">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <img src="https://i.ibb.co/8NNd8st/logoPNG.png" alt="" style={{width: 100}} />
                                                <h1 className="h4 text-gray-900 mb-4">הרשמה למערכת </h1>
                                            </div>
                                            <form className="user">
                                                <div className="form-group">
                                                    <input type="text" name="firstName" className="form-control form-control-user" style={{textAlign: 'right'}} id="firstName_in" aria-describedby="name=Help" placeholder="שם פרטי  "
                                                        onChange={(e) => this.setState({firstName: e.target.value})} value={this.state.firstName} />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" name="lastName" className="form-control form-control-user" style={{textAlign: 'right'}} id="lastName_in" aria-describedby="name=Help" placeholder="שם משפחה  "
                                                        onChange={(e) => this.setState({lastName: e.target.value})} value={this.state.lastName} />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" name="email" className="form-control form-control-user" style={{textAlign: 'right'}} id="email_in" aria-describedby="emailHelp" placeholder="דואר אלקטרוני  "
                                                        onChange={(e) => this.setState({email: e.target.value})} value={this.state.email} />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" name="phone" className="form-control form-control-user" style={{textAlign: 'right'}} id="phone_in" aria-describedby="phoneHelp" placeholder="מס' טלפון  "
                                                        onChange={(e) => this.setState({phone: e.target.value})} value={this.state.phone} />
                                                </div>
                                                <div className="form-group">
                                                    <input type="password" name="password" className="form-control form-control-user" style={{textAlign: 'right'}} placeholder="סיסמא"
                                                        onChange={(e) => this.setState({password: e.target.value})} value={this.state.password} />
                                                </div>
            
                                                <div className="form-check mb-2" style={{direction: 'rtl', textAlign: 'right'}}>
                                                    <input type="checkbox" defaultChecked={this.state.isChecked} className="form-check-input" id="tos_check"
                                                        onChange={(e) => this.setState({isChecked: !this.state.isChecked})} />
                                                    <label className="form-check-label mr-4" htmlFor="exampleCheck1">אני מאשר את <a href={ENV.TERMS_URL} target="_blank" rel="noopener noreferrer">תקנון השימוש</a> </label>
                                                </div>

                                                {
                                                    this.state.isLoading ? 
                                                    <Loading />
                                                    : <input type="button" style={{backgroundColor: Colors.PRIMARY_YELLOW}} className={"btn btn-user btn-custom btn-block " + (this.state.isChecked ? "" : "no-animation")} value="הירשם עכשיו" onClick={this.submit} disabled={!this.state.isChecked} />
                                                }
                                                
                                                <div className="mt-3 text-right mr-2"><span className="text-success">{this.state.successMessage}</span></div>
                                                <div className="mt-3 text-right mr-2"><span className="text-danger">{this.state.errorMessage}</span></div>
                                            </form>
                                            <hr />
                                            <div className="text-center">
                                                <a className="meduim" href={SCREEN_NAMES.LOGIN_SCREEN}>משתמש קיים? התחבר עכשיו </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Register;