import moment from 'moment';
import React, { Component, Fragment } from 'react';
//import DataTable from 'react-data-table-component';
import DataTable from 'react-data-table-component-with-filter';
import { ENV } from '../../config';

export default class AdminDocumentsList extends Component {

    state = {
        columns: [
            {
                name: '', sortable: false, center: true, style: { fontSize: 14 },
                cell: row => <Fragment>
                    <button className="icon-button" data-tooltip="מחיקה" onClick={() => this.props.deleteDocument(row._id)}>
                        <span className="tooltiptext">מחיקה</span>
                        <i className="eye fa fa-trash text-danger"></i>
                    </button>
                    <button className="icon-button ml-2" data-tooltip="עריכה" onClick={() => this.props.editDocument(row._id)}>
                        <span className="tooltiptext">עריכה</span>
                        <i className="download fa fa-pencil"></i>
                    </button>
                    <button className="icon-button ml-2" data-tooltip="בניית תבנית" onClick={() => this.props.openTemplateScreen(row._id)}>
                        <span className="tooltiptext">בניית תבנית</span>
                        <i className="download fa fa-file"></i>
                    </button>
                </Fragment>
            },
            { name: 'שם הטופס', width: "320px", sortable: true, selector: 'name', filterable: true, center: true, style: { fontSize: 14, textAlign: "center" } },
            { name: 'מועצה', sortable: true, selector: 'council', filterable: true, center: true, style: { fontSize: 14, textAlign: "center" } },
            {
                name: 'שלב הבניה', sortable: true, selector: 'constructionStep', filterable: true, center: true, style: { fontSize: 14, textAlign: "center" },
                cell: row => <label>{row.constructionStep ? row.constructionStep.join(', ') : ""}</label>
            },
            {
                name: 'סוג מבנה', sortable: true, selector: 'constructionType', filterable: true, center: true, style: { fontSize: 14, textAlign: "center" },
                cell: row => <label>{row.constructionType ? row.constructionType.join(', ') : ""}</label>
            },
            {
                name: 'קטגוריות', sortable: true, selector: 'categories', filterable: true, center: true, style: { fontSize: 14, textAlign: "center" },
                cell: row => <label>{row.categories ? row.categories.join(', ') : ""}</label>
            },
            {
                name: 'תאריך יצירה', sortable: true, selector: 'createdAt', center: true, style: { fontSize: 14, textAlign: "center" },
                cell: row => <label>{moment(row.createdAt).format("DD/MM/YYYY HH:mm:ss")}</label>
            },
            {
                name: 'קישור להורדה', sortable: true, selector: 'url', center: true, style: { fontSize: 14, textAlign: "center" },
                cell: row => <label><a href='#' style={{cursor: "pointer"}} onClick={() => this.openFile(row.url)}>לחץ כאן</a></label>
            },

        ],
        emptyColumns: [
            { name: '', sortable: false, selector: 'actions', center: true, style: { fontSize: 14, textAlign: "center" } },
            { name: 'שם הטופס', sortable: true, selector: 'name', center: true, style: { fontSize: 14, textAlign: "center" } },
            { name: 'מועצה', sortable: true, selector: 'council', center: true, style: { fontSize: 14, textAlign: "center" } },
            { name: 'שלב הבניה', sortable: true, selector: 'constructionStep', center: true, style: { fontSize: 14, textAlign: "center" } },
            { name: 'סוג מבנה', sortable: true, selector: 'constructionType', center: true, style: { fontSize: 14, textAlign: "center" } },
            {
                name: 'קטגוריות', sortable: true, selector: 'categories', center: true, style: { fontSize: 14, textAlign: "center" },
                cell: row => <label>{row.categories ? row.categories.join(', ') : ""}</label>
            },
            {
                name: 'תאריך יצירה', sortable: true, selector: 'createdAt', center: true, style: { fontSize: 14, textAlign: "center" },
                cell: row => <label></label>
            },
            { name: 'קישור להורדה', sortable: true, selector: 'url', center: true, style: { fontSize: 14, textAlign: "center" } },
        ],
        emptyColumnsData: [{ _id: '', name: "", numOfUsers: "", plan: "", planExpiry: "", actions: '', documentsPerMonth: "", remainigDocs: "" }],
    }

    componentDidMount() {

        //Change Text OF "Rows per page: " by it's class
        let rowsPerPageText = document.getElementsByClassName("bnNgVs");
        if (rowsPerPageText && rowsPerPageText.length > 0) {
            rowsPerPageText[0].innerHTML = "שורות בכל דף"
        }
    }

    openFile = (url) => {
        window.location.href = `https://api-esign.blirokratia.com/api/files/download-file?url=${url}`;
    }

    render() {
        return (
            (!this.props.data || this.props.data.length === 0) ?
                <div className="empty-container custom-box-shadow">

                    <DataTable
                        columns={this.state.emptyColumns}
                        data={this.state.emptyColumnsData}
                        pagination={true}
                    />

                    <div className="text-center empty-container-inner">
                        <h3>לא נמצאו מסמכים</h3>
                    </div>
                </div>
                :
                <DataTable
                    columns={this.state.columns}
                    data={this.props.data}
                    pagination={true}
                />
        );
    }
}