import React, { Component } from 'react';
import Loader from 'react-loader-spinner';

export default class Loading extends Component {

    render(){
        return (
            <div className="text-center">
                <Loader type="BallTriangle" color="black" height={50} width={50} />
            </div>
        );
    }
}