
const EVNIRONMENTS = {
    DEV: "DEV",
    PROD: "PROD"
};


//MAKE SURE ENV IS PROD ONLY!!!
const EVNIRONMENT = EVNIRONMENTS.PROD;

const DEV_API_URL = "http://localhost:5001";
const PROD_API_URL = "https://api-esign.blirokratia.com";
const BASE_API_URL = EVNIRONMENT === EVNIRONMENTS.DEV ? DEV_API_URL : PROD_API_URL;

export const ENV = {
    //API_URL: "https://api-esign.blirokratia.com",
    //API_URL: "http://localhost:5001",
    API_URL: BASE_API_URL,
    CLIENT_URL: 'http://esign.blirokratia.com',
    USERS_API_URL: "api/user",
    DOCUMENTS_API_URL: "api/document",
    UPLOADS_API_URL: "api/upload",
    CONTACT_LIST_URL: "api/contact-list",
    COMPANY_LIST_URL: "api/company-list",
    NOTIFICATIONS_URL: "api/notifications",
    FILES_URL: 'api/files',
    FILES_V2_URL: 'api/v2/files',
    CATEGORIES_URL: "api/category",
    EXTERNAL_ORGS_URL: "api/external-orgs",
    CONCILS_URL: "api/council",
    CONSTRUCTION_TYPES_URL: "api/construction-type",
    CONSTRUCTION_STEPS_URL: "api/construction-step",
    TERMS_URL: "https://blirokratiabucket.s3.eu-central-1.amazonaws.com/Tos.pdf",
    ADMIN_PASSWORD: "123123",
    ELECTRONIC_SIGNATURE_URL: "https://signature.blirokratia.com"
};