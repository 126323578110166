import { CONSTANT_KEYS, getBothTimesInSameLength, SCREEN_NAMES } from "../constants";

export const HttpRequestTypes = {
    GET: 'GET',
    POST: 'POST',
    PATCH: 'PATCH',
    DELETE: 'DELETE'
}

export const HttpRequest = async (url, params = null, type = HttpRequestTypes.GET, token = null) => {
    try{
        let res = null;

        if(type === HttpRequestTypes.GET) {

            if(token){
                res = await fetch(url, GetRequestBody(type, params, token));
            } else {
                res = await fetch(url);
            }
        } else {
            res = await fetch(url, GetRequestBody(type, params, token));
        }
        
        if(res){

            let jsonResponse = await res.json();

            if ((res.status >= 200 && res.status < 300) || (res.status === 400 && jsonResponse.success !== undefined)) return jsonResponse;
            return null;
        } else {
            return null;
        }
    } catch(e) {
        console.error(e);
        return null;
    }
}

const GetRequestBody = (type, body, token) => {
    let req = GetRequestHeaders(type, token);

    if(body){
        req["body"] = JSON.stringify(body);
    }

    return req;
}

const GetRequestHeaders = (type, token) => {
    if(token){
        return {
            method: type,
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': token
            }
        }
    } else {
        return {
            method: type,
            headers: {
                'Content-Type': 'application/json'
            }
        }
    }
}


export const UploadImage = async (url, data) => {
    try{
      
        let options = {
          method: HttpRequestTypes.POST,
          headers: {},
          body: data
        };
  
        let res = await fetch(url, options);
  
        let json = await res.json();
        
        return {
            ...json,
            success: res.status >= 200 && res.status < 300
        }
        
    }catch(e){
      console.log(e);
      return null;
    }
  }


//Get User Info
export const GetLoggedInUserInfo = () => {
    return JSON.parse(localStorage.getItem(CONSTANT_KEYS.USER_INFO));
}

//Get User Token
export const GetLoggedInUserToken = () => {
    return localStorage.getItem(CONSTANT_KEYS.USER_TOKEN);
}

//Logout User
export const Logout = () => {
    localStorage.removeItem(CONSTANT_KEYS.USER_INFO);
    localStorage.removeItem(CONSTANT_KEYS.USER_TOKEN);
    localStorage.removeItem(CONSTANT_KEYS.ADMIN_ACCESS);

    window.location.href = SCREEN_NAMES.LOGIN_SCREEN;
}


//Is User need a Plan
export const IsNeedAPlan = () => {
    let userInfo = GetLoggedInUserInfo();

    if(userInfo && userInfo.user){
        let {planExpiry} = userInfo.user;
        let {time1, time2, success} = getBothTimesInSameLength(planExpiry, new Date().getTime());
    
        return success && time1 < time2;
    }

    return false;
}