import React, { Component } from 'react';
import Loading from '../Loading';
import Modal from 'react-modal';
import { APP_OPTIONS, Colors, ORGANIZATION_TYPES, SCREEN_NAMES, validateEmail } from '../../constants';
import { Fragment } from 'react';
import { GetLoggedInUserToken, HttpRequest, HttpRequestTypes } from '../../config/GeneralMethods';
import { ENV } from '../../config';
import { confirmAlert } from 'react-confirm-alert';
import AdminCompanies from './AdminCompanies';
import moment from "moment";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Select from 'react-select'
import makeAnimated from 'react-select/animated';

import TOFES4_LOGO from '../../assets/images/tofes4_new.png';
import ESIGN_LOGO from '../../assets/images/logo.png';

const animatedComponents = makeAnimated();

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 500,
        maxWidth: '45vw'
    }
};

const plans = [
    { id: "0", title: "נסיון", docs: "10", expireAfter: 1000 * 60 * 60 * 24 * 14 },
    { id: "1", title: "Pro", docs: "100", expireAfter: 1000 * 60 * 60 * 24 * 30 },
    { id: "2", title: "Premuim", docs: "500", expireAfter: 1000 * 60 * 60 * 24 * 30 },
    { id: "3", title: "מותאם אישית", docs: "100", expireAfter: 1000 * 60 * 60 * 24 * 30 },
];

const periods = [
    { id: "1", title: "חודש" },
    { id: "2", title: "חצי שנה" },
    { id: "3", title: "שנה" }
];

class AdminOrganizations extends Component {

    state = {
        companies: [],
        isLoading: true,
        isModalVisible: false,
        successMessage: "",
        errorMessage: "",
        isSaving: false,
        name: "",
        plan: "0",
        planExpiry: new Date().getTime() + (1000 * 60 * 60 * 24 * 14),
        selectedCompany: null,
        numOfDocs: "10",
        peridId: "1",
        isPaymentModalVisible: false,
        pickedPlan: 1,
        finalPrice: "0",
        app: APP_OPTIONS.ESIGN,
        council: "",
        councils: [],
        regions: [],
        region: "",
        allCouncils: [],
        organizationTypesSelected: [],
        primaryOrganizationType: "",
        orgNames: "",
        primaryEmailForDocument: ""
    }


    componentDidMount() {
        this.loadCompanies();
        this.loadCouncils();
    }

    loadCompanies = async () => {
        let companies = [];

        let url = `${ENV.API_URL}/${ENV.COMPANY_LIST_URL}`;
        let res = await HttpRequest(url, null, HttpRequestTypes.GET, GetLoggedInUserToken());

        if (res && res.success) {
            let data = res.data || [];

            if (data.length > 0) {
                companies = data;
            }
        }

        this.setState({ companies, isLoading: false });
        this.resetModal();
    }

    loadCouncils = async () => {
        try {
            let url = `${ENV.API_URL}/${ENV.CONCILS_URL}/get-list`
            let res = await HttpRequest(url, null, HttpRequestTypes.GET, GetLoggedInUserToken());

            if (res) {
                let { regions, councils } = res;

                let allCouncils = [...councils];
                let region = regions && regions.length > 0 ? regions[0]._id : "";
                councils = councils.filter(val => val.regionId === region);
                let council = councils && councils.length > 0 ? councils[0].name : "";

                this.setState({ councils, council, allCouncils, region, regions });
            }
        } catch (e) {
            console.error(e);
        }
    }

    resetModal = () => {
        this.setState({
            plan: "0",
            planExpiry: new Date().getTime() + (1000 * 60 * 60 * 24 * 14),
            selectedCompany: null,
            numOfDocs: "10",
            name: "",
            app: APP_OPTIONS.ESIGN,
            council: "",
            organizationTypesSelected: [],
            primaryOrganizationType: "",
            orgNames: "",
            primaryEmailForDocument: ""
        });
    }

    addNewCompany = () => {
        this.resetModal();
        this.setState({ selectedCompany: null });

        setTimeout(() => {
            this.setState({ isModalVisible: true })
        }, 300);
    }

    editCompany = (id) => {

        let { companies } = this.state;
        let index = this.getIndexOfCompanyById(id);

        if (index !== -1) {

            let selectedCompany = companies[index];

            let name = selectedCompany.name;
            let plan = this.getPlanIdByItsValue(selectedCompany.plan);
            let planExpiry = selectedCompany.planExpiry;
            let numOfDocs = selectedCompany.documentsPerMonth;
            let app = selectedCompany.app || APP_OPTIONS.ESIGN;
            let council = selectedCompany.council || null;

            if (app === APP_OPTIONS.TOFES4) {
                let { allCouncils } = this.state;
                let councilObjArray = allCouncils.filter(val => val.name === council);

                if (councilObjArray && councilObjArray.length > 0) {
                    let councilObj = councilObjArray[0];

                    let region = councilObj.regionId;
                    let councils = allCouncils.filter(val => val.regionId === region);

                    this.setState({ region, councils });
                }

                let { organizationTypesSelected = [], primaryOrganizationType = "", primaryEmailForDocument = "" } = selectedCompany;
                organizationTypesSelected = organizationTypesSelected.map(item => { return { label: item, value: item } })
                this.setState({ organizationTypesSelected: organizationTypesSelected, primaryOrganizationType, primaryEmailForDocument })
            }

            this.setState({ selectedCompany, name, plan, planExpiry, numOfDocs, app, council, orgNames: "" });

            setTimeout(() => {
                this.setState({ isModalVisible: true })
            }, 300);
        }
    }

    getPlanIdByItsValue = (val) => {
        let planId = "0";

        for (let i = 0; i < plans.length; i++) {
            if (plans[i].title === val) {
                planId = plans[i].id;
                break;
            }
        }

        return planId;
    }

    deleteCompany = async (id) => {
        //SHOW POPUP MESSAGE
        confirmAlert({
            title: "מחיקת ארגון",
            message: "פעולה זו תמחק גם את כל העובדים השייכים לאותו ארגון",
            buttons: [
                {
                    label: "לא, סגור",
                    onClick: () => { },
                },
                {
                    label: "כן",
                    onClick: () => {
                        this.removeCompanyFromDB(id);
                    },
                },
            ],
        });
    }

    removeCompanyFromDB = async (id) => {
        let url = `${ENV.API_URL}/${ENV.COMPANY_LIST_URL}/${id}`;
        let res = await HttpRequest(url, null, HttpRequestTypes.DELETE, GetLoggedInUserToken());

        if (res && res.success) {
            this.loadCompanies();
        }
    }

    closeModal = () => {
        this.setState({ isModalVisible: false, successMessage: "" });
    }

    submit = async () => {
        let { selectedCompany, plan, planExpiry, name, numOfDocs, council, app, organizationTypesSelected, primaryOrganizationType, orgNames, primaryEmailForDocument } = this.state;
        this.setState({ successMessage: "", errorMessage: "", isSaving: true });

        if (app === APP_OPTIONS.TOFES4) {
            organizationTypesSelected = organizationTypesSelected.map(item => item.value);
            if (organizationTypesSelected.includes("הכל")) {
                organizationTypesSelected = [...ORGANIZATION_TYPES];
            }

            if (orgNames.trim().length > 0) {
                let orgNamesList = orgNames.split(',').map(item => item.trim());

                if (orgNamesList.length > 0) {
                    organizationTypesSelected = organizationTypesSelected.concat(orgNamesList);
                    organizationTypesSelected = organizationTypesSelected.filter((item, index) => {
                        return organizationTypesSelected.indexOf(item) === index;
                    })
                }
            }
        }

        if (name.length === 0) {
            this.setState({ errorMessage: "חובה להזין שם ארגון" });
        } else if (numOfDocs.length === 0) {
            this.setState({ errorMessage: "חובה להזין מספר מסמכים חודשיי" });
        } else if (app === APP_OPTIONS.TOFES4 && organizationTypesSelected.length === 0) {
            this.setState({ errorMessage: "חובה לבחור את רשימת המחקות בתוך מועצה זו" })
        } else if (app === APP_OPTIONS.TOFES4 && primaryOrganizationType.length === 0) {
            this.setState({ errorMessage: "חובה לבחור מחלקה ראשית" })
        } else if (app === APP_OPTIONS.TOFES4 && primaryEmailForDocument.trim().length > 0 && !validateEmail(primaryEmailForDocument.trim())) {
            this.setState({ errorMessage: "חובה להזין דוא״ל ראשי תקין" })
        } else {

            let isAdd = selectedCompany === null;
            let successMessage = isAdd ? "ארגון נוצר בהצלחה" : "פרטי הארגון נערכו בהצלחה";

            if (isAdd) {
                let url = `${ENV.API_URL}/${ENV.COMPANY_LIST_URL}`;
                let params = {
                    name,
                    documentsPerMonth: numOfDocs,
                    plan,
                    planExpiry,
                    council: app === APP_OPTIONS.TOFES4 ? council : null,
                    app
                };

                if (app === APP_OPTIONS.TOFES4) {
                    params["organizationTypesSelected"] = organizationTypesSelected;
                    params["primaryOrganizationType"] = primaryOrganizationType;
                    params["primaryEmailForDocument"] = primaryEmailForDocument;
                }

                let res = await HttpRequest(url, params, HttpRequestTypes.POST, GetLoggedInUserToken());

                if (res && res.success) {
                    this.loadCompanies();
                    this.setState({ successMessage });

                    setTimeout(() => {
                        this.closeModal();
                    }, 1200);

                } else {
                    this.setState({ errorMessage: res && res.message ? res.message : "אירעה שגיאה, נא לנסות שנית בעוד כמה דקות" })
                }
            } else {
                let index = this.getIndexOfCompanyById(selectedCompany._id);

                if (index !== -1) {
                    let url = `${ENV.API_URL}/${ENV.COMPANY_LIST_URL}/edit`;
                    let params = {
                        name,
                        documentsPerMonth: numOfDocs,
                        plan,
                        planExpiry,
                        _id: selectedCompany._id,
                        council: app === APP_OPTIONS.TOFES4 ? council : null,
                        app
                    };

                    if (app === APP_OPTIONS.TOFES4) {
                        params["organizationTypesSelected"] = organizationTypesSelected;
                        params["primaryOrganizationType"] = primaryOrganizationType;
                        params["primaryEmailForDocument"] = primaryEmailForDocument;
                    }

                    let res = await HttpRequest(url, params, HttpRequestTypes.POST, GetLoggedInUserToken());

                    if (res && res.success) {
                        this.loadCompanies();
                        this.setState({ successMessage });

                        setTimeout(() => {
                            this.closeModal();
                        }, 1200);

                    } else {
                        this.setState({ errorMessage: res && res.message ? res.message : "אירעה שגיאה, נא לנסות שנית בעוד כמה דקות" })
                    }
                }
            }
        }

        this.setState({ isSaving: false });
    }


    getIndexOfCompanyById = (id) => {
        let { companies } = this.state;

        if (!companies || companies.length === 0) {
            return -1;
        }

        for (let i = 0; i < companies.length; i++) {
            if (companies[i]._id === id) {
                return i;
            }
        }

        return -1;
    }

    planChanged = (planId) => {
        let plan = this.getPlanById(planId);

        if (plan) {
            let planExpiry = new Date().getTime() + plan.expireAfter;
            this.setState({ plan: planId, numOfDocs: plan.docs, planExpiry });
        }
    }

    periodChanged = (peridId) => {
        let plan = this.getPlanById(this.state.plan);


        if (plan) {
            let planExpiry = new Date().getTime() + plan.expireAfter * this.getPeridCount(peridId);
            this.setState({ peridId, planExpiry, finalPrice: this.getFinalPrice(parseInt(plan.id), peridId) })
        }
    }

    getPeridCount = (peridId) => {
        switch (peridId) {
            case "2":
                return 6;

            case "3":
                return 12;

            case "1":
            default:
                return 1;

        }
    }

    getPlanById = (id) => {
        for (let i = 0; i < plans.length; i++) {
            if (plans[i].id === id) {
                return plans[i];
            }
        }

        return null;
    }

    getIsThisMatchesCertainApp = (app) => {
        return app === this.state.app;
    }

    regionChanged = (regionId) => {
        let { allCouncils } = this.state;
        let councils = allCouncils.filter(val => val.regionId === regionId);
        let council = councils && councils.length > 0 ? councils[0] : "";

        this.setState({ council, councils, region: regionId })
    }

    getOrganizationPrimaryList = () => {
        let { organizationTypesSelected } = this.state;

        return organizationTypesSelected.findIndex(item => item.value === "הכל") === -1
            ? organizationTypesSelected : ORGANIZATION_TYPES.map(item => { return { label: item, value: item } });
    }

    renderModalContent = () => {
        return (
            <Fragment>

                <label style={{ color: 'black', marginLeft: 10 }}>
                    בחירת מערכת
                </label>

                <div className='app-radio-box-container-parent'>
                    <div className={`app-radio-box-container ${this.state.app === APP_OPTIONS.TOFES4 ? 'active' : ''} ${this.state.selectedCompany !== null && this.state.app === APP_OPTIONS.ESIGN ? 'disabled' : ''}`}
                        onClick={() => this.state.selectedCompany === null && this.setState({ app: APP_OPTIONS.TOFES4 })}>
                        <label>טופס 4</label>
                        <img src={TOFES4_LOGO} alt='' className='smaller-img' />
                    </div>
                    <div className={`app-radio-box-container ${this.state.app === APP_OPTIONS.ESIGN ? 'active' : ''} ${this.state.selectedCompany !== null && this.state.app === APP_OPTIONS.TOFES4 ? 'disabled' : ''}`}
                        onClick={() => this.state.selectedCompany === null && this.setState({ app: APP_OPTIONS.ESIGN })}>
                        <label>איי סיין</label>
                        <img src={ESIGN_LOGO} alt='' />
                    </div>
                </div>


                <label style={{ color: 'black', marginLeft: 10 }}>
                    שם הארגון:
                </label>

                <input type="text" name="name" className="form-control form-control-user" style={{ textAlign: 'right' }} aria-describedby="emailHelp" placeholder="שם הארגון  "
                    onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} />

                {
                    this.getIsThisMatchesCertainApp(APP_OPTIONS.ESIGN) && (
                        <>
                            <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
                                בחירת תוכנית:
                            </label>

                            <select className="form-control" onChange={(e) => this.planChanged(e.target.value)}
                                value={this.state.plan}>
                                {
                                    plans.map((val, ind) =>
                                        <option key={ind} value={val.id}>{val.title}</option>
                                    )
                                }
                            </select>
                        </>
                    )
                }

                {
                    this.getIsThisMatchesCertainApp(APP_OPTIONS.TOFES4) && (
                        <>
                            <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
                                בחירת איזור:
                            </label>

                            <select className="form-control" onChange={(e) => this.regionChanged(e.target.value)}
                                value={this.state.region}>
                                {
                                    this.state.regions.map((val, ind) =>
                                        <option key={ind} value={val._id}>{val.name}</option>
                                    )
                                }
                            </select>
                            <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
                                בחירת מועצה:
                            </label>

                            <select className="form-control" onChange={(e) => this.setState({ council: e.target.value })}
                                value={this.state.council}>
                                {
                                    this.state.councils.map((val, ind) =>
                                        <option key={ind} value={val.name}>{val.name}</option>
                                    )
                                }
                            </select>

                            <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
                                בחירת רשימת המחלקות:
                            </label>
                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                options={["הכל", ...ORGANIZATION_TYPES].map(item => { return { label: item, value: item } })}
                                isMulti
                                value={this.state.organizationTypesSelected}
                                onChange={(selected) => this.setState({ organizationTypesSelected: selected })}
                                placeholder="בחירת רשימת המחלקות"
                            />

                            <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
                                או יש להזין ידנית עם פסיק בין כל מחלקה
                            </label>

                            <input type="text" name="name" className="form-control form-control-user" style={{ textAlign: 'right' }} aria-describedby="emailHelp" placeholder="הוספת מחלקות באופן ידני"
                                onChange={(e) => this.setState({ orgNames: e.target.value })} value={this.state.orgNames} />

                            <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
                                בחירת מחלקה ראשית:
                            </label>
                            <select className="form-control" onChange={(e) => this.setState({ primaryOrganizationType: e.target.value })}
                                value={this.state.primaryOrganizationType}>
                                <option value="">בחירת מחלקה ראשית</option>
                                {
                                    this.getOrganizationPrimaryList().map((val, ind) =>
                                        <option key={ind} value={val.value}>{val.label}</option>
                                    )
                                }
                            </select>

                            <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
                                דוא״ל ראשי לקבלת מסמכים
                            </label>

                            <input type="text" name="email" className="form-control form-control-user" style={{ textAlign: 'right' }} aria-describedby="emailHelp" 
                                placeholder="דוא״ל ראשי לקבלת מסמכים"
                                onChange={(e) => this.setState({ primaryEmailForDocument: e.target.value })} value={this.state.primaryEmailForDocument} />

                        </>
                    )
                }

                {
                    this.getIsThisMatchesCertainApp(APP_OPTIONS.ESIGN) && (
                        <>
                            <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
                                החבילה תפוג בתאריך:
                            </label>

                            <input type="text" className="form-control form-control-user" style={{ textAlign: 'right' }} aria-describedby="emailHelp"
                                value={moment(this.state.planExpiry).format("DD/MM/yyyy")} disabled={true} />

                            <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
                                מספר המסמכים:
                            </label>

                            <input type="number" min="1" className="form-control form-control-user" style={{ textAlign: 'right' }} aria-describedby="emailHelp" placeholder="מספר מסמכים  "
                                onChange={(e) => this.setState({ numOfDocs: e.target.value })} value={this.state.numOfDocs} disabled={this.state.plan !== "3"} />

                        </>
                    )
                }


                {
                    this.state.isSaving ?
                        <div style={{ marginTop: 25 }}>
                            <Loading />
                        </div>
                        :
                        <div className="text-center w-50 btn-send-email" style={{ marginTop: 25 }}>
                            <input type="button" className="btn btn-user btn-custom btn-block" style={{ backgroundColor: Colors.PRIMARY_YELLOW }} value={this.state.selectedCompany === null ? "הוספה" : "שמירת שינויים"} onClick={this.submit} />
                        </div>
                }

                <div className="mt-3 text-right mr-2"><span className="text-success">{this.state.successMessage}</span></div>
                <div className="mt-3 text-right mr-2"><span className="text-danger">{this.state.errorMessage}</span></div>
            </Fragment>
        );
    }

    addEmailTextBox = () => {
        let { emails } = this.state.contactItem;
        emails.push('');
        this.setState({ emails });
    }

    removeEmailTextBox = (ind) => {
        let { emails } = this.state.contactItem;

        if (ind < emails.length) {
            emails.splice(ind, 1)
            this.setState({ emails });
        }
    }

    openCompany = (company) => {
        this.props.openCompany(company);
    }

    payService = (id) => {
        let { companies } = this.state;
        let index = this.getIndexOfCompanyById(id);

        if (index !== -1) {
            let selectedCompany = companies[index];

            let name = selectedCompany.name;
            let plan = this.getPlanIdByItsValue(selectedCompany.plan);
            let planExpiry = selectedCompany.planExpiry;
            let numOfDocs = selectedCompany.documentsPerMonth;
            let finalPrice = this.getFinalPrice(plan);

            this.setState({ selectedCompany, name, plan, planExpiry, numOfDocs, finalPrice, peridId: "1" });

            setTimeout(() => {
                this.setState({ isPaymentModalVisible: true })
            }, 300);
        }
    }

    getFinalPrice = (plan, pickedPlan) => {
        let minBase = 49, maxBase = 79;
        let price = 0;

        if (typeof (plan) === typeof ("")) {
            plan = parseInt(plan);
        }

        pickedPlan = pickedPlan || this.state.pickedPlan;

        if (typeof (pickedPlan) === typeof ("")) {
            pickedPlan = parseInt(pickedPlan);
        }

        switch (pickedPlan) {
            case 2:
                price = plan === 1 ? minBase * 6 : maxBase * 6;
                break;

            case 3:
                price = plan === 1 ? minBase * 12 : maxBase * 12;
                break;

            case 1:
            default:
                price = plan === 1 ? minBase : maxBase;
                break;
        }

        return price;
    }

    payNow = async () => {
        let description = "";
        let plan = parseInt(this.state.plan) + 1 || 1;
        let { selectedCompany } = this.state;
        let price = plan === 4 ? parseInt(this.state.finalPrice) : this.getFinalPrice(plan, this.state.peridId);

        if (!selectedCompany) {
            return;
        }

        switch (this.state.pickedPlan) {
            case 2:
                description = "מסלול חצי שנתי";
                break;

            case 3:
                description = "מסלול שנתי";
                break;

            case 1:
            default:
                description = "מסלול חודשי";
                break;
        }

        let unique_id = selectedCompany._id + '_' + plan + '_' + this.state.pickedPlan + '_' + price + '_' + selectedCompany.documentsPerMonth;

        let data = {
            Key: '9d618041d751ef91d3c46e934cbff8e5a690bb7c7dfb955d524fcf3d16d88325',
            Local: 'He',
            UniqueId: unique_id,
            SuccessUrl: `${ENV.CLIENT_URL}${SCREEN_NAMES.PAYMENT_SUCCESS_SCREEN}`,
            CancelUrl: `${ENV.CLIENT_URL}${SCREEN_NAMES.PAYMENT_CANCEL_SCREEN}`,
            CallbackUrl: `${ENV.API_URL}/${ENV.USERS_API_URL}/callbackCompany`,
            PaymentType: 'regular',
            CreateInvoice: 'true',
            AdditionalText: '',
            ShowCart: 'false',
            Installments: {
                Type: 'regular',
                MinQuantity: '1',
                MaxQuantity: 12
            },
            Customer: {
                Email: "",
                Name: "",
                PhoneNumber: "",
                Attributes: {
                    HolderId: 'optional',
                    Name: 'required',
                    PhoneNumber: 'required',
                    Email: 'optional'
                }
            },
            CartItems: [
                {
                    Amount: price,
                    Currency: 'ILS',
                    Name: 'מנוי חודשי של esign',
                    Description: description,
                    Quantity: 1,
                    Image: 'https://i.ibb.co/d2gHWfR/Iphones.png',
                    IsTaxFree: 'false'
                }
            ]
        }


        let url = "https://pci.zcredit.co.il/webcheckout/api/WebCheckout/CreateSession";
        let res = await HttpRequest(url, data, HttpRequestTypes.POST, null);

        if (res && res.Data.SessionUrl) {
            window.location.href = res.Data.SessionUrl;
        }
    }

    closePaymentService = () => {
        this.setState({ isPaymentModalVisible: false });
    }

    renderPaymentModalContent = () => {
        return (
            <Fragment>

                <label style={{ color: 'black', marginLeft: 10 }}>
                    שם הארגון:
                </label>

                <input type="text" name="name" className="form-control form-control-user" style={{ textAlign: 'right' }} aria-describedby="emailHelp" placeholder="שם הארגון  "
                    value={this.state.selectedCompany?.name || ""} disabled={true} />

                <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
                    תוכנית נבחרה:
                </label>

                <select className="form-control"
                    value={this.getPlanIdByItsValue(this.state.selectedCompany?.plan) || "0"} disabled={true}>
                    {
                        plans.map((val, ind) =>
                            <option key={ind} value={val.id}>{val.title}</option>
                        )
                    }
                </select>

                {(this.getPlanIdByItsValue(this.state.selectedCompany?.plan) || "0") !== "0" ?
                    <Fragment>
                        <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
                            בחר תקופת החבילה:
                        </label>

                        <select className="form-control" onChange={(e) => this.periodChanged(e.target.value)}
                            value={this.state.peridId}>
                            {
                                periods.map((val, ind) =>
                                    <option key={ind} value={val.id}>{val.title}</option>
                                )
                            }
                        </select>
                    </Fragment> :
                    null
                }

                <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
                    החבילה תפוג בתאריך:
                </label>

                <input type="text" className="form-control form-control-user" style={{ textAlign: 'right' }} aria-describedby="emailHelp"
                    value={moment(this.state.planExpiry).format("DD/MM/yyyy")} disabled={true} />

                <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
                    מספר המסמכים:
                </label>

                <input type="number" min="1" className="form-control form-control-user" style={{ textAlign: 'right' }} aria-describedby="emailHelp" placeholder="מספר מסמכים  "
                    onChange={(e) => this.setState({ numOfDocs: e.target.value })} value={this.state.numOfDocs} disabled={this.state.plan !== "3"} />

                <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
                    סה"כ לתשלום:
                </label>

                <input type="number" min="1" className="form-control form-control-user" style={{ textAlign: 'right' }} aria-describedby="emailHelp" placeholder="מספר מסמכים  "
                    onChange={(e) => this.setState({ finalPrice: e.target.value })} value={this.state.finalPrice} />

                {
                    this.state.isSaving ?
                        <div style={{ marginTop: 25 }}>
                            <Loading />
                        </div>
                        :
                        <div className="text-center w-50 btn-send-email" style={{ marginTop: 25 }}>
                            <input type="button" className="btn btn-user btn-custom btn-block" style={{ backgroundColor: Colors.PRIMARY_YELLOW }} value="שלם עכשיו" onClick={this.payNow} />
                        </div>
                }

                <div className="mt-3 text-right mr-2"><span className="text-success">{this.state.successMessage}</span></div>
                <div className="mt-3 text-right mr-2"><span className="text-danger">{this.state.errorMessage}</span></div>
            </Fragment>
        );
    }


    render() {
        return (
            <>
                <div className="mt-3 d-flex justify-content-between align-items-center home-container">

                    <div className="">
                        <button className="btn prim-btn no-animation custom-box-shadow" style={{}}
                            onClick={this.addNewCompany}>
                            הוספת ארגון חדש
                        </button>
                    </div>

                    <div className="text-right">
                        <h4 style={{ fontWeight: 'bold' }}>רשימת הארגונים במערכת</h4>
                    </div>
                </div>

                <div className="mt-3 mb-3 dir-rtl">
                    {
                        this.state.isLoading ?
                            <Loading />
                            : <AdminCompanies data={this.state.companies}
                                editCompany={this.editCompany.bind(this)}
                                deleteCompany={this.deleteCompany.bind(this)}
                                openCompany={this.openCompany.bind(this)}
                                payService={this.payService.bind(this)} />
                    }
                </div>

                <Modal
                    ariaHideApp={false}
                    isOpen={this.state.isModalVisible}
                    style={customStyles}>

                    <div>
                        <div className="d-flex justify-content-between align-items-center">
                            <div></div>
                            <div>
                                <label className="labelModal">
                                    {this.state.selectedCompany === null ? "הוספת ארגון חדש" : "עריכת פרטי ארגון"}
                                </label>
                            </div>
                            <div>
                                <i className="fa fa-times pointer" onClick={this.closeModal}></i>
                            </div>
                        </div>

                        <div style={{ direction: 'rtl', marginTop: 20, textAlign: 'right' }}>
                            {this.renderModalContent()}
                        </div>
                    </div>
                </Modal>


                <Modal
                    ariaHideApp={false}
                    isOpen={this.state.isPaymentModalVisible}
                    style={customStyles}>

                    <div>
                        <div className="d-flex justify-content-between align-items-center">
                            <div></div>
                            <div>
                                <label className="labelModal">
                                    תשלום בכ. אשראי עבור שירות חתימה דיגטלית
                                </label>
                            </div>
                            <div>
                                <i className="fa fa-times pointer" onClick={this.closePaymentService}></i>
                            </div>
                        </div>

                        <div style={{ direction: 'rtl', marginTop: 20, textAlign: 'right' }}>
                            {this.renderPaymentModalContent()}
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}

export default AdminOrganizations;