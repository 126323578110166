import React, { Component } from 'react';
import { ENV } from '../config';
import { GetLoggedInUserInfo, GetLoggedInUserToken, HttpRequest, HttpRequestTypes } from '../config/GeneralMethods';
import { Colors, CONSTANT_KEYS, SCREEN_NAMES } from '../constants';

export default class Success extends Component{

    componentDidMount(){
        //remove the side bar
        const sidebarContainer = document.getElementsByClassName("sidebar-container");

        if(sidebarContainer && sidebarContainer.length > 0){
            sidebarContainer[0].style.display = "none";
        }

        //Make App-main Full Width
        const appMain = document.getElementById("appMain");

        if(appMain){
            appMain.style.width = "100%";
        }

        //Hide TopNavBar
        const topNavBar = document.getElementsByClassName("topnavbar-container");
        
        if(topNavBar && topNavBar.length > 0){
            topNavBar[0].style.display = "none";
        }

        //Need to Update User Session
        this.updateUserSession();
    }

    updateUserSession = async () => {
        try{
            let url = `${ENV.API_URL}/${ENV.USERS_API_URL}/getUserByItsToken`;
            let res = await HttpRequest(url, null, HttpRequestTypes.GET, GetLoggedInUserToken());

            if(res && res.success){


                let userInfoExpiry = GetLoggedInUserInfo();

                if(userInfoExpiry){
                    userInfoExpiry.user = res.user;
                    localStorage.setItem(CONSTANT_KEYS.USER_INFO, JSON.stringify(userInfoExpiry));
                }
            }

        }catch(e){
            console.log(e);
        }
    }

    render(){
        return (
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>

                <img src={require("../assets/images/check_icon.png")} alt="" style={{width: 200, height: 200, marginTop: 20}} />

                <h2>התשלום בוצע בהצלחה</h2>
                <h5>חשבונית עבור העסקה שביצעת נשלחה לדואר אלקטרוני שלך</h5>

                <button onClick={() => window.location.href = SCREEN_NAMES.HOME_SCREEN}
                    style={{marginTop: 20, backgroundColor: Colors.PRIMARY_YELLOW, color: "white"}} className="btn custom-btn">
                    חזור לדף הבית
                </button>
            </div>
        );
    }
}