import React from 'react';
import {Route, Switch} from 'react-router-dom';
import { SCREEN_NAMES } from '../constants';
import Cancel from '../screens/Cancel';
import ForgetPassword from '../screens/ForgetPassword';
import Home from '../screens/Home';
import Login from '../screens/Login';
import NewPassword from '../screens/NewPassword';
import Pricing from '../screens/Pricing';
import Register from '../screens/Register';
import Success from '../screens/Success';

const AuthRouter = () => {
    return (
        <Switch>
            <Route exact path={SCREEN_NAMES.LOGIN_SCREEN} component={Login} />
            <Route exact path={SCREEN_NAMES.FORGET_PASSWORD_SCREEN} component={ForgetPassword}/>
            <Route exact path={SCREEN_NAMES.NEW_PASSWORD_SCREEN} component={NewPassword}/>
            <Route exact path={SCREEN_NAMES.REGISTER_SCREEN} component={Register}/>
            <Route exact path={SCREEN_NAMES.HOME_SCREEN} component={Home} />
            <Route exact path={SCREEN_NAMES.PRICING_SCREEN} component={Pricing}/>
            <Route exact path={SCREEN_NAMES.PAYMENT_SUCCESS_SCREEN} component={Success}/>
                <Route exact path={SCREEN_NAMES.PAYMENT_CANCEL_SCREEN} component={Cancel}/>
        </Switch>
    );
}

export default AuthRouter;
