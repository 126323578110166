import React, { Component } from 'react';

import "../assets/styles/pricing.css";
import { GetLoggedInUserInfo, HttpRequest, HttpRequestTypes } from '../config/GeneralMethods';
import moment from "moment";
import { getBothTimesInSameLength, SCREEN_NAMES } from '../constants';
import { ENV } from '../config';
import { Fragment } from 'react';

const fields = [
    "חתימה דיגטלית על מסמכים בכל מקום ובכל עת",
    "שליחת מסמך לחתימה במייל",
    "שליחת מסמך לחתימה בקישור",
    "עריכת טפסים אונליין",
    "ממשק נוח ומהיר"
];

export default class Pricing extends Component{

    state = {
        isPlanExpired: true,
        planExpire: 0,
        pickedPlan: 1
    }

    componentDidMount(){

        let userInfo = GetLoggedInUserInfo();
        let {planExpiry} = userInfo?.user || undefined;

        let {time1, time2, success} = getBothTimesInSameLength(planExpiry, new Date().getTime());

        if(success && time1 > time2){
            this.setState({ isPlanExpired: false, planExpire: time1 })
        } else {

            //remove the side bar
            const sidebarContainer = document.getElementsByClassName("sidebar-container");

            if(sidebarContainer && sidebarContainer.length > 0){
                sidebarContainer[0].style.display = "none";
            }

            //Make App-main Full Width
            const appMain = document.getElementById("appMain");

            if(appMain){
                appMain.style.width = "100%";
            }

            //Hide Profile from Dropdown
            const profileItem = document.querySelector(".ddl-ul.custom-box-shadow > li:first-child");
            
            if(profileItem){
                profileItem.style.display = "none";
            }

            //Hide Notifications Icon
            const notificationIcon = document.getElementsByClassName("notification-inner-container pointer");
            
            if(notificationIcon && notificationIcon.length > 0){
                notificationIcon[0].style.display = "none";
            }
        }
    }

    planPicked = async (plan) => {

        let minBase = 49, maxBase = 79;
        let price = 0, description = ""; 
        plan = plan + 1 || 1;

        switch(this.state.pickedPlan){
            case 2:
                price = plan === 1 ? minBase * 6 : maxBase * 6;
                description = "מסלול חצי שנתי";
                break;

            case 3:
                price = plan === 1 ? minBase * 12 : maxBase * 12;
                description = "מסלול שנתי";
                break;

            case 1:
            default:
                price = plan === 1 ? minBase : maxBase;
                description = "מסלול חודשי";
                break;
        }

        let userInfo = GetLoggedInUserInfo();
        let user = userInfo.user || {};
        let unique_id = user._id + '_' + plan + '_' + this.state.pickedPlan + '_' + price + '_' + 0;

        let data = {
            Key: '9d618041d751ef91d3c46e934cbff8e5a690bb7c7dfb955d524fcf3d16d88325',
            Local: 'He',
            UniqueId: unique_id,
            SuccessUrl: `${ENV.CLIENT_URL}${SCREEN_NAMES.PAYMENT_SUCCESS_SCREEN}`,
            CancelUrl: `${ENV.CLIENT_URL}${SCREEN_NAMES.PAYMENT_CANCEL_SCREEN}`,
            CallbackUrl: `${ENV.API_URL}/${ENV.USERS_API_URL}/callback`,
            PaymentType: 'regular',
            CreateInvoice: 'true',
            AdditionalText: '',
            ShowCart: 'false',
            Installments: {
                Type: 'regular',
                MinQuantity: '1',
                MaxQuantity: 12
            },
            Customer: {
                Email: user.email,
                Name: user.name,
                PhoneNumber: user.phone,
                Attributes: {
                HolderId: 'optional',
                    Name: 'required',
                    PhoneNumber: 'required',
                    Email: 'optional'
                }
            },
            CartItems: [
                {
                    Amount: price,
                    Currency: 'ILS',
                    Name: 'מנוי חודשי של esign',
                    Description: description,
                    Quantity: 1,
                    Image: 'https://i.ibb.co/d2gHWfR/Iphones.png',
                    IsTaxFree: 'false'
                }
            ]
        }


        let url = "https://pci.zcredit.co.il/webcheckout/api/WebCheckout/CreateSession";
        let res = await HttpRequest(url, data, HttpRequestTypes.POST, null);
        
        if(res && res.Data.SessionUrl){
            window.location.href = res.Data.SessionUrl;   
        }
    }

    getCurrentPeriod = (ind) => {

        let money = 0, period = "";
        let minBase = 49, maxBase = 79;

        switch(this.state.pickedPlan) {
            case 2:
                money = ind === 0 ? minBase * 6: maxBase * 6;
                period = "חצי שנה";
                break;

            case 3:
                money = ind === 0 ? minBase * 12 : maxBase * 12;
                period = "שנה";
                break;
            
            case 1:
            default:
                money = ind === 0 ? minBase: maxBase;
                period = "חודש";
                break;
        }

        return (
            <Fragment><span className="period">₪</span>{money}<span className="period">/{period}</span></Fragment>
        );
    }

    render(){
        return (
            <div>
                <div className="">
                    
                    <div className="text-center mt-3">
                        <h2>חידוש חבילה</h2>
                        <label>{this.state.isPlanExpired ? "החבילה שלך נגמרה, נא לחדש אותה כדי להמשיך להינות מהשירותים שלנו" : 
                        `החבילה שלך תיגמר בתאריך ${moment(this.state.planExpire).format("DD/MM/yyyy")}`}</label>
                    </div>
                    
                    <div className="active">
                        <section className="pricing" style={{direction: "rtl"}}>
                            <div className="container">
                                <div className="">
                                    <ul class="nav nav-tabs justify-content-center">
                                        <li class="nav-item" onClick={() => this.setState({ pickedPlan: 1 })}>
                                            <a class="nav-link active" data-toggle="tab" href="#month">מסלול חודשי</a>
                                        </li>
                                        <li class="nav-item" onClick={() => this.setState({ pickedPlan: 2 })}>
                                            <a class="nav-link" data-toggle="tab" href="#half">מסלול חצי שנתי</a>
                                        </li>
                                        <li class="nav-item" onClick={() => this.setState({ pickedPlan: 3 })}>
                                            <a class="nav-link" data-toggle="tab" href="#year">מסלול שנתי</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="py-4 row justify-content-center">
                                    {
                                        Array.from(new Array(2)).map( (val, ind) => 
                                        <div className="col-lg-4" key={ind}>
                                            <div className="card mb-5 mb-lg-0">
                                                <div className="card-body">
                                                    <h5 className="card-title text-muted text-uppercase text-center">{ind === 0 ? "Pro" : "Premium"}</h5>
                                                    <h6 className="card-price text-center">{this.getCurrentPeriod(ind)}</h6>
                                                    <hr />
                                                    <ul className="features">
                                                        <li><strong>עד {ind === 0 ? "100" : "500"} מסמכים</strong></li>
                                                        {
                                                            fields.map((value, index) => 
                                                                <li key={"LI_" + index}>{value}</li>
                                                            )
                                                        }
                                                    </ul>
                                                    
                                                    <button type="button" className="btn btn-block btn-custom text-uppercase" onClick={() => this.planPicked(ind)}>בחר חבילה</button>
                                                </div>
                                            </div>
                                        </div>
                                        )
                                    }

                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}