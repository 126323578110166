import React, { useState, useEffect } from 'react';

import {BrowserRouter as Router} from 'react-router-dom';
import AppRouter from '../components/AppRouter';
import TopNavBar from '../components/TopNavBar';
import SideBar from '../components/SideBar'
import AuthRouter from './AuthRouter';
import { SCREEN_NAMES } from '../constants';
import { GetLoggedInUserInfo, Logout } from '../config/GeneralMethods';

const MainRouter = (props) => {

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const isNotRequiredLoginScreens = (currLocation) => {

        return (
            currLocation.includes(SCREEN_NAMES.LOGIN_SCREEN) ||
            currLocation.includes(SCREEN_NAMES.REGISTER_SCREEN) ||
            currLocation.includes(SCREEN_NAMES.FORGET_PASSWORD_SCREEN) || 
            currLocation.includes(SCREEN_NAMES.NEW_PASSWORD_SCREEN)
        );
    }

    const isNotAuthorizedAfterLogin = (currLocation) => {

        return (
            currLocation.includes(SCREEN_NAMES.LOGIN_SCREEN) ||
            currLocation.includes(SCREEN_NAMES.REGISTER_SCREEN) ||
            currLocation.includes(SCREEN_NAMES.FORGET_PASSWORD_SCREEN) || 
            currLocation.includes(SCREEN_NAMES.NEW_PASSWORD_SCREEN)
        );
    }

    useEffect(() => {

        let userInfo = GetLoggedInUserInfo();

        let currLocation = window.location.href;
        currLocation = currLocation.replace("http://", "").replace("https://", "").replace(window.location.host, "");

        if(userInfo){

            if(userInfo.expiry < new Date().getTime()){
               Logout();
            } else {
                setIsLoggedIn(true);
                if(isNotAuthorizedAfterLogin(currLocation)){
                    window.location.href = SCREEN_NAMES.HOME_SCREEN;
                }
            }

        } else {
            if(!isNotRequiredLoginScreens(currLocation)){
                window.location.href = SCREEN_NAMES.LOGIN_SCREEN + "?returnUrl=" + currLocation;
            }
        }

    }, [isLoggedIn]);

    return (
        isLoggedIn ? 
            <div className="app-container" id="appContainer">

                <div className="app-main" id="appMain">

                    <TopNavBar />

                    <Router>
                        <AppRouter />
                    </Router>

                </div>

                <SideBar /> 
            </div>
        :
        <Router>
            <AuthRouter />
        </Router> 
    );
}
export default MainRouter;