import moment from 'moment';
import React, { Component, Fragment } from 'react';
//import DataTable from 'react-data-table-component';
import DataTable from 'react-data-table-component-with-filter';

export default class AdminExternalOrgsList extends Component {

    state = {
        columns: [
            {
                name: '', sortable: false, center: true, style: { fontSize: 14 },
                cell: row => <Fragment>
                    <button className="icon-button" data-tooltip="מחיקה" onClick={() => this.props.delete(row._id, row?.user?._id)}>
                        <span className="tooltiptext">מחיקה</span>
                        <i className="eye fa fa-trash text-danger"></i>
                    </button>
                    <button className="icon-button ml-2" data-tooltip="עריכה" onClick={() => this.props.edit(row?.user?._id)}>
                        <span className="tooltiptext">עריכה</span>
                        <i className="download fa fa-pencil"></i>
                    </button>
                </Fragment>
            },
            { name: 'קטגוריה', sortable: true, selector: row => row.name,  filterable: true, center: true, style: { fontSize: 14, textAlign: "center" } },
            { name: 'מועצה', sortable: true, selector: row => row.council, filterable: true, center: true, style: { fontSize: 14, textAlign: "center" } },
            { name: 'שם מלא', sortable: true, selector: row => row.user.fullName, filterable: true, center: true, style: { fontSize: 14, textAlign: "center" },
                cell: row => <label>{row?.user?.fullName || ""}</label>
            },
            { name: 'שם החברה', sortable: true, selector: row => row.user.companyName, filterable: true, center: true, style: { fontSize: 14, textAlign: "center" },
                cell: row => <label>{row?.user?.companyName || ""}</label>
            },
            { name: 'דוא״ל', sortable: true, selector: row => row.user.email, filterable: true, center: true, style: { fontSize: 14, textAlign: "center" },
                cell: row => <label>{row?.user?.email || ""}</label>
            },
            { name: 'טלפון', sortable: true, selector: row => row.user.phone, filterable: true, center: true, style: { fontSize: 14, textAlign: "center" },
                cell: row => <label>{row?.user?.phone || ""}</label>
            },
            {
                name: 'תאריך יצירה', sortable: true, selector: row => row.createdAt, center: true, style: { fontSize: 14, textAlign: "center" },
                cell: row => <label>{moment(row.createdAt).format("DD/MM/YYYY HH:mm:ss")}</label>
            }
        ],
        emptyColumns: [
            { name: '', sortable: false, selector: row => row.actions, center: true, style: { fontSize: 14, textAlign: "center" } },
            { name: 'שם הטופס', sortable: true, selector: row => row.name, center: true, style: { fontSize: 14, textAlign: "center" } },
            { name: 'מועצה', sortable: true, selector: row => row.council, center: true, style: { fontSize: 14, textAlign: "center" } },
            { name: 'שם מלא', sortable: true, selector: row => row.user.fullName, center: true, style: { fontSize: 14, textAlign: "center" } },
            { name: 'שם החברה', sortable: true, selector: row => row.user.companyName, center: true, style: { fontSize: 14, textAlign: "center" } },
            { name: 'דוא״ל', sortable: true, selector: row => row.user.email, center: true, style: { fontSize: 14, textAlign: "center" } },
            { name: 'טלפון', sortable: true, selector: row => row.user.phone, center: true, style: { fontSize: 14, textAlign: "center" } },
            {
                name: 'תאריך יצירה', sortable: true, selector: row => row.createdAt, center: true, style: { fontSize: 14, textAlign: "center" },
                cell: row => <label></label>
            }
        ],
        emptyColumnsData: [{ uniqueId: "", _id: '', name: "", council: "", userFullName: "", userCompanyName: "", actions: '', userEmail: "", userPhone: "", user: { fullName: "", companyName: "", email: "", phone: "" } }],
    }

    componentDidMount() {
        //Change Text OF "Rows per page: " by it's class
        let rowsPerPageText = document.getElementsByClassName("bnNgVs");
        if (rowsPerPageText && rowsPerPageText.length > 0) {
            rowsPerPageText[0].innerHTML = "שורות בכל דף"
        }
    }

    render() {
        return (
            (!this.props.data || this.props.data.length === 0) ?
                <div className="empty-container custom-box-shadow">

                    <DataTable
                        columns={this.state.emptyColumns}
                        data={this.state.emptyColumnsData}
                        pagination={true}
                    />

                    <div className="text-center empty-container-inner">
                        <h3>לא נמצאו גורמי חוץ</h3>
                    </div>
                </div>
                :
                <DataTable
                    keyField='uniqueId'
                    columns={this.state.columns}
                    data={this.props.data}
                    pagination={true}
                />
        );
    }
}