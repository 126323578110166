import React, {Component} from 'react';
import { IsNeedAPlan } from '../config/GeneralMethods';
import { SCREEN_NAMES } from '../constants';

class Contact extends Component {

    componentDidMount(){
        if(IsNeedAPlan()){
            window.location.href = SCREEN_NAMES.PRICING_SCREEN;
        }
    }

    render(){
        return (
            <div className="container contact-main-container">
                <div className="row justify-content-center">
                    <div className="col-lg-9 col-xl-9 col-md-12">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row justify-content-center">
                                    <div className="col-12">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h1 className="text-gray-900 mb-4">יצירת קשר </h1>
                                            </div>
                                           
                                            <div className="d-flex justify-content-between align-items-center contact-container">
                                                <div className="contact-img-container">
                                                    <img src={require('../assets/images/c1.jpg')} alt="" className="contact-img" />
                                                </div>
                                                <div className="text-center">
                                                    <h4>נשמח לשמוע מכם</h4>

                                                    <div className="mt-3">
                                                        <span>
                                                            משפחת בלירוקרטיה כאן בכל עניין ולכל שאלה
                                                        </span>
                                                        <br />
                                                        <span>
                                                            ביום עסקים רגיל, בין השעות 08:30- 18:00
                                                        </span>
                                                    </div>

                                                    <h5 className="mt-4">
                                                        תמיכה
                                                    </h5>
                                                    <span className="mt-3">
                                                        info@blirokratia.com | 051-291-4545
                                                    </span>

                                                    
                                                    <h5 className="mt-4">
                                                        מכירות
                                                    </h5>
                                                    <span className="mt-3">
                                                        sales@blirokratia.com | 051-291-4545
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;