import React, { Component } from 'react';

import "../assets/styles/sb-admin-2.min.css";
import Loading from '../components/Loading';
import { ENV } from '../config';
import { HttpRequest, HttpRequestTypes } from '../config/GeneralMethods';
import { Colors, SCREEN_NAMES, validateEmail } from '../constants';

class ForgetPassword extends Component {

    state = {
        email: '',
        successMessage: '',
        errorMessage: '',
        isLoading: false
    };

    submit = async () => {
        let userInfo = {
            email: this.state.email
        };

        this.setState({ successMessage: '', errorMessage: '' });

        if(userInfo.email.length === 0) {
            this.setState({ errorMessage: 'חובה להזין דוא"ל **' });
        } else if(!validateEmail(userInfo.email)){
            this.setState({ errorMessage: 'נא להזין דוא"ל תקין' });
        } else {

            this.setState({ isLoading: true })

            let url = `${ENV.API_URL}/${ENV.USERS_API_URL}/forget-password`;
            let res = await HttpRequest(url, userInfo, HttpRequestTypes.POST);

            if(res) {
                if(res.success){
                    this.setState({ successMessage: res.message });
                } else {
                    this.setState({ errorMessage: res.message });
                }
            } else {
                this.setState({ errorMessage: 'אירעה שגיאה, נא לנסות שוב בעוד כמה דקות' })
            }

            this.setState({ isLoading: false })
        }
    }

    render(){
        return (
            
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-xl-6 col-md-9">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row justify-content-center">
                                    <div className="col-12">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <img src="https://i.ibb.co/8NNd8st/logoPNG.png" alt="" style={{width: 100}} />
                                                <h1 className="h4 text-gray-900 mb-4">שחזור סיסמא </h1>
                                            </div>
                                            <form className="user">
                                                <div className="form-group">
                                                    <input type="text" name="email" className="form-control form-control-user" style={{textAlign: 'right'}} id="email_in" aria-describedby="emailHelp" placeholder="דואר אלקטרוני  "
                                                        onChange={(e) => this.setState({email: e.target.value})} value={this.state.email} />
                                                </div>

                                                {
                                                    this.state.isLoading ? 
                                                    <Loading />
                                                    : <input type="button" style={{backgroundColor: Colors.PRIMARY_YELLOW}} className="btn btn-user btn-custom btn-block" value="שחזור סיסמא" onClick={this.submit} />
                                                }
                                                
                                                <div className="mt-3 text-right mr-2" style={{direction: 'rtl'}}><span className="text-success">{this.state.successMessage}</span></div>
                                                <div className="mt-3 text-right mr-2" style={{direction: 'rtl'}}><span className="text-danger">{this.state.errorMessage}</span></div>
                                            </form>
                                            <hr />
                                            <div className="text-center">
                                                <a className="meduim" href={SCREEN_NAMES.LOGIN_SCREEN}>חזור להתחברות</a>
                                            </div>
                                            <div className="text-center">
                                                <a className="meduim" href={SCREEN_NAMES.REGISTER_SCREEN}>משתמש חדש? הירשם עכשיו</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ForgetPassword;