import React, { Component, createRef } from 'react';
import AdminUser from '../AdminUsers';
//import AdminDocuments from './AdminDocuments';
import AdminExternalOrgs from './AdminExternalOrgs';
import AdminOrganizations from './AdminOrganizations';
import FilesMap from './FilesMap';
import "./style.css"
import AdminDocumentsV2 from './AdminDocumentsV2';

const TABS_IDS = {
    ORGANIZATIONS: "organizations",
    DOCUMENTS: "documents",
    PEOPLE: "people",
    FILES_MAP: "filesMap",
    EXTERNAL_ORGS: "externalOrgs"
}

class AdminCompany extends Component {

    state = {
        activeTab: TABS_IDS.FILES_MAP,
        selectedCompany: null
    }

    constructor(){
        super();
        this.fileMapRef = createRef();
        this.organizationsRef = createRef();
    }

    componentDidMount() {
        let tab = new URLSearchParams(window.location.search).get("tab");

        if (tab && Object.values(TABS_IDS).includes(tab)) {
            this.setState({ activeTab: tab });
        }
    }

    openCompany = (company) => {
        this.setState({ activeTab: TABS_IDS.PEOPLE , selectedCompany: company})
    }

    loadFileMap = () => {
        this.setState({ activeTab: TABS_IDS.FILES_MAP })
        this.fileMapRef.current.loadScreen();
    }

    peopleManagementGoBackPressed = () => {
        this.setState({ activeTab: TABS_IDS.ORGANIZATIONS })
        
        try {
            this.organizationsRef.current.loadCompanies();
        } catch (e) {console.log(e)}
    }

    updateActiveTab = (activeTab) => {
        
        //this.setState({ activeTab });
        window.location.href = "/admin?tab=" + activeTab;
    }

    render() {
        return (
            <div className="admin-template-container">

                <div className="container">

                    <div className="d-flex justify-content-between align-items-center my-5">
                        
                        <div className="text-right">
                        </div>
                        
                        <div className="text-center">
                            {/* <button className={"btn btn-tab mx-2" + (this.state.activeTab === TABS_IDS.PEOPLE ? " active" : "")} onClick={() => this.setState({ activeTab: TABS_IDS.PEOPLE })}>ניהול בעלי עניין</button> */}
                            <button className={"btn btn-tab mx-2" + (this.state.activeTab === TABS_IDS.EXTERNAL_ORGS ? " active" : "")} onClick={() => this.updateActiveTab(TABS_IDS.EXTERNAL_ORGS)}>ניהול גורמי חוץ</button>
                            <button className={"btn btn-tab mx-2" + (this.state.activeTab === TABS_IDS.FILES_MAP ? " active" : "")} onClick={() => this.loadFileMap()}>מפת המסמכים</button>
                            <button className={"btn btn-tab mx-2" + (this.state.activeTab === TABS_IDS.DOCUMENTS ? " active" : "")} onClick={() => this.updateActiveTab(TABS_IDS.DOCUMENTS)}>ניהול מסמכים</button>
                            <button className={"btn btn-tab mx-2" + (this.state.activeTab === TABS_IDS.ORGANIZATIONS ? " active" : "")} onClick={() => this.updateActiveTab(TABS_IDS.ORGANIZATIONS)}>ניהול ארגונים</button>
                        </div>
                    </div>

                    <div className={"tab-section" + (this.state.activeTab === TABS_IDS.ORGANIZATIONS ? " active" : "")} id={TABS_IDS.ORGANIZATIONS}>
                        <AdminOrganizations ref={this.organizationsRef} openCompany={this.openCompany.bind(this)} />
                    </div>

                    <div className={"tab-section" + (this.state.activeTab === TABS_IDS.DOCUMENTS ? " active" : "")} id={TABS_IDS.DOCUMENTS}>
                        {/* <AdminDocuments /> */}
                        <AdminDocumentsV2 />
                    </div>

                    <div className={"tab-section" + (this.state.activeTab === TABS_IDS.PEOPLE ? " active" : "")} id={TABS_IDS.PEOPLE}>
                        <AdminUser selectedCompany={this.state.selectedCompany} goBack={() => this.peopleManagementGoBackPressed()} />
                    </div>

                    <div className={"tab-section" + (this.state.activeTab === TABS_IDS.FILES_MAP ? " active" : "")} id={TABS_IDS.FILES_MAP}>
                        <FilesMap ref={this.fileMapRef} />
                    </div>

                    <div className={"tab-section" + (this.state.activeTab === TABS_IDS.EXTERNAL_ORGS ? " active" : "")} id={TABS_IDS.EXTERNAL_ORGS}>
                        <AdminExternalOrgs />
                    </div>

                </div>

            </div>
        );
    }
}

export default AdminCompany;