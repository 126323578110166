import React from 'react';
import MainRouter from './components/MainRouter';

import "./assets/styles/app.css";

function App() {
  return (
    <MainRouter />
  );
}

export default App;
