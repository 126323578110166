import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { ENV } from "../config";
import {
  GetLoggedInUserInfo,
  GetLoggedInUserToken,
  HttpRequest,
  HttpRequestTypes,
  IsNeedAPlan,
  UploadImage,
} from "../config/GeneralMethods";
import { APP_OPTIONS, SCREEN_NAMES } from "../constants";
import Loader from "react-loader-spinner";
import "../assets/styles/documents.css";


class DragDrop extends Component {
  
  state = {
    isLoggedIn: false,
    files: [],
    loader:false
  };
  

  componentDidMount() {
    
    let isLoggedIn = false;
    let userInfo = GetLoggedInUserInfo();

    if (userInfo) {
      isLoggedIn = true;

      if (IsNeedAPlan()) {
        window.location.href = SCREEN_NAMES.PRICING_SCREEN;
      }
    }

    this.setState({ isLoggedIn });
  }

  onDrop = (files) => {
    try{
      this.setState({ files, loader: true });
      this.uploadFileToServer(files[0]);
    } catch (e) {
      console.error(e);
      this.setState({ loader: false });
    }
  };

  uploadFileToServer = async (file) => {
    
    let { isLoggedIn } = this.state;
    
    if (!isLoggedIn) return;
    let user = GetLoggedInUserInfo();

    let remainigDocs = await this.getRemainingDocsByUserId();

    if (remainigDocs > 0) {
      

      let url = `${ENV.API_URL}/${ENV.DOCUMENTS_API_URL}/add-file`;

      let formData = new FormData();

      let title = `file_${new Date().getTime()}.pdf`;

      let bodyObj = {
        app: APP_OPTIONS.ESIGN,
        docId: null,
        tokenId: null,
        userId: user.user._id,
        tmc: false,
        title
      }

      formData.append("data", file, title);
      formData.append("userInfo", JSON.stringify(bodyObj));


      let res = await UploadImage(url, formData);

      if (res.success) {
        //Should Open the file
        let {docId, tokenId} = res;
        bodyObj["docId"] = docId;
        bodyObj["tokenId"] = tokenId;

        //Generate Token and Open
        let token = await this.makeTokenFromJson(bodyObj);
        let url = `${ENV.ELECTRONIC_SIGNATURE_URL}?t=${token}`;
        window.location.href=url;
      } else {
        //Show the error message
        let {message} = res;
        console.error(message);
      }

    } else {
      //No Enough balance ===> Go Purhcase
    }


    this.setState({ loader: false });
  }

  makeTokenFromJson = async (bodyObj) => {
    try{

      let url = `${ENV.API_URL}/${ENV.USERS_API_URL}/makeTokenFromJson`;
      let res = await HttpRequest(url, bodyObj, HttpRequestTypes.POST, GetLoggedInUserToken());

      if (res) {
        return res.token;
      }

    } catch (e) {
      console.error(e);
    }

    return null;
  }

  getRemainingDocsByUserId = async () => {
    let remainigDocs = 0;
    try {
      let url = `${ENV.API_URL}/${ENV.USERS_API_URL}/getUserAdditionalSettings`;
      let res = await HttpRequest(
        url,
        null,
        HttpRequestTypes.GET,
        GetLoggedInUserToken()
      );

      if (res && res.success) {
        let additionalInfo = res.data;
        if (additionalInfo && additionalInfo.remainigDocs) {
          remainigDocs = additionalInfo.remainigDocs;
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      return remainigDocs;
    }
  };

  render() {
   
    const dropzoneheaderstyle = {
      backgroundImage: 'url(https://docucdn-a.akamaihd.net/olive/images/2.33.0/ndse/home-hero-bg.svg)',
      content: "",
      display: 'block',
      height: '250px',
      position: 'absolute',
      width: '100%',
      zIndex: -1
    }

    const centerAlignItems = {
      textAlign: 'center',
      "&:hover": {
        background: "#efefef"
      }
    }

    const files = this.state.files.map(file => (
      <li key={file.name}>
        {file.name} - {file.size} bytes
      </li>
    ));

    return (
      <>
      
        <div style={dropzoneheaderstyle}>
          <h2 className="headingStyle" >העלאת קובץ חדש</h2>
        </div>
        <div className="dropzoneelement">
          <Dropzone onDrop={this.onDrop} multiple={false}>
            {({ getRootProps, getInputProps }) => (
              <section className="container">
                <div {...getRootProps({ className: 'dropzone' })} style={centerAlignItems}>
                  <input {...getInputProps()} />
                  <h2 style={{cursor: 'pointer'}}>הקש או גרור קובץ לכאן כדי להתחיל</h2>
                </div>
                <aside style={centerAlignItems}>
                  
                  {files && files.length > 0 && (
                    <>
                      <h4>קבצים הועלאו</h4>
                      <ul>{files}</ul>
                    </>
                  )}
                </aside>
              </section>
            )}
          </Dropzone>
          {this.state.loader ? <Loader type="TailSpin" color="black" height={50} width={50} />: ""}
        </div>
      </>
    );
  }
}
export default DragDrop;
