import React, { Component } from 'react';
import AdminCompany from '../components/AdminCompany';
import AdminUser from '../components/AdminUsers';
import NotFound from '../screens/NotFound';
import { GetLoggedInUserInfo } from '../config/GeneralMethods';
import { USERS_ROLES } from '../constants';

export default class Admin extends Component{

    state = {
        isAdmin: false,
        step: 1,
        selectedCompany: ""
    };

    componentDidMount(){
        this.loadScreen();
    }

    loadScreen = () => {
        let userInfo = GetLoggedInUserInfo();

        let allowedUserRoles = [USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ESIGN_ADMIN, USERS_ROLES.BLIROKRATIA_EMPLOYEE];

        if (allowedUserRoles.includes(userInfo.user.role)) {
            this.setState({ isAdmin: true })
        }
    }

    renderTemplate = () => {
        let {step} = this.state;

        switch(step){
            case 2:
                return (
                    <AdminUser selectedCompany={this.state.selectedCompany} goBack={() => this.setState({ step: 1 })} />
                );
            case 1:
            default:
                return (
                    <AdminCompany openCompany={this.openCompany.bind(this)} />
                );
        }
    }

    openCompany = (company) => {
        this.setState({ selectedCompany: company, step: 2 });
    }

    render(){
        return (
            this.state.isAdmin ?
            <div>
                {this.renderTemplate()}
            </div>
            : 
            <NotFound />
        );
    }
}