import React, { Component } from 'react';
import { Fragment } from 'react';
import { ENV } from '../config';
import moment from "moment";
import DataTable from 'react-data-table-component';

export default class ExpandableDocumentRow extends Component{

    state = {
        columns: [
            {name: '', sortable: false, center: true, style: {fontSize: 14},
                cell: row => <Fragment>
                <button className="icon-button" data-tooltip="הצג" onClick={() => this.viewDocument(row.docId, row.tokenId || "", row.email)}>
                    <span className="tooltiptext">הצג</span>
                    <i className="eye fa fa-eye"></i>
                </button>
                <button className="icon-button ml-2" data-tooltip="הורדה" onClick={() => this.download(row.docId, row.name)}>
                    <span className="tooltiptext">הורדה</span>
                    <i className="download fa fa-download"></i>
                </button>
            </Fragment>
            },
            {name: "סטטוס", sortable: true, selector: 'status', center: true, 
                cell: row => <img alt="" src={row.status ? require('../assets/images/blueTickIcon.png') : require('../assets/images/yellowCross.png')} style={{width: 20, height: 20}} /> 
            },
            {name: 'תאריך קבלת חתימה', sortable: true, selector: 'editedDate', center: true,
                cell: row => <h2 style={{fontSize: 14}}>{
                    row.status ? (
                        moment(row.editedDate).format('DD/MM/YYYY HH:mm:ss') === 'Invalid date' ? 
                        moment(row.editedDate * 1000).format('DD/MM/YYYY HH:mm:ss'):
                        moment(row.editedDate).format('DD/MM/YYYY HH:mm:ss')
                    ): "מסמך לא נחתם עדיין"
                }</h2>
            },
            {name: 'תאריך שליחת המסמך', sortable: true, selector: 'creationDate', center: true,
                cell: row => <h2 style={{fontSize: 14}}>{
                    moment(row.creationDate).format('DD/MM/YYYY HH:mm:ss') === 'Invalid date' ? 
                    moment(row.creationDate * 1000).format('DD/MM/YYYY HH:mm:ss'):
                    moment(row.creationDate).format('DD/MM/YYYY HH:mm:ss')
                }</h2>
            },
            {name: 'דוא"ל לקוח', sortable: true, selector: 'email', center: true, style: {fontSize: 14}}
        ],
        emptyColumns: [
            {name: 'סטטוס', sortable: true, selector: 'status', center: true},
            {name: 'תאריך קבלת חתימה', sortable: true, selector: 'editedDate', center: true},
            {name: 'תאריך שליחת המסמך', sortable: true, selector: 'creationDate', center: true},
            {name: 'דוא"ל לקוח', sortable: true, selector: 'email', center: true, style: {fontSize: 14}},
        ],
        emptyColumnsData: [{status: '', creationDate: '', email: '', editedDate: ""}],
    }

    viewDocument = (id, tokenId, email) => {
        this.props.viewDocument(id, tokenId, email);
    }

    download = async (id, name) => {
        let url = `${ENV.API_URL}/${ENV.UPLOADS_API_URL}/download/${name}`;
        window.location.href = url;
    }

    render(){

        let childrenList = this.props.data.documentsList;

        return (
            <div className="empty-container custom-box-shadow" style={{border: "1px solid #a59c9c"}}>
                {(!childrenList || childrenList.length === 0) ? 
                    <Fragment>
                        <DataTable
                            columns={this.state.emptyColumns}
                            data={this.state.emptyColumnsData}
                            pagination={true}
                        />

                        <div className="text-center empty-container-inner">
                            <h3>לא נמצאו אנשים שקיבלו את המסמך</h3>
                        </div>
                    </Fragment>
                    :
                    <DataTable
                        columns={this.state.columns}
                        data={childrenList}
                        pagination={true}
                    />
                }
            </div>
        )
    }
}