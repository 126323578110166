import React, {Component} from 'react';
import Loading from '../components/Loading';
import { ENV } from '../config';
import {GetLoggedInUserInfo, GetLoggedInUserToken, HttpRequest, HttpRequestTypes, IsNeedAPlan, UploadImage} from '../config/GeneralMethods'
import { Colors, CONSTANT_KEYS, SCREEN_NAMES } from '../constants';
import moment from "moment";

class Settings extends Component {

    state = {
        isLoading: false,
        firstName: '',
        lastName: '',
        address: '',
        successMessage: '',
        errorMessage: '',
        signatureUrl: '',
        stampUrl: '',
        signatureFile: null,
        stampFile: null,
        planTitle: "",
        planExpiry: new Date().getTime(),
        remainigDocs: 0
    }

    componentDidMount(){

        if(IsNeedAPlan()){
            window.location.href = SCREEN_NAMES.PRICING_SCREEN;
        } else {
            this.setState({isLoading: true});
            let userInfo = GetLoggedInUserInfo();
    
            if(userInfo){
                let {userSettings} = userInfo.user;
    
                if(userSettings){
                    let {firstName, lastName, address, signatureUrl, stampUrl} = userSettings || "";
                    this.setState({firstName, lastName, address, signatureUrl, stampUrl});
                }
            }

            this.loadAdditionalInformationFromAPI();
        }
    }

    loadAdditionalInformationFromAPI = async () => {
        try{

            let url = `${ENV.API_URL}/${ENV.USERS_API_URL}/getUserAdditionalSettings`;
            let res = await HttpRequest(url, null, HttpRequestTypes.GET, GetLoggedInUserToken());

            if(res && res.success){
                let additionalInfo = res.data;
                let {plan, planExpiry, remainigDocs} = additionalInfo || {};
                this.setState({ planTitle: plan, planExpiry, remainigDocs })
            }

        }catch(e){
            console.log(e);
        } finally {
            this.setState({isLoading: false});
        }
    }

    submit = async () => {

        this.setState({isLoading: true, successMessage: '', errorMessage: ''});

        let {firstName, lastName, address, signatureUrl, stampUrl, stampFile, signatureFile} = this.state;

        //if signature has new image upload it and get the url
        if(signatureFile){
            const formData = new FormData(); 
     
            // Update the formData object 
            formData.append('data', signatureFile); 

            let uploadUrl = `${ENV.API_URL}/${ENV.UPLOADS_API_URL}`;
            let uploadRes = await UploadImage(uploadUrl, formData);

            if(uploadRes && uploadRes.success){
                signatureUrl = uploadRes.url;
            }
        }

        //if stamp has new image upload it and get the url
        if(stampFile){
            const formData = new FormData(); 
     
            // Update the formData object 
            formData.append('data', stampFile); 

            let uploadUrl = `${ENV.API_URL}/${ENV.UPLOADS_API_URL}`;
            let uploadRes = await UploadImage(uploadUrl, formData);

            if(uploadRes && uploadRes.success){
                stampUrl = uploadRes.url;
            }
        }

        let userSettings = {
            firstName,
            lastName,
            address,
            signatureUrl,
            stampUrl
        };

        let url = `${ENV.API_URL}/${ENV.USERS_API_URL}/edit-settings`;
        let res = await HttpRequest(url, {userSettings}, HttpRequestTypes.PATCH, GetLoggedInUserToken());

        if(res){
            if(res.success){
                this.setState({ successMessage: res.message });

                let userInfoObj = GetLoggedInUserInfo();
                let userInfo = userInfoObj.user;
                userInfo.userSettings = userSettings;
                userInfoObj.user = userInfo;
                
                localStorage.setItem(CONSTANT_KEYS.USER_INFO, JSON.stringify(userInfoObj));

            } else {

                let msg = res.message;

                if(typeof(msg) === "object"){
                    msg = JSON.stringify(msg);

                    if(msg === '{}'){
                        msg = 'אירעה שגיאה, נא לנסות שוב בעוד כמה דקות';
                    }
                }

                this.setState({ errorMessage: msg })
            }
        } else {
            this.setState({ errorMessage: 'אירעה שגיאה, נא לנסות שוב בעוד כמה דקות' })
        }


        this.setState({isLoading: false});
    }

    uploadSignature = (e) => {

        try{
            this.setState({signatureFile: e.target.files[0]});

            let reader = new FileReader();
            reader.onload = () => {
                this.setState({
                    signatureUrl: reader.result || ""
                });
            }

            reader.readAsDataURL(e.target.files[0]);
        } catch(e){

        }
    }

    uploadStamp = (e) => {

        try{
            this.setState({stampFile: e.target.files[0]});

            let reader = new FileReader();
            reader.onload = () => {
                this.setState({
                    stampUrl: reader.result || ""
                });
            }

            reader.readAsDataURL(e.target.files[0]);
        } catch(e){
            
        }
    }

    render(){
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-7 col-xl-7 col-md-9">
                        <div className="card o-hidden border-0 shadow-lg my-5">
                            <div className="card-body p-0">
                                <div className="row justify-content-center">
                                    <div className="col-12">
                                        <div className="settings-padding">
                                            <div className="text-center">
                                                <h1 className="h4 text-gray-900 mb-4">הגדרות </h1>
                                            </div>
                                            {
                                                this.state.isLoading ? 
                                                null:
                                                <div style={{marginBottom: 10}}>
                                                    <div style={{textAlign: "right"}}>
                                                        <label style={{marginRight: 10}}>חבילה נוכחית: <span style={{fontWeight: "bold"}}>{this.state.planTitle}</span></label>
                                                    </div>
                                                    <div style={{textAlign: "right"}}>
                                                        <label style={{marginRight: 10}}>חבילה תפוג בתאריך: <span style={{fontWeight: "bold"}}>{moment(this.state.planExpiry).format("DD/MM/yyyy")}</span></label>
                                                    </div>
                                                    <div style={{textAlign: "right"}}>
                                                        <label style={{marginRight: 10}}>מסמכים נותרו: <span style={{fontWeight: "bold"}}>{this.state.remainigDocs}</span></label>
                                                    </div>

                                                    <hr />
                                                </div>
                                            }
                                            <form className="user">
                                                <div className="form-group" style={{textAlign: "right"}}>
                                                    <label style={{marginRight: 10}}>שם פרטי</label>
                                                    <input type="text" name="firstName" className="form-control form-control-user" style={{textAlign: 'right'}} placeholder="שם פרטי  "
                                                        onChange={(e) => this.setState({firstName: e.target.value})} value={this.state.firstName} />
                                                </div>
                                                <div className="form-group" style={{textAlign: "right"}}>
                                                    <label style={{marginRight: 10}}>שם משפחה</label>
                                                    <input type="text" name="lastName" className="form-control form-control-user" style={{textAlign: 'right'}} placeholder="שם משפחה  "
                                                        onChange={(e) => this.setState({lastName: e.target.value})} value={this.state.lastName} />
                                                </div>
                                                <div className="form-group" style={{textAlign: "right"}}>
                                                    <label style={{marginRight: 10}}>כתובת</label>
                                                    <input type="text" name="address" className="form-control form-control-user" style={{textAlign: 'right'}} placeholder="כתובת  "
                                                        onChange={(e) => this.setState({address: e.target.value})} value={this.state.address} />
                                                </div>
                                                <div className="form-group text-right">
                                                    <label className="mr-3">חתימה</label>
                                                    
                                                    <div className="text-center">
                                                        <input type="file" name="signature" className="" style={{visibility: 'hidden'}} id="signature" onChange={this.uploadSignature} />

                                                        <div className="img-settings-container">
                                                            <img src={this.state.signatureUrl.length === 0 ? require('../assets/images/no-image-found-360x250.png') : this.state.signatureUrl}  alt="" />
                                                        </div>
                                                        
                                                        <div className="mt-3">
                                                            <label htmlFor="signature" className="prim-btn shadow btn-upload w-50 pointer">העלאת תמונה</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="form-group text-right">
                                                    <label className="mr-3">חותמת דיגטלית</label>
                                                    
                                                    <div className="text-center">
                                                        <input type="file" name="stamp" className="" style={{visibility: 'hidden'}} id="stamp" onChange={this.uploadStamp} />

                                                        <div className="img-settings-container">
                                                            <img src={this.state.stampUrl.length === 0 ? require('../assets/images/no-image-found-360x250.png') : this.state.stampUrl} alt="" />
                                                        </div>

                                                        <div className="mt-3">
                                                            <label htmlFor="stamp" className="prim-btn shadow btn-upload w-50 pointer">העלאת תמונה</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    this.state.isLoading ? 
                                                    <Loading />
                                                    : <input type="button" style={{backgroundColor: Colors.PRIMARY_YELLOW}} className="btn btn-user btn-custom btn-block" value="שמירת שינויים" onClick={this.submit} />
                                                }
                                                
                                                <div className="mt-3 text-right mr-2"><span className="text-success">{this.state.successMessage}</span></div>
                                                <div className="mt-3 text-right mr-2"><span className="text-danger">{this.state.errorMessage}</span></div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Settings;