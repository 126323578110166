import React, {Component} from 'react';
import ContactListDataTable from '../components/ContactListDataTable';
import Loading from '../components/Loading';
import Modal from 'react-modal';
import { Colors, SCREEN_NAMES, validateEmail } from '../constants';
import { Fragment } from 'react';
import {GetLoggedInUserToken, HttpRequest, HttpRequestTypes, IsNeedAPlan} from '../config/GeneralMethods';
import { ENV } from '../config';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      minWidth                 : 500,
      maxWidth: '45vw'
    }
};

class ContactsList extends Component {

    state = {
        contacts: [],
        isLoading: true,
        contactItem: {
            _id: "",
            emails: [""],
            title: ""
        },
        isModalVisible: false,
        successMessage: "",
        errorMessage: "",
        isSavingContact: false
    };

    componentDidMount(){

        if(IsNeedAPlan()){
            window.location.href = SCREEN_NAMES.PRICING_SCREEN;
        }else {
            this.loadContacts();
        }
    }

    loadContacts = async () => {
        let contacts = [];

        let url = `${ENV.API_URL}/${ENV.CONTACT_LIST_URL}`;
        let res = await HttpRequest(url, null, HttpRequestTypes.GET, GetLoggedInUserToken());

        if(res && res.success){
            let data = res.data || [];

            if(data.length > 0){
                for(let i = 0; i < data.length; i++){
                    contacts.push({
                        _id: data[i]._id,
                        title: data[i].title,
                        userId: data[i].userId,
                        emails: data[i].emails?.split(",")
                    });
                }
            }
        }

        this.setState({ contacts, isLoading: false });
    }

    addNewContactItem = () => {
        this.resetSelectedContactGroup();

        setTimeout(() => {
            this.setState({ isModalVisible: true })
        }, 300);
    }


    resetSelectedContactGroup = () => {
        let contactItem = {
            _id: "",
            emails: [""],
            title: ""
        };

        this.setState({ contactItem });
    }

    editContact = (id, title, emails) => {

        let {contactItem} = this.state;
        contactItem._id = id;
        contactItem.emails = emails;
        contactItem.title = title;

        this.setState({ contactItem });

        setTimeout(() => {
            this.setState({ isModalVisible: true })
        }, 300);
    }

    deleteContact = async (id) => {
        //SHOW POPUP MESSAGE
        confirmAlert({
            title: "מחיקת רשימת תפוצה",
            message: "האם אתה בטוח?",
            buttons: [
              {
                label: "לא, סגור",
                onClick: () => {},
              },
              {
                label: "כן",
                onClick: () => {
                  this.removeContactFromDB(id);
                },
              },
            ],
          });
    }

    removeContactFromDB = async (id) => {
        let url = `${ENV.API_URL}/${ENV.CONTACT_LIST_URL}/${id}`;
        let res = await HttpRequest(url, null, HttpRequestTypes.DELETE, GetLoggedInUserToken());

        if(res && res.success){
            this.loadContacts();
        }
    }

    closeModal = () => {
        this.setState({ isModalVisible: false, successMessage: "" });
    }


    updateEmailContent = (ind, email) => {
        let {contactItem} = this.state;
        contactItem.emails[ind] = email;
        this.setState({ contactItem });
    }

    submit = async () => {
        let {contactItem, contacts} = this.state;
        let {emails} = contactItem;
        this.setState({ successMessage: "", errorMessage: "", isSavingContact: true });

        let fixedEmails = [];

        for(let i = 0; i < emails.length; i++){

            if(emails[i].trim().length > 0){
                fixedEmails.push(emails[i].trim());
            }
        }

        if(contactItem.title.length === 0) {
            this.setState({ errorMessage: "חובה להזין שם רשימת התפוצה" });
        } else if(fixedEmails.length === 0){
            this.setState({ errorMessage: "חובה להזין דואר אלקטרוני" });
        }  else {

            let isAtLeastOneEmailIsNotValid = false;

            for(let i = 0; i < fixedEmails.length; i++){
                if(!validateEmail(fixedEmails[i])) {
                    isAtLeastOneEmailIsNotValid = true;
                    this.setState({ errorMessage: `תיבת הטקסט מספר ${i + 1} מכילה דוא"ל לא חוקי` });        
                    break;
                }
            }

            if(!isAtLeastOneEmailIsNotValid){
                let isAdd = contactItem._id.length === 0;
                let successMessage = isAdd ? "איש הקשר התווסף בהצלחה" : "פרטי איש הקשר נערכו בהצלחה";

                if(isAdd){
                    let url = `${ENV.API_URL}/${ENV.CONTACT_LIST_URL}`;
                    let params = {
                        title: contactItem.title,
                        emails: contactItem.emails.join(",")
                    };

                    let res = await HttpRequest(url, params, HttpRequestTypes.POST, GetLoggedInUserToken());

                    if(res && res.success){
                        this.loadContacts();
                    }
                } else {
                    let index = this.getIndexOfUserById(contactItem._id);

                    if(index !== -1){
                        let url = `${ENV.API_URL}/${ENV.CONTACT_LIST_URL}/edit`;
                        let params = {
                            title: contactItem.title,
                            emails: contactItem.emails.join(","),
                            _id: contactItem._id
                        };  

                        let res = await HttpRequest(url, params, HttpRequestTypes.POST, GetLoggedInUserToken());

                        if(res && res.success){
                            this.loadContacts();
                        }
                    }
                }

                this.setState({ successMessage, contacts });

                setTimeout(() => {
                    this.closeModal();
                }, 1200);
            }
        }

        this.setState({ isSavingContact: false });
    }


    getIndexOfUserById = (id) => {
        let {contacts} = this.state;

        if(!contacts || contacts.length === 0){
            return -1;
        }

        for(let i = 0; i < contacts.length; i++){
            if(contacts[i]._id === id){
                return i;
            }
        }

        return -1;
    }

    updateTitle = (title) => {
        let {contactItem} = this.state;
        contactItem.title = title;
        this.setState({ title });
    }

    renderModalContent = () => {
        return (
            <Fragment>

                <label style={{color: 'black', marginLeft: 10}}>
                    שם הרשימה: 
                </label>

                <input type="text" name="name" className="form-control form-control-user" style={{textAlign: 'right'}} aria-describedby="emailHelp" placeholder="שם רשימת התפוצה  "
                    onChange={(e) => this.updateTitle(e.target.value)} value={this.state.contactItem.title} />   

                <label style={{color: 'black', marginLeft: 10}}>
                    דוא"ל: 
                </label>

                <div className="form-group">
                    {
                        this.state.contactItem.emails.map( (val, ind) => 
                            
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginBottom: 10}}>
                                <input key={ind} type="text" name="email" className="form-control form-control-user" style={{textAlign: 'right'}} id={"email_in_" + ind} aria-describedby="emailHelp" placeholder="דואר אלקטרוני  "
                                    onChange={(e) => this.updateEmailContent(ind, e.target.value)} value={val} />   
                                    {ind === (this.state.contactItem.emails.length - 1) ? 
                                    <div style={{marginRight: 8, backgroundColor: Colors.PRIMARY_YELLOW, width: 30, height: 30, borderRadius: 15, textAlign: 'center', cursor: "pointer"}} onClick={this.addEmailTextBox}>
                                        <i className="fa fa-plus" style={{color: "white", fontSize: 14, textAlign: "center", lineHeight: 2.1}}></i>
                                    </div>
                                    :  <div style={{marginRight: 8, backgroundColor: "red", width: 30, height: 30, borderRadius: 15, textAlign: 'center', cursor: "pointer"}} onClick={() => this.removeEmailTextBox(ind)}>
                                            <i className="fa fa-times" style={{color: "white", fontSize: 14, textAlign: "center", lineHeight: 2.1}}></i>
                                        </div>
                                    }
                                
                            </div>
                        )
                    }
                </div>

                {
                    this.state.isSavingContact ? 
                    <div>
                        <Loading />
                    </div>
                    :
                    <div className="text-center w-50 btn-send-email">
                        <input type="button" className="btn btn-user btn-custom btn-block" style={{backgroundColor: Colors.PRIMARY_YELLOW}} value={this.state.contactItem._id.length === 0 ? "צור קבוצה" : "שמירת שינויים"} onClick={this.submit} />
                    </div>
                }
                
                <div className="mt-3 text-right mr-2"><span className="text-success">{this.state.successMessage}</span></div>
                <div className="mt-3 text-right mr-2"><span className="text-danger">{this.state.errorMessage}</span></div>
            </Fragment>
        );
    }

    addEmailTextBox = () => {
        let {emails} = this.state.contactItem;
        emails.push('');
        this.setState({ emails });
    }

    removeEmailTextBox = (ind) => {
        let {emails} = this.state.contactItem;

        if(ind < emails.length){
            emails.splice(ind, 1)
            this.setState({ emails });
        }
    }

    render(){
        return (
            <div className="container">

                <div className="mt-3 d-flex justify-content-between align-items-center home-container">

                    <div className="">
                        <button className="btn prim-btn no-animation custom-box-shadow" style={{}}
                            onClick={this.addNewContactItem}>
                            הוספת רשימת תפוצה חדשה
                        </button>
                    </div>

                    <div className="">
                        <h4 style={{fontWeight: 'bold'}}>רשומות התפוצה שלי</h4>
                    </div>
                </div>

                <div className="mt-3 mb-3">
                    {
                        this.state.isLoading ? 
                        <Loading />
                        : <ContactListDataTable data={this.state.contacts}
                            editContact={this.editContact.bind(this)}
                            deleteContact={this.deleteContact.bind(this)} />
                    }
                </div>

                <Modal
                    ariaHideApp={false}
                    isOpen={this.state.isModalVisible}
                    style={customStyles}>

                        <div>
                            <div className="d-flex justify-content-between align-items-center">
                                <div></div>
                                <div>
                                    <label className="labelModal">
                                        {this.state.contactItem._id.length === 0 ? "הוספת רשימת תפוצה חדשה" : "עריכת רשימת תפוצה"}
                                    </label>
                                </div>
                                <div>
                                    <i className="fa fa-times pointer" onClick={this.closeModal}></i>
                                </div>
                            </div>

                            <div style={{direction: 'rtl', marginTop: 20, textAlign: 'right'}}>
                                {this.renderModalContent()}
                            </div>
                        </div>
                    </Modal>
            </div>
        );
    }
}

export default ContactsList;