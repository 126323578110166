export const MenuBarItemsIds = {
    DASHBOARD: 'menu-item-dashboard',
    USERS: 'menu-item-users',
    CUSTOMERS: 'menu-item-customers',
    CATEGORIES: 'menu-item-categories',
    ORDERS: 'menu-item-orders',
    REPORTS: 'menu-item-reports',
    DEALS: 'menu-item-deals',
    SETTINGS: 'menu-item-settings'
};

export const CONSTANT_KEYS = {
    USER_INFO: 'USER_INFO',
    USER_TOKEN: 'USER_TOKEN',
    ADMIN_ACCESS: "ADMIN_ACCESS"
}; 

export const SCREEN_NAMES = {
    HOME_SCREEN: '/documents',
    LOGIN_SCREEN: '/sign-in',
    LOGOUT_SCREEN: '/logout',
    FORGET_PASSWORD_SCREEN: '/forget-password',
    CONTACT_SCREEN: '/contact',
    SETTINGS_SCREEN: '/settings',
    REGISTER_SCREEN: '/register',
    PSPDFKITDOCUMENTS_SCREEN: '/document',
    NEW_PASSWORD_SCREEN: '/new-password',
    PROFILE_SCREEN: '/profile',
    ADMIN_SCREEN: '/admin',
    CONTACTS_LIST_SCREEN: '/contacts-list',
    PRICING_SCREEN: '/pricing',
    PAYMENT_SUCCESS_SCREEN: '/success',
    PAYMENT_CANCEL_SCREEN: '/cancel',
    DROPZONE: '/',
};


export const APP_OPTIONS = {
    TOFES4: "TOFES4",
    ESIGN: "ESIGN"
}

export const USERS_ROLES = {
    SUPER_ADMIN: 1,
    FREELANCER: 2,
    TOFES4_USER: 3,
    ESIGN_ADMIN: 4,
    ESIGN_USER: 5,
    BLIROKRATIA_EMPLOYEE: 6,
    TOFES4_ORGANIZATION: 7
}

export const LOGIN_METHODS = {
    EMAIL_PASSWORD: "EMAIL_PASSWORD",
    EMAIL_OTP: "EMAIL_OTP",
    FACEBOOK: "FACEBOOK",
    GMAIL: "GMAIL"
};

export const RemoveAllActiveFromMenuItems = () => {
    const menuItemsIds = Object.values(MenuBarItemsIds);

    if(menuItemsIds && menuItemsIds.length > 0){
        for(let i = 0; i < menuItemsIds.length; i++){

            const currItem = document.querySelector("li#" + menuItemsIds[i] + " a");
           
            if(currItem && currItem.classList.contains("mm-active")){
                currItem.classList.remove("mm-active");
            }
        }
    }
}

export const SetActiveMenuItem = (id) => {

    const currentMenuItem = document.querySelector("li#" + id + " a");

    if(currentMenuItem && !currentMenuItem.classList.contains("mm-active")){
        currentMenuItem.classList.add("mm-active");
    }
}

export const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
    return re.test(String(email).toLowerCase());
}

export const validatePhone = (phone) => {
    let validPhone = "";

    for(let i = 0; i < phone.length; i++){
        if(phone.charAt(i) >= '0' && phone.charAt(i) <= '9'){
            validPhone += phone.charAt(i);
        }
    }

    return validPhone.length === 10 && validPhone.substring(0, 2) === '05';
}

export const validateOfficeOrCellPhone = (phone) => {
    let validPhone = "";

    for(let i = 0; i < phone.length; i++){
        if(phone.charAt(i) >= '0' && phone.charAt(i) <= '9'){
            validPhone += phone.charAt(i);
        }
    }

    return (
        (validPhone.length === 10 && validPhone.substring(0, 2) === '05') ||
        (validPhone.length === 9 && validPhone.charAt(0) === '0')
    );
}



export const getBothTimesInSameLength = (time1, time2) => {

    if(!time1 || !time2){
        return {
            success: false
        }
    }

    let time1Str = "" + time1;
    let time2Str = "" + time2;

    if(time1Str.length !== time2Str.length){
        if(time1Str.length < time2Str.length){
            while(time1Str.length !== time2Str.length){
                time1Str = time1Str + "0";
            }
        }

        if(time2Str.length < time1Str.length){
            while(time1Str.length !== time2Str.length){
                time2Str = time2Str + "0";
            }
        }
    }

    return {
        success: true,
        time1: Number(time1Str),
        time2: Number(time2Str)
    }
}


export const Colors = {
    PRIMARY_YELLOW: "#ffc107",
    PRIMARY_BLUE: "#42a4f5"
}

export const ORGANIZATION_TYPES = [
    "ללא",
    "מחלקת רישוי",
    "מחלקת מדידות",
    "מחלקת איכות בניה",
    "מחלקת גביה",
    "מחלקת איכות סביבה",
    "מחלקת כלי אצירה ופסולת",
    "מחלקת תנועה",
    "מחלקת הטלים ואגרות",
    "מחלקת דרכים",
    "מחלקת פיקוח על בניה",
    "מחלקת בטיחות",
    "מחלקת חשמל",
    "מחלקת תקשורת ומחשוב",
    "מחלקת גזברות",
    "מחלקת תשתיות ופיתוח",
    "מחלקת לביטחון וסדר ציבורי",
    "מחלקת תברואה",
    "מחלקת שפע",
    "חברה מפתחת"
];

export const EXTERNAL_ORGS = [
    "ללא",
    "תאגיד מים",
    "חברת תקשורת",
    "חברה מפתחת",
    "חברה כלכלית",
    "חברת גז",
    "רשות מים",
    "חברת חשמל",
    "עורך דין"
];

export const EXTERNAL_APPROVALS_TITLES = [
    "מהנדס/אדריכל העיר",
    "יו״ר וועדה לתכנון ובניה",
    "מנהל אגף רישוי ופיקוח על בניה",
    "מנהל מחלקת פיקוח על הבניה"
];

export const EXTERNAL_APPROVALS = [
    "ללא",
    ...EXTERNAL_APPROVALS_TITLES
];