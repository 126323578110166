import React, { Component, Fragment } from 'react';
import DataTable from 'react-data-table-component';

import moment from 'moment';
import { ENV } from '../config';
import ExpandableDocumentRow from './ExpandableDocumentRow';
import { APP_OPTIONS, Colors } from '../constants';
import { GetLoggedInUserInfo, GetLoggedInUserToken, HttpRequest, HttpRequestTypes } from '../config/GeneralMethods';


export default class DocumentsDataTable extends Component{

    state = {
        columns: [
            {name: '', sortable: false, center: true, style: {fontSize: 14},
                cell: row => <Fragment>
                <button className="icon-button" data-tooltip="שיתוף" onClick={() => this.viewDocument(row._id, row.tokenId || "")}
                    style={{marginRight: 10}}>
                    <span className="tooltiptext">שיתוף</span>
                    <i className="eye fa fa-envelope"></i>
                </button>
                <button className="icon-button" data-tooltip="הצג" onClick={() => this.viewDocument(row._id, row.tokenId || "")}>
                    <span className="tooltiptext">הצג</span>
                    <i className="eye fa fa-eye"></i>
                </button>
                <button className="icon-button ml-2" data-tooltip="הורדה" onClick={() => this.download(row._id, row.name)}>
                    <span className="tooltiptext">הורדה</span>
                    <i className="download fa fa-download"></i>
                </button>
            </Fragment>
            },
            {name: 'תאריך עדכון אחרון', sortable: true, selector: 'editedDate', center: true,
                cell: row => <h2 style={{fontSize: 14}}>{
                    moment(row.editedDate).format('DD/MM/YYYY HH:mm:ss') === 'Invalid date' ? 
                    moment(row.editedDate * 1000).format('DD/MM/YYYY HH:mm:ss'):
                    moment(row.editedDate).format('DD/MM/YYYY HH:mm:ss')
                }</h2>
            },
            {name: 'תאריך הפקת המסמך', sortable: true, selector: 'creationDate', center: true,
                cell: row => <h2 style={{fontSize: 14}}>{
                    moment(row.creationDate).format('DD/MM/YYYY HH:mm:ss') === 'Invalid date' ? 
                    moment(row.creationDate * 1000).format('DD/MM/YYYY HH:mm:ss'):
                    moment(row.creationDate).format('DD/MM/YYYY HH:mm:ss')
                }</h2>
            },
            {
                name: "מס' אנשים שקיבלו את זה", sortable: true, selector: "documentsList", center: true,
                cell: row => <h2 style={{fontSize:  14}}>{row.documentsList.length || 0}</h2>
            },
            {name: 'הופק על ידי', sortable: true, selector: 'user', center: true, style: {fontSize: 14},
                cell: row => <h2 style={{fontSize: 14}}>{row.user.name || ""}</h2>
            },
            {name: 'שם המסמך', sortable: true, selector: 'title', center: true, style: {fontSize: 14}},
        ],
        emptyColumns: [
            {name: 'תאריך עדכון אחרון', sortable: true, selector: 'editedDate', center: true},
            {name: 'תאריך הפקת המסמך', sortable: true, selector: 'creationDate', center: true},
            {name: "מס' אנשים שקיבלו את זה", sortable: true, selector: 'documentsList', center: true},
            {name: 'הופק על ידי', sortable: true, selector: 'title', center: true, style: {fontSize: 14}},
            {name: 'שם המסמך', sortable: true, selector: 'title', center: true, style: {fontSize: 14}},
        ],
        emptyColumnsData: [{name: '', creationDate: '', documentsList: "", editedDate: ""}],
    }

    componentDidMount(){

        //Change Text OF "Rows per page: " by it's class
        let rowsPerPageText = document.getElementsByClassName("gckGBs");
        if(rowsPerPageText && rowsPerPageText.length > 0){
            rowsPerPageText[0].innerHTML = "שורות בכל דף"
        }
    }

    viewDocument = async (id, tokenId, email = null) => {

        console.log("HereYES")

        let user = GetLoggedInUserInfo();

        let bodyObj = {
            tokenId,
            docId: id,
            app: APP_OPTIONS.ESIGN,
            userId: user.user._id,
            tmc: false,
            email
        };

        let token = await this.makeTokenFromJson(bodyObj);
        let url = `${ENV.ELECTRONIC_SIGNATURE_URL}?t=${token}`;
        window.location.href=url;
    }

    makeTokenFromJson = async (bodyObj) => {
        try{
    
          let url = `${ENV.API_URL}/${ENV.USERS_API_URL}/makeTokenFromJson`;
          let res = await HttpRequest(url, bodyObj, HttpRequestTypes.POST, GetLoggedInUserToken());
    
          if (res) {
            return res.token;
          }
    
        } catch (e) {
          console.error(e);
        }
    
        return null;
      }

    download = async (id, name) => {
        let url = `${ENV.API_URL}/${ENV.UPLOADS_API_URL}/download/${name}`;
        window.location.href = url;
    }

    render(){
        return (
            (!this.props.data || this.props.data.length === 0) ? 
            <div className="empty-container custom-box-shadow">

            <DataTable
                columns={this.state.emptyColumns}
                data={this.state.emptyColumnsData}
                pagination={true}
            />

                <div className="text-center empty-container-inner">
                    <h3>לא נמצאו מסמכים עבורך</h3>
                </div>
            </div>
            :
            <DataTable
                columns={this.state.columns}
                data={this.props.data}
                viewDocument={this.viewDocument.bind(this)}
                pagination={true}
                expandableIcon={{
                    collapsed: <div><label style={{padding: "0px 10px", color: Colors.PRIMARY_BLUE, fontSize: 14}}><i className="fa fa-info" style={{marginRight: 5}}></i> הצג פרטים</label></div>,
                    expanded: <div><label style={{padding: "0px 10px", color: Colors.PRIMARY_BLUE, fontSize: 14}}><i className="fa fa-info" style={{marginRight: 5}}></i> הסתר פרטים</label></div>
                }}
                expandableRows
                expandableRowsComponent={<ExpandableDocumentRow viewDocument={this.viewDocument.bind(this)}
                />}
            />
        );
    }
}