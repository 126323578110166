import React, { Component } from 'react';

import "../assets/styles/sidebar.css";
import { GetLoggedInUserInfo, Logout } from '../config/GeneralMethods';
import { SCREEN_NAMES, USERS_ROLES } from '../constants';

import LOGO from '../assets/images/logowhiteBlirok.png';

class SideBar extends Component {

    state = {
        isAdmin: false
    }

    logout = () => {
        Logout();
    }

    componentDidMount() {
        this.updateIsAdmin();
    }

    updateIsAdmin = () => {
        try {
            let userInfo = GetLoggedInUserInfo();

            let allowedUserRoles = [USERS_ROLES.SUPER_ADMIN, USERS_ROLES.ESIGN_ADMIN, USERS_ROLES.BLIROKRATIA_EMPLOYEE];

            if (allowedUserRoles.includes(userInfo.user.role)) {
                this.setState({ isAdmin: true })
            }
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        return (
            <div className="sidebar-container">
                <div className="menu-container">

                    <ul>
                        <li className="pointer">
                            <a href={SCREEN_NAMES.DROPZONE} className="anchor-block">
                                <img src={LOGO} alt="" className="logo-sidebar" />
                            </a>
                        </li>
                        <li className="pointer">
                            <a href={SCREEN_NAMES.HOME_SCREEN} className="anchor-block">
                                <img src={require('../assets/images/Permits@1x.png')} alt="" className="icons-sidebar" />
                                <label>טפסים</label>
                            </a>
                        </li>
                        <li className="pointer">
                            <a href={SCREEN_NAMES.CONTACTS_LIST_SCREEN} className="anchor-block">
                                <img src={require('../assets/images/Employees@1x.png')} alt="" className="icons-sidebar" />
                                <label style={{ textAlign: "center" }}>רשימות תפוצה</label>
                            </a>
                        </li>
                        <li className="pointer">
                            <a href={SCREEN_NAMES.SETTINGS_SCREEN} className="anchor-block">
                                <img src={require('../assets/images/Employees@1x.png')} alt="" className="icons-sidebar" />
                                <label>הגדרות</label>
                            </a>
                        </li>
                        <li className="pointer">
                            <a href={SCREEN_NAMES.CONTACT_SCREEN} className="anchor-block">
                                <img src={require('../assets/images/Guidance@1x.png')} alt="" className="icons-sidebar" />
                                <label>יצירת קשר</label>
                            </a>
                        </li>

                        {this.state.isAdmin && (
                            <li className="pointer">
                                <a href={SCREEN_NAMES.ADMIN_SCREEN} className="anchor-block">
                                    <img src={require('../assets/images/Employees@1x.png')} alt="" className="icons-sidebar" />
                                    <label>מערכת ניהול</label>
                                </a>
                            </li>
                        )}
                    </ul>

                    <div>
                        <div className="logout-container pointer" onClick={this.logout}>
                            <img src={require('../assets/images/logout.svg')} alt="" className="icons-sidebar icon-logout" />
                            <label className="pointer">התנתק</label>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SideBar;