import React, { Component } from 'react';
import Loading from '../Loading';
import Modal from 'react-modal';
import { APP_OPTIONS, Colors, EXTERNAL_APPROVALS_TITLES, LOGIN_METHODS, USERS_ROLES, validateEmail, validatePhone } from '../../constants';
import { Fragment } from 'react';
import { GetLoggedInUserToken, HttpRequest, HttpRequestTypes } from '../../config/GeneralMethods';
import { ENV } from '../../config';
import { confirmAlert } from 'react-confirm-alert';
import AdminUsers from './AdminUsers';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Select from 'react-select'
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 500,
        maxWidth: '45vw'
    }
};

class AdminUser extends Component {

    state = {
        users: [],
        isLoading: true,
        isModalVisible: false,
        successMessage: "",
        errorMessage: "",
        isSaving: false,
        selectedUser: null,
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        password: "",
        isChecked: true,
        organizationTypesSelected: [],
        isExternalApproval: false,
        externalApprovalsSelected: []
    };

    componentDidMount() {
        this.loadUsers();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedCompany !== this.props.selectedCompany) {
            this.loadUsers();
        }
    }

    loadUsers = async () => {
        let users = [];

        let url = `${ENV.API_URL}/${ENV.USERS_API_URL}/getUsersByCompanyId/${this.props?.selectedCompany?._id}`;
        let res = await HttpRequest(url, null, HttpRequestTypes.GET, GetLoggedInUserToken());

        if (res && res.success) {
            let data = res.data || [];

            if (data.length > 0) {
                users = data;
            }
        }

        this.setState({ users, isLoading: false });
        this.resetModal();
    }

    getAvailableOrganizationTypes = () => {
        let organizationTypesSelected = this.props?.selectedCompany?.organizationTypesSelected || [];
        return organizationTypesSelected;
    }

    resetModal = () => {
        this.setState({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            password: ""
        });
    }

    addNewUser = () => {
        this.setState({ selectedUser: null });

        setTimeout(() => {
            this.setState({ isModalVisible: true })
        }, 300);
    }

    editUser = (id) => {

        let { users } = this.state;
        let index = this.getIndexOfUsersById(id);

        if (index !== -1) {

            let selectedUser = users[index];
            let firstName = selectedUser.firstName;
            let lastName = selectedUser.lastName;
            let phone = selectedUser.phone;
            let email = selectedUser.email;
            let isChecked = selectedUser.isActive;
            let { organizationTypesSelected = [], externalApprovals = [], isExternalApproval = false } = selectedUser;
            organizationTypesSelected = organizationTypesSelected.map(item => { return { label: item, value: item } })
            let externalApprovalsSelected = externalApprovals.map(item => { return { label: item, value: item } });

            this.setState({ selectedUser, firstName, lastName, phone, email, isChecked, organizationTypesSelected, externalApprovalsSelected, isExternalApproval });

            setTimeout(() => {
                this.setState({ isModalVisible: true })
            }, 300);
        }
    }

    deleteUser = async (id) => {
        //SHOW POPUP MESSAGE
        confirmAlert({
            title: "מחיקת משתמש",
            message: "האם אתה בטוח?",
            buttons: [
                {
                    label: "לא, סגור",
                    onClick: () => { },
                },
                {
                    label: "כן",
                    onClick: () => {
                        this.removeUserFromDB(id);
                    },
                },
            ],
        });
    }

    removeUserFromDB = async (id) => {
        let url = `${ENV.API_URL}/${ENV.USERS_API_URL}/${id}`;
        await HttpRequest(url, null, HttpRequestTypes.DELETE, GetLoggedInUserToken());
        this.loadUsers();
    }

    closeModal = () => {
        this.setState({ isModalVisible: false, successMessage: "" });
    }

    getFields = () => {
        let { firstName, lastName, email, phone, password } = this.state;

        if (firstName) firstName = firstName.trim();
        if (lastName) lastName = lastName.trim();
        if (email) email = email.trim().toLowerCase();
        if (password) password = password.trim().toLowerCase();
        if (phone) phone = phone.trim();

        return {
            firstName,
            lastName,
            email,
            phone,
            password
        }
    }

    submit = async () => {
        let { selectedUser, organizationTypesSelected, externalApprovalsSelected, isExternalApproval } = this.state;

        let { firstName, lastName, email, phone, password } = this.getFields();
        this.setState({ successMessage: "", errorMessage: "", isSaving: true });

        let { selectedCompany } = this.props;

        let app = selectedCompany.app || APP_OPTIONS.ESIGN;
        let role = app === APP_OPTIONS.TOFES4 ? USERS_ROLES.TOFES4_ORGANIZATION : USERS_ROLES.ESIGN_USER;
        let loginMethod = app === APP_OPTIONS.TOFES4 ? LOGIN_METHODS.EMAIL_OTP : LOGIN_METHODS.EMAIL_PASSWORD;

        if (firstName.length === 0) {
            this.setState({ errorMessage: "חובה להזין שם פרטי" });
        } else if (lastName.length === 0) {
            this.setState({ errorMessage: "חובה להזין שם משפחה" });
        } else if (email.length === 0) {
            this.setState({ errorMessage: "חובה להזין דואר אלקטרוני" });
        } else if (phone.length === 0) {
            this.setState({ errorMessage: "חובה להזין מספר טלפון" });
        } else if (selectedUser === null && app === APP_OPTIONS.ESIGN && password.length === 0) {
            this.setState({ errorMessage: "חובה להזין סיסמא" });
        } else if (!validateEmail(email)) {
            this.setState({ errorMessage: "חובה להזין דואר אלקטרוני חוקי" });
        } else if (!validatePhone(phone)) {
            this.setState({ errorMessage: "חובה להזין מספר טלפון חוקי" });
        } else if (app === APP_OPTIONS.TOFES4 && organizationTypesSelected.length === 0) {
            this.setState({ errorMessage: "חובה לבחור רשימת מחלקות" })
        } else if (app === APP_OPTIONS.TOFES4 && isExternalApproval && externalApprovalsSelected.length === 0) {
            this.setState({ errorMessage: "חובה לבחור גורם מאשר" })
        } else {

            let isAdd = selectedUser === null;
            let successMessage = isAdd ? "משתמש נוצר בהצלחה" : "פרטי המשתמש נערכו בהצלחה";

            if (app === APP_OPTIONS.TOFES4) {
                organizationTypesSelected = organizationTypesSelected.map(item => item.value);
                if (organizationTypesSelected.includes("הכל")) {
                    organizationTypesSelected = [...this.getAvailableOrganizationTypes()];
                }

                if (isExternalApproval) {
                    externalApprovalsSelected = externalApprovalsSelected.map(item => item.value);
                    if (externalApprovalsSelected.includes("הכל")) {
                        externalApprovalsSelected = [...EXTERNAL_APPROVALS_TITLES];
                    }    
                } else {
                    externalApprovalsSelected = [];
                }
            }

            if (isAdd) {
                let url = `${ENV.API_URL}/${ENV.USERS_API_URL}/add-user`;
                let params = {
                    firstName,
                    lastName,
                    phone,
                    email,
                    password,
                    companyId: selectedCompany._id,
                    app,
                    role,
                    loginMethod
                };

                if (app === APP_OPTIONS.TOFES4) {
                    params["organizationTypesSelected"] = organizationTypesSelected;
                    params["externalApprovals"] = externalApprovalsSelected;
                    params["isExternalApproval"] = isExternalApproval;
                }

                let res = await HttpRequest(url, params, HttpRequestTypes.POST, GetLoggedInUserToken());

                if (res && res.success) {
                    this.loadUsers();

                    this.setState({ successMessage });

                    setTimeout(() => {
                        this.closeModal();
                    }, 1200);
                } else {
                    this.setState({ errorMessage: res && res.message ? res.message : "אירעה שגיאה" })
                }
            } else {
                let index = this.getIndexOfUsersById(selectedUser._id);

                if (index !== -1) {
                    let url = `${ENV.API_URL}/${ENV.USERS_API_URL}/edit-user`;
                    let params = {
                        firstName,
                        lastName,
                        phone,
                        email,
                        _id: selectedUser._id,
                        isActive: this.state.isChecked
                    };

                    if (app === APP_OPTIONS.TOFES4) {
                        params["organizationTypesSelected"] = organizationTypesSelected;
                        params["externalApprovals"] = externalApprovalsSelected;
                        params["isExternalApproval"] = isExternalApproval;
                    }

                    let res = await HttpRequest(url, params, HttpRequestTypes.POST, GetLoggedInUserToken());

                    if (res && res.success) {
                        this.loadUsers();

                        this.setState({ successMessage });

                        setTimeout(() => {
                            this.closeModal();
                        }, 1200);
                    } else {
                        this.setState({ errorMessage: res && res.message ? res.message : "אירעה שגיאה" })
                    }
                }
            }
        }

        this.setState({ isSaving: false });
    }


    getIndexOfUsersById = (id) => {
        let { users } = this.state;

        if (!users || users.length === 0) {
            return -1;
        }

        for (let i = 0; i < users.length; i++) {
            if (users[i]._id === id) {
                return i;
            }
        }

        return -1;
    }

    handleChangeChk = (e) => {
        console.log("e: ", e)
        console.log("e.target.value: ", e.target.value)
    }

    renderModalContent = () => {
        return (
            <Fragment>

                <label style={{ color: 'black', marginLeft: 10 }}>
                    שם פרטי:
                </label>

                <input type="text" name="firstName" className="form-control form-control-user" style={{ textAlign: 'right' }} aria-describedby="emailHelp" placeholder="שם פרטי  "
                    onChange={(e) => this.setState({ firstName: e.target.value })} value={this.state.firstName} />

                <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
                    שם משפחה:
                </label>

                <input type="text" name="lastName" className="form-control form-control-user" style={{ textAlign: 'right' }} aria-describedby="emailHelp" placeholder="שם משפחה  "
                    onChange={(e) => this.setState({ lastName: e.target.value })} value={this.state.lastName} />

                <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
                    דוא"ל:
                </label>

                <input type="text" name="email" className="form-control form-control-user" style={{ textAlign: 'right' }} aria-describedby="emailHelp" placeholder="דואר אלקטרוני  "
                    onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} />

                <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
                    מס' טלפון:
                </label>

                <input type="text" name="phone" className="form-control form-control-user" style={{ textAlign: 'right' }} aria-describedby="emailHelp" placeholder="מספר טלפון  "
                    onChange={(e) => this.setState({ phone: e.target.value })} value={this.state.phone} />

                {
                    this.state.selectedUser === null && this.props?.selectedCompany?.app !== APP_OPTIONS.TOFES4 ?
                        <Fragment>
                            <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
                                סיסמא:
                            </label>

                            <input type="text" name="password" className="form-control form-control-user" style={{ textAlign: 'right' }} aria-describedby="emailHelp" placeholder="סיסמא  "
                                onChange={(e) => this.setState({ password: e.target.value })} value={this.state.password} />

                        </Fragment>
                        : <Fragment>
                            <label style={{ marginTop: 10, marginLeft: 10 }}>
                                <input type="checkbox"
                                    checked={this.state.isChecked}
                                    style={{ marginRight: 5, marginLeft: 5 }}
                                    onChange={() => this.setState({ isChecked: !this.state.isChecked })}
                                />
                                משתמש פעיל?
                            </label>
                        </Fragment>
                }

                {
                    this.props?.selectedCompany?.app === APP_OPTIONS.TOFES4 && (
                        <div>
                            <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
                                בחירת רשימת המחלקות:
                            </label>
                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                options={["הכל", ...this.getAvailableOrganizationTypes()].map(item => { return { label: item, value: item } })}
                                isMulti
                                value={this.state.organizationTypesSelected}
                                onChange={(selected) => this.setState({ organizationTypesSelected: selected })}
                                placeholder="בחירת רשימת המחלקות"
                            />
                        </div>
                    )
                }

                {
                    this.props?.selectedCompany?.app === APP_OPTIONS.TOFES4 && (
                        <Fragment>
                            <label style={{ marginTop: 10, marginLeft: 10 }}>
                                <input type="checkbox"
                                    checked={this.state.isExternalApproval}
                                    style={{ marginRight: 5, marginLeft: 5 }}
                                    onChange={() => this.setState({ isExternalApproval: !this.state.isExternalApproval })}
                                />
                                גורם מאשר?
                            </label>

                            {this.state.isExternalApproval && (
                                <div>
                                    <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
                                        בחירת גורם מאשר:
                                    </label>
                                    <Select
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        options={["הכל", ...EXTERNAL_APPROVALS_TITLES].map(item => { return { label: item, value: item } })}
                                        isMulti
                                        value={this.state.externalApprovalsSelected}
                                        onChange={(selected) => this.setState({ externalApprovalsSelected: selected })}
                                        placeholder="בחירת גורם מאשר"
                                    />
                                </div>
                            )}
                        </Fragment>
                    )}

                {
                    this.state.isSaving ?
                        <div style={{ marginTop: 25 }}>
                            <Loading />
                        </div>
                        :
                        <div className="text-center w-50 btn-send-email" style={{ marginTop: 25 }}>
                            <input type="button" className="btn btn-user btn-custom btn-block" style={{ backgroundColor: Colors.PRIMARY_YELLOW }} value={this.state.selectedUser === null ? "הוספה" : "שמירת שינויים"} onClick={this.submit} />
                        </div>
                }

                <div className="mt-3 text-right mr-2"><span className="text-success">{this.state.successMessage}</span></div>
                <div className="mt-3 text-right mr-2"><span className="text-danger">{this.state.errorMessage}</span></div>
            </Fragment>
        );
    }

    render() {
        return (
            <div className="container">

                <div className="mt-3 d-flex justify-content-between align-items-center home-container">

                    <div className="">
                        <button className="btn prim-btn no-animation custom-box-shadow" style={{}}
                            onClick={this.addNewUser}>
                            הוספת משתמש חדש
                        </button>
                    </div>

                    <div className="text-right">
                        <h4 style={{ fontWeight: 'bold' }}>רשימת המשתמשים ב{this.props?.selectedCompany?.name}</h4>
                        <label onClick={this.props?.goBack} style={{ cursor: "pointer", textAlign: "right", fontWeight: "bold", fontSize: 17 }}>חזור <i className="fa fa-chevron-right"></i> </label>
                    </div>
                </div>

                <div className="mt-3 mb-3">
                    {
                        this.state.isLoading ?
                            <Loading />
                            : <AdminUsers data={this.state.users}
                                editUser={this.editUser.bind(this)}
                                deleteUser={this.deleteUser.bind(this)} />
                    }
                </div>

                <Modal
                    ariaHideApp={false}
                    isOpen={this.state.isModalVisible}
                    style={customStyles}>

                    <div>
                        <div className="d-flex justify-content-between align-items-center">
                            <div></div>
                            <div>
                                <label className="labelModal">
                                    {this.state.selectedUser === null ? "הוספת משתמש חדש" : "עריכת פרטי משתמש"}
                                </label>
                            </div>
                            <div>
                                <i className="fa fa-times pointer" onClick={this.closeModal}></i>
                            </div>
                        </div>

                        <div style={{ direction: 'rtl', marginTop: 20, textAlign: 'right' }}>
                            {this.renderModalContent()}
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default AdminUser;