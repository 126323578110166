import moment from 'moment';
import React, { Component } from 'react';
import "../assets/styles/topbar.css";
import { ENV } from '../config';
import { GetLoggedInUserInfo, GetLoggedInUserToken, HttpRequest, HttpRequestTypes, Logout } from '../config/GeneralMethods';
import { Colors, SCREEN_NAMES } from '../constants';

class TopNavBar extends Component {
    
    state = {
        userName: '',
        userMenuShow: false,
        notificationShow: false,
        notifications: [],
        dsblock: "",
        unreadNotications: 0
    }

    componentDidMount(){
        let userName = "";
        try{
            let userInfo = GetLoggedInUserInfo();

            let firstName = userInfo.user?.firstName;
            let lastName = userInfo.user?.lastName;

            userName = (firstName || "") + " " + (lastName || "");
        }catch(e){
            console.error(e);
        }

        this.setState({userName});
        this.loadNotifications();

        this.notificationInterval = setInterval(() => this.loadNotifications(), 60 * 1000);

        this.setClickEventForHTMLTag();
    }

    setClickEventForHTMLTag = () => {
        
        const htmlTag = document.getElementsByTagName("html");

        if(htmlTag && htmlTag.length > 0){
            //Target Tag will be the first
            htmlTag[0].onclick = (e) => {
                let element = e.target;
                //If the target click is on the notification dropdown or one of the children then do nothing
                //DO NOT CLOSE!
                //otherwise, close the drop down list if open
                if(element && (element.classList.contains("ddl-notification-ul") || element.classList.contains("dont-close"))){
                    //DO NOTHING
                } else {
                    this.closeNotificationView();    
                }
            }
        };
    }

    componentWillUnmount(){
        if(this.notificationInterval){
            clearInterval(this.notificationInterval);
        }
    }

    loadNotifications = async () => {
        
        let url = `${ENV.API_URL}/${ENV.NOTIFICATIONS_URL}`;
        let res = await HttpRequest(url, null, HttpRequestTypes.GET, GetLoggedInUserToken());

        if(res && res.success){

            let data = res.data;

            let notifications = [];
            let unreadNotications = 0;

            for(let i = data.length - 1; i >= 0; i--){
                notifications.push({
                    _id: data[i]._id,
                    userId: data[i].userId,
                    title: data[i].title,
                    message: data[i].message,
                    sentDate: moment(data[i].sentAt).format("DD/MM/YYYY HH:mm"),
                    isRead: data[i].isRead
                })

                if(!data[i].isRead){
                    unreadNotications++;
                }
            }

            this.setState({ notifications, unreadNotications });
        }
    }

    logout = () => {
        Logout();
    }

    userMenuClicked = () => {
        this.setState({ userMenuShow: !this.state.userMenuShow });
    }

    notificationShowClicked = () => {

        let {notificationShow} = this.state;

        if(notificationShow){
            this.closeNotificationView();
        } else {
            this.openNotificationView();
        }
    }

    openNotificationView = () => {
        this.setNotificationDisplayBlock("ds-block");
        this.updateNotificationVisible(true);
    }

    closeNotificationView = () => {

        if(!this.state.notificationShow){
            return;
        }

        if(this.state.unreadNotications > 0){
            this.readAllNotifications();
        }

        this.setNotificationDisplayBlock("");
        this.updateNotificationVisible(false);
    }

    setNotificationDisplayBlock = (dsblock) => {
        this.setState({ dsblock });
    }

    updateNotificationVisible = (notificationShow) => {
        setTimeout(() => {
            this.setState({ notificationShow });    
        }, 200);
    }


    notificationRead = async (id) => {
        let url = `${ENV.API_URL}/${ENV.NOTIFICATIONS_URL}/${id}`;
        let res = await HttpRequest(url, null, HttpRequestTypes.POST, GetLoggedInUserToken());

        if(res && res.success){
            this.loadNotifications();
        }
    }

    notificationDelete = async (id) => {
        let url = `${ENV.API_URL}/${ENV.NOTIFICATIONS_URL}/${id}`;
        let res = await HttpRequest(url, null, HttpRequestTypes.DELETE, GetLoggedInUserToken());

        if(res && res.success){
            this.loadNotifications();
        }
    }

    readAllNotifications = async () => {
        let url = `${ENV.API_URL}/${ENV.NOTIFICATIONS_URL}/readAll`;
        let res = await HttpRequest(url, null, HttpRequestTypes.POST, GetLoggedInUserToken());

        if(res && res.success){
            this.loadNotifications();
        }
    }

    deleteAllNotifications = async () => {
        let url = `${ENV.API_URL}/${ENV.NOTIFICATIONS_URL}`;
        let res = await HttpRequest(url, null, HttpRequestTypes.DELETE, GetLoggedInUserToken());

        if(res && res.success){
            this.loadNotifications();
        }
    }

    render(){
        return (
            <div className="topnavbar-container">
                <div className="navbar-container">
                    <div className="username-container position-relative">
                        
                        <i className={"fa pointer " + (this.state.userMenuShow ? 'fa-chevron-up' : 'fa-chevron-down')} onClick={this.userMenuClicked}></i>
                        <label>
                            {!this.state.userName ? "" : ("שלום, " + this.state.userName)}
                        </label>

                        <div className="ddl-container">
                            <ul className={"ddl-ul custom-box-shadow " + (this.state.userMenuShow ? 'active': '')}>
                                <li><a href={SCREEN_NAMES.PROFILE_SCREEN}>פרופיל אישי</a></li> 
                                <li>
                                    {/* eslint-disable-next-line */}
                                    <a href='#' onClick={this.logout}>
                                    התנתק
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="notification-container1">
                        <div className="notification-inner-container pointer">
                            {
                                this.state.unreadNotications === 0 ?
                                null : 
                                <span>{this.state.unreadNotications}</span>
                            }
                            
                            <i className="fa fa-bell" style={{color: Colors.PRIMARY_BLUE}} onClick={this.notificationShowClicked}></i>

                            <div className="ddl-notification-container dont-close">
                                <ul className={"ddl-notification-ul dont-close custom-box-shadow " + (this.state.dsblock + ' ' + (this.state.notificationShow ? 'active': ''))}>
                                    <li className="dont-close"><label className="dont-close" style={{direction: 'rtl', fontWeight: 'bold', color: 'black'}}>התראות אחרונות: </label></li>

                                    {
                                        this.state.notifications.length === 0 ?
                                        null :
                                        <div style={{marginBottom: 10, display: "flex", justifyContent: "space-between", alignItems: "center"}}
                                            className="dont-close">
                                            <label onClick={this.readAllNotifications} className="custom-notification-icon-btn dont-close">סמן הכל כנקרא <i className="fa fa-envelope-open"></i></label>
                                            <label onClick={this.deleteAllNotifications} className="custom-notification-icon-btn dont-close">מחק הכל <i className="fa fa-trash"></i></label>
                                        </div>
                                    }

                                   {
                                    this.state.notifications.length === 0 ? 
                                    <li style={{textAlign: 'center'}} className="dont-close">
                                        <label style={{direction: 'rtl', color: 'black', fontWeight: 'bolder'}} className="dont-close">
                                            לא נמצאו התראות
                                        </label>
                                    </li>
                                   : this.state.notifications.map( (val, ind) => 
                                    <li key={ind} className="dont-close">
                                        <div className="notification-item-div dont-close" style={{backgroundColor: val.isRead ? 'white': 'rgb(230 230 230)'}} onClick={() => this.notificationRead(val._id)}>
                                            <div className="dont-close">
                                                <label className="dont-close" style={{direction: 'rtl', marginRight: 5, fontWeight: "bold"}}>{val.title}</label>
                                            </div>

                                            <div className="dont-close">
                                                <label className="dont-close" style={{direction: 'rtl', marginRight: 5, fontSize: 14}}>
                                                    {val.message}
                                                </label>
                                                
                                            </div>
                                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}
                                                className="dont-close">
                                                <label className="dont-close" style={{marginLeft: 5, cursor: "pointer", zIndex: 5}} onClick={() => this.notificationDelete(val._id)}>
                                                    <i className="fa fa-trash dont-close"></i>
                                                </label>
                                                <label className="dont-close" style={{direction: 'rtl', marginRight: 5, fontSize: 12}}>התקבלה: {val.sentDate}</label>
                                            </div>
                                        </div>
                                    </li>
                                   )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TopNavBar;