import React, { Component, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import moment from "moment";
import { Colors } from '../../constants';

export default class AdminUsers extends Component{

    state = {
        columns: [
            {name: '', sortable: false, center: true, style: {fontSize: 14},
                cell: row => <Fragment>        
                <button className="icon-button" data-tooltip="מחיקה" onClick={() => this.props.deleteUser(row._id)}>
                    <span className="tooltiptext">מחיקה</span>
                    <i className="eye fa fa-trash text-danger"></i>
                </button>
                <button className="icon-button ml-2" data-tooltip="עריכה" onClick={() => this.props.editUser(row._id)}>
                    <span className="tooltiptext">עריכה</span>
                    <i className="download fa fa-pencil"></i>
                </button>
            </Fragment>
            },
            {name: 'הצטרף בתאריך', sortable: true, selector: 'joinedAt', center: true, style: {fontSize: 14, textAlign: "center"},
                cell: row => <label>{moment(row.joinedAt).format("DD/MM/yyyy")}</label>
            },
            {name: 'משתמש פעיל/חסום', sortable: true, selector: 'isActive', center: true, style: {fontSize: 14, textAlign: "center"},
                cell: row => <label style={{color: row.isActive ? Colors.PRIMARY_BLUE : "#f00"}}>{row.isActive ? "זמין" : "חסום"}</label>
            },
            {name: 'מספר טלפון', sortable: true, selector: 'phone', center: true, style: {fontSize: 14, textAlign: "center"}},
            {name: 'דואר אלקטרוני', sortable: true, selector: 'email', center: true, style: {fontSize: 14, textAlign: "center"}},
            {name: 'שם משפחה', sortable: true, selector: 'lastName', center: true, style: {fontSize: 14, textAlign: "center"}},
            {name: 'שם פרטי', sortable: true, selector: 'firstName', center: true, style: {fontSize: 14, textAlign: "center"}},
        ],
        emptyColumns: [
            {name: '', sortable: false, selector: 'actions', center: true},
            {name: 'הצטרף בתאריך', sortable: true, selector: 'joinedAt', center: true, style: {fontSize: 14, textAlign: "center"}},
            {name: 'משתמש פעיל/חסום', sortable: true, selector: 'isActive', center: true, style: {fontSize: 14, textAlign: "center"}},
            {name: 'מספר טלפון', sortable: true, selector: 'phone', center: true, style: {fontSize: 14, textAlign: "center"}},
            {name: 'דואר אלקטרוני', sortable: true, selector: 'email', center: true, style: {fontSize: 14, textAlign: "center"}},
            {name: 'שם משפחה', sortable: true, selector: 'lastName', center: true, style: {fontSize: 14, textAlign: "center"}},
            {name: 'שם פרטי', sortable: true, selector: 'firstName', center: true, style: {fontSize: 14, textAlign: "center"}},
        ],
        emptyColumnsData: [{firstName: "", lastName: "", email: "", phone: "", isActive: "", actions: '', joinedAt: ""}],
    }

    componentDidMount(){

        //Change Text OF "Rows per page: " by it's class
        let rowsPerPageText = document.getElementsByClassName("gckGBs");
        if(rowsPerPageText && rowsPerPageText.length > 0){
            rowsPerPageText[0].innerHTML = "שורות בכל דף"
        }
    }

    render(){
        return (
            (!this.props.data || this.props.data.length === 0) ? 
            <div className="empty-container custom-box-shadow">

            <DataTable
                columns={this.state.emptyColumns}
                data={this.state.emptyColumnsData}
                pagination={true}
            />

                <div className="text-center empty-container-inner">
                    <h3>לא נמצאו משתמשים בארגון זה</h3>
                </div>
            </div>
            :
            <DataTable
                columns={this.state.columns}
                data={this.props.data}
                pagination={true}
            />
        );
    }
}