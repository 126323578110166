import React, { Component, Fragment } from 'react'
import { ENV } from '../../config';
import { GetLoggedInUserInfo, GetLoggedInUserToken, HttpRequest, HttpRequestTypes } from '../../config/GeneralMethods';
import TOFES4_LOGO from '../../assets/images/tofes4_logo.png'

import Loading from '../Loading';
import Modal from 'react-modal';
import { confirmAlert } from 'react-confirm-alert';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { APP_OPTIONS, Colors, ORGANIZATION_TYPES } from '../../constants';
import axios from 'axios';

const animatedComponents = makeAnimated();

const STEPS = {
    REGIONS_STEP: 1,
    COUNCILS_STEP: 2,
    CATEGORIES_LEVEL1_STEP: 3,
    CATEGORIES_LEVEL2_STEP: 4,
    CATEGORIES_LEVEL3_STEP: 5,
    DOCUMENTS_STEP: 6
}

const TITLES = {
    REGIONS_TITLE: "איזורים",
    COUNCILS_TITLE: "מועצות/יישובים",
    CATEGORIES_TITLE: "קטגוריות",
    DOCUMENTS_TITLE: "מסמכים"
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 500,
        maxWidth: '45vw'
    }
};

class FilesMap extends Component {

    state = {
        step: STEPS.REGIONS_STEP,
        regions: [],
        councils: [],
        categories: [],
        superCategories: [],
        subCategories: [],
        documents: [],
        region: "",
        council: "",
        superCategory: "",
        subCategory: "",
        superCategoryName: "",
        subCategoryName: "",
        category: "",
        regionObj: {},
        menuItemsVisibleId: null,
        isLoading: true,
        isModalVisible: false,
        successMessage: "",
        errorMessage: "",
        selectedDocument: null,
        documentInfo: {
            name: ""
        },
        url: "",
        fileName: "",
        categoriesSelected: [],
        isSaving: false,
        selectedConstructionStep: [],
        selectedConstructionType: [],
        file: null,
        constructionSteps: [],
        constructionTypes: [],
        selectedOrganizationTypes: [{label: ORGANIZATION_TYPES[0], value: ORGANIZATION_TYPES[0]}]
    }

    componentDidMount() {
        this.loadScreen();
    }

    loadScreen = () => {
        this.loadRegionsAndCouncils();
        this.loadCategories();
        this.loadDocuments();
        this.loadConstructionTypes();
        this.loadConstructionSteps();
    }

    loadRegionsAndCouncils = async () => {
        try {
            let url = `${ENV.API_URL}/${ENV.CONCILS_URL}/get-list-by-admin`;
            let res = await HttpRequest(url, null, HttpRequestTypes.GET, GetLoggedInUserToken());

            if (res) {
                let { regions, councils } = res;

                this.setState({ councils, regions });
            }
        } catch (e) {
            console.log(e);
        }
    }

    loadCategories = async () => {
        try {
            let url = `${ENV.API_URL}/${ENV.CATEGORIES_URL}`;
            let res = await HttpRequest(url, null, HttpRequestTypes.GET, GetLoggedInUserToken());

            if (res && res.success) {
                let { superCategories, subCategories, categories } = res;
                this.setState({ superCategories, subCategories,
                    categories: [{
                        addedRecently: false,
                        isActive: true,
                        name: "כללי",
                        subCategoryIds: []
                    }, ...categories] 
                })
            }
        } catch (e) {
            console.log(e);
        }
    }

    loadDocuments = async () => {
        try {
            let documents = [];

            let url = `${ENV.API_URL}/${ENV.FILES_URL}/get-all-admin`;
            let res = await HttpRequest(url, null, HttpRequestTypes.GET, GetLoggedInUserToken());

            if (res && res.success) {
                let data = res.data || [];

                if (data.length > 0) {
                    documents = data;
                }
            }

            this.setState({ documents });
        } catch (e) {
            console.log(e);
        }
    }

    loadConstructionSteps = async () => {
        try {
            let url = `${ENV.API_URL}/${ENV.CONSTRUCTION_STEPS_URL}/get-all`;
            let res = await HttpRequest(url, null, HttpRequestTypes.GET, GetLoggedInUserToken());

            if (res) {
                let { constructionSteps } = res;
                let selectedConstructionStep = constructionSteps && constructionSteps.length > 0 ? constructionSteps[0].name : "";

                this.setState({ constructionSteps, selectedConstructionStep });
            }
        } catch (e) {
            console.log(e);
        }
    }

    loadConstructionTypes = async () => {
        try {
            let url = `${ENV.API_URL}/${ENV.CONSTRUCTION_TYPES_URL}/get-all`;
            let res = await HttpRequest(url, null, HttpRequestTypes.GET, GetLoggedInUserToken());

            if (res) {
                let { constructionTypes } = res;
                let selectedConstructionType = constructionTypes && constructionTypes.length > 0 ? constructionTypes[0].name : "";

                this.setState({ constructionTypes, selectedConstructionType });
            }
        } catch (e) {
            console.log(e);
        }
    }

    toggleActive = (id) => {
        this.removeAllActiveClasses(id);
        document.getElementById(id).classList.toggle('active');
    }

    removeAllActiveClasses = (id = null) => {
        let classes = document.getElementsByClassName('folder-container');

        if (classes && classes.length > 0) {
            for (let i = 0; i < classes.length; i++) {
                if (classes[i].id !== id && classes[i].classList.contains('active')) {
                    classes[i].classList.remove('active');
                }
            }
        }
    }

    itemClicked = (val) => {
        let { step } = this.state;
        this.removeAllActiveClasses();

        switch (step) {
            case STEPS.COUNCILS_STEP:
                this.setState({ council: val.name, step: STEPS.CATEGORIES_LEVEL1_STEP })
                break;

            case STEPS.CATEGORIES_LEVEL1_STEP:
                this.setState({ superCategory: val.id, superCategoryName: val.name, step: STEPS.CATEGORIES_LEVEL2_STEP })
                break;

            case STEPS.CATEGORIES_LEVEL2_STEP:
                this.setState({ subCategory: val.id, subCategoryName: val.name, step: STEPS.CATEGORIES_LEVEL3_STEP })
                break;

            case STEPS.CATEGORIES_LEVEL3_STEP:
                this.setState({ category: val.name, step: STEPS.DOCUMENTS_STEP })
                break;

            case STEPS.REGIONS_STEP:
            default:
                this.setState({ region: val.id, step: STEPS.COUNCILS_STEP, regionObj: val })
                break;
        }

    }

    renderFoldersSteps = () => {
        let arr = this.getFolderArrayList();

        return (
            arr.map((val, ind) => <div key={ind} className='folder-container' id={`itm_${ind}`}
                onClick={() => this.toggleActive(`itm_${ind}`)}
                onDoubleClick={() => this.itemClicked(val)}>
                <i className='fa fa-folder'></i>
                <label className='folder-title'>{val.name}</label>
            </div>)
        )
    }

    getFolderArrayList = () => {
        let arr = [];
        let { step, region, regions, councils, superCategories, subCategories, categories, superCategory, subCategory } = this.state;

        switch (step) {
            case STEPS.COUNCILS_STEP:
                arr = councils.filter(val => val.regionId === region).map(val => {
                    return { id: val._id, name: val.name }
                })
                break;

            case STEPS.CATEGORIES_LEVEL1_STEP:
                arr = superCategories.map(val => {
                    return { id: val._id, name: val.name }
                })
                break;

            case STEPS.CATEGORIES_LEVEL2_STEP:
                arr = subCategories.filter(val => val.superCategoryId === superCategory).map(val => {
                    return { id: val._id, name: val.name }
                })
                break;

            case STEPS.CATEGORIES_LEVEL3_STEP:
                arr = categories.filter(val => val.subCategoryIds.includes(subCategory)).map(val => {
                    return { id: val._id, name: val.name }
                })
                break;

            case STEPS.REGIONS_STEP:
            default:
                arr = regions.map(val => {
                    return { id: val._id, name: val.name }
                })
                break;
        }

        return arr;
    }

    getTitleBasedOnStep = () => {
        let { step } = this.state;

        switch (step) {
            case STEPS.DOCUMENTS_STEP:
                return TITLES.DOCUMENTS_TITLE;

            case STEPS.COUNCILS_STEP:
                return TITLES.COUNCILS_TITLE;

            case STEPS.CATEGORIES_LEVEL1_STEP:
            case STEPS.CATEGORIES_LEVEL2_STEP:
            case STEPS.CATEGORIES_LEVEL3_STEP:
                return TITLES.CATEGORIES_TITLE;

            case STEPS.REGIONS_STEP:
            default:
                return TITLES.REGIONS_TITLE;
        }
    }

    menuItemsClicked = (id) => {
        let { menuItemsVisibleId } = this.state;
        if (menuItemsVisibleId === id) id = null;
        this.setState({ menuItemsVisibleId: id });
    }

    renderDocumentsStep = () => {
        let { documents, category, council } = this.state;
        let arr = documents.filter(val => val.categories.includes(category) && val.council === council);

        return (
            arr.length === 0 ? (
                <>
                    <div className='no-files'>
                        <label>לא נמצאו מסמכים</label>
                    </div>
                </>
            ) : (
                arr.map((val, ind) => <div key={ind} className='files-container'>
                    <img src={TOFES4_LOGO} alt="" />
                    
                    <div className='files-bottom-container'>
                        <div className='title-and-icon'>
                            <i className='fa fa-file-pdf-o'></i>
                            <label className='folder-title'>{val.name}</label>
                        </div>
                        <div className='vertical-settings' onClick={() => this.menuItemsClicked(`menu-${ind}`)}>
                            <i className="fa fa-ellipsis-v"></i>

                            {this.state.menuItemsVisibleId === `menu-${ind}` && (
                                <div className='menu-action'>
                                    <button type='button' onClick={() => window.open(val.url, "_blank")}>הצגה</button>
                                    <button type='button' onClick={() => this.createURLAndOpenInNewTab(val._id)}>בניית תבנית</button>
                                    <button type='button' onClick={() => this.editDocument(val._id)}>עריכה</button>
                                    <button type='button' onClick={() => this.deleteDocument(val._id)}>מחיקה</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>)
            )
        )
    }

    uploadDocument = () => {

        let {documentInfo} = this.state;
        documentInfo.name = "";
        
        this.setState({ selectedDocument: null, categoriesSelected: [{label: this.state.category, value: this.state.category}], selectedConstructionStep: [], selectedConstructionType: [], documentInfo, selectedOrganizationTypes: [{label: ORGANIZATION_TYPES[0], value: ORGANIZATION_TYPES[0]}] });

        setTimeout(() => {
            this.setState({ isModalVisible: true })
        }, 300);
    }

    documentInfoItemChange = (key, value) => {
        let {documentInfo} = this.state;
        documentInfo[key] = value;
        this.setState({ documentInfo });
    }

    setSelectedCategories = (selected) => {
        this.setState({ categoriesSelected: selected })
    }

    fileChanged = (event) => {
        let selectedFile = event.target.files[0];
        let fileName = selectedFile.name || "";

        this.setState({ file: selectedFile, fileName });
    }

    submit = async (withTemplateCreation = false) => {
        try {
            //we should populate the REGION, COUNCIL AS SELECTED PATH
            this.setState({ errorMessage: "", successMessage: "", isSaving: true });

            let { documentInfo, categoriesSelected, council, selectedConstructionStep, file, selectedDocument, selectedConstructionType, selectedOrganizationTypes } = this.state;
            let {name} = documentInfo;

            let isAdd = selectedDocument === null;

            if (name.trim().length === 0) {
                this.setState({ errorMessage: "**חובה להזין שם המסמך" });
            } else if (categoriesSelected.length === 0) {
                this.setState({ errorMessage: "**חובה לבחור קטגוריה אחת לפחות" });
            } else if (council.length === 0) {
                this.setState({ errorMessage: "**חובה לבחור מועצה אחת לפחות" });
            } else if (selectedConstructionStep.length === 0) {
                this.setState({ errorMessage: "**חובה לבחור שלב בניה אחד לפחות" });
            } else if (selectedConstructionType.length === 0) {
                this.setState({ errorMessage: "**חובה לבחור סוג מבנה אחד לפחות" });
            } else if (selectedOrganizationTypes.length === 0) {
                this.setState({ errorMessage: "**חובה לבחור מחלקה אחת לפחות" });
            } else if (isAdd && file === null) {
                this.setState({ errorMessage: "**חובה להעלות קובץ" });
            } else if (file !== null && file.type !== "application/pdf") {
                this.setState({ errorMessage: "**סוג קובץ לא נתמך, אפשר להעלות קבצי PDF בלבד" });
            } else {

                // let constructionStep = constructionSteps.filter(val => val.id === parseInt(selectedConstructionStep));
                // constructionStep = constructionStep.length > 0 ?  constructionStep[0].title : selectedConstructionStep;

                // let constructionType = constructionTypes.filter(val => val.id === parseInt(selectedConstructionType));
                // constructionType = constructionType.length > 0 ? constructionType[0].title : selectedConstructionType;

                let params = {
                    name,
                    categories: categoriesSelected.map(val => val.value),
                    council,
                    constructionStep: selectedConstructionStep.map(val => val.value),
                    constructionType: selectedConstructionType.map(val => val.value),
                    selectedOrganizationTypes: selectedOrganizationTypes.map(val => val.value)
                };

                let formData = new FormData();

                if (file) {
                    formData.append("data", file)
                }
                
                for(const [key, value] of Object.entries(params)) {
                    formData.append(key, value);
                }

                //Add
                if (isAdd) {
                    let requestUrl = `${ENV.API_URL}/${ENV.FILES_URL}/add`;

                    let response = await axios.post(requestUrl, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            'x-auth-token': GetLoggedInUserToken()
                        }
                    })

                    let data = response.data;

                    if (data) {
                        if (data.success) {
                            this.setState({ successMessage: "המסמך נוצר בהצלחה", name: "", file: null, fileName: "", url: "", categoriesSelected: [] });

                            this.loadDocuments();

                            setTimeout(() => {
                                this.setState({ successMessage: "" });

                                if (!withTemplateCreation) {
                                    this.setState({ isModalVisible: false })
                                }
                            }, 2000);

                            if (withTemplateCreation) {
                                await this.createURLAndOpenInNewTab(data.result._id);
                                this.setState({ isModalVisible: false })
                            }
                        } else {
                            this.setState({ errorMessage: data.message || "אירעה שגיאה, נא לנסות שנית" });
                        }
                    } else {
                        this.setState({ errorMessage: "אירעה שגיאה, נא לנסות שנית" });
                    }

                } else {
                    //Edit
                    let requestUrl = `${ENV.API_URL}/${ENV.FILES_URL}/edit`;

                    formData.append("_id", selectedDocument._id);

                    let response = await axios.put(requestUrl, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            'x-auth-token': GetLoggedInUserToken()
                        }
                    })

                    let data = response.data;

                    if (data) {
                        if (data.success) {
                            this.setState({ successMessage: "המסמך נערך בהצלחה", name: "", file: null, fileName: "", url: "", categoriesSelected: [] });

                            this.loadDocuments();

                            setTimeout(() => {
                                this.setState({ successMessage: "" });
                                
                                if (!withTemplateCreation) {
                                    this.setState({ isModalVisible: false })
                                }
                            }, 2000);

                            if (withTemplateCreation) {
                                await this.createURLAndOpenInNewTab(selectedDocument._id);
                                this.setState({ isModalVisible: false })
                            }
                        } else {
                            this.setState({ errorMessage: data.message || "אירעה שגיאה, נא לנסות שנית" });
                        }
                    } else {
                        this.setState({ errorMessage: "אירעה שגיאה, נא לנסות שנית" });
                    }
                }
            }

        } catch (e) {
            console.log(e);
            let message = e.message || "";

            if (typeof message !== typeof "") {
                message = JSON.stringify(message);
            }

            this.setState({ errorMessage: message });
        } finally {
            this.setState({ isSaving: false });
        }
    }

    createURLAndOpenInNewTab = async (id) => {
        let token = await this.makeTokenFromJson(id);
        //Open new Tab with this token and Close Popup
        let url = `${ENV.ELECTRONIC_SIGNATURE_URL}?t=${token}`;
        window.open(url, "_blank");
    }

    editDocument = (id) => {

        let { documents } = this.state;

        documents = documents.filter(val => val._id === id);
        let currentDocument = documents.length > 0 ? documents[0] : null;

        if (currentDocument) {

            let url = currentDocument.url || "";
            let name = currentDocument.name || "";
            let categoriesSelected = currentDocument.categories.map(val => {return { label: val, value: val }});
            let selectedConstructionStep = currentDocument.constructionStep || [];
            let selectedConstructionType = currentDocument.constructionType || [];
            let selectedOrganizationTypes = currentDocument.selectedOrganizationTypes || [];

            selectedConstructionStep = selectedConstructionStep.map(val => {return { label: val, value: val }});
            selectedConstructionType = selectedConstructionType.map(val => {return { label: val, value: val }});
            selectedOrganizationTypes = selectedOrganizationTypes.map(val => { return {label: val, value: val} })

            let documentInfo = {
                name
            }

            this.setState({ selectedDocument: currentDocument, url, documentInfo, categoriesSelected, selectedConstructionStep, selectedConstructionType, selectedOrganizationTypes });

            setTimeout(() => {
                this.setState({ isModalVisible: true })
            }, 300);
        }
    }

    deleteDocument = async (id) => {
        //SHOW POPUP MESSAGE
        confirmAlert({
            title: "מחיקת מסמך",
            message: "פעולה זו תמחק את המסמך ללא אפשרות שחזור",
            buttons: [
                {
                    label: "לא, סגור",
                    onClick: () => { },
                },
                {
                    label: "כן",
                    onClick: () => {
                        this.removeDocument(id);
                    },
                },
            ],
        });
    }

    removeDocument = async (id) => {
        let url = `${ENV.API_URL}/${ENV.FILES_URL}/${id}`;
        let res = await HttpRequest(url, null, HttpRequestTypes.DELETE, GetLoggedInUserToken());

        if (res && res.success) {
            this.loadDocuments();
        }
    }

    closeModal = () => {
        this.setState({ isModalVisible: false, successMessage: "" });
    }

    renderModalContent = () => {
        return (
            <Fragment>

                <label style={{ color: 'black', marginLeft: 10 }}>
                    שם המסמך:
                </label>

                <input type="text" name="name" className="form-control form-control-user" style={{ textAlign: 'right' }}
                    aria-describedby="emailHelp" placeholder="שם המסמך  "
                    onChange={(e) => this.documentInfoItemChange("name", e.target.value)} value={this.state.documentInfo.name} />

                <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
                    בחירת קטגוריה:
                </label>

                <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    options={this.state.categories.map(item => { return { label: item.name, value: item.name } })}
                    isMulti
                    value={this.state.categoriesSelected}
                    onChange={this.setSelectedCategories}
                    placeholder="בחר קטגוריה/ות"
                />

                <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
                    בחירת שלב בנייה:
                </label>

                <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    options={this.state.constructionSteps.map(item => { return { label: item.name, value: item.name } })}
                    isMulti
                    value={this.state.selectedConstructionStep}
                    onChange={(selected) => this.setState({ selectedConstructionStep: selected })}
                    placeholder="בחירת שלב בנייה"
                />

                <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
                    בחירת סוג מבנה:
                </label>

                <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    options={this.state.constructionTypes.map(item => { return { label: item.name, value: item.name } })}
                    isMulti
                    value={this.state.selectedConstructionType}
                    onChange={(selected) => this.setState({ selectedConstructionType: selected })}
                    placeholder="בחירת סוג מבנה"
                />

                <div className="text-left">
                    <input type="file" accept="application/pdf" id="fileUpload" style={{ visibility: "collapse", width: 0 }} onChange={this.fileChanged} />

                    <input type="text" name="fileName" className="form-control form-control-user" style={{ textAlign: 'right' }} disabled
                        placeholder="שם הקובץ  " value={this.state.fileName} />

                    <button className="btn prim-btn no-animation custom-box-shadow  mt-3" type="button" onClick={() => document.getElementById("fileUpload").click()}>
                        העלאת קובץ
                    </button>
                </div>


                {this.state.selectedDocument !== null ? (
                    <>
                        <label style={{ color: 'black', marginLeft: 10, marginTop: 10 }}>
                            קישור הקובץ
                        </label>

                        <input type="text" name="url" className="form-control form-control-user" style={{ textAlign: 'right' }} disabled
                            placeholder="קישור הקובץ  " onChange={(e) => this.setState({ url: e.target.value })} value={this.state.url} />
                    </>
                ) : null}

                {
                    this.state.isSaving ?
                        <div style={{ marginTop: 25 }}>
                            <Loading />
                        </div>
                        :
                        <div className="text-center w-50 btn-send-email" style={{ marginTop: 25 }}>
                            <input type="button" className="btn btn-user btn-custom btn-block" style={{ backgroundColor: Colors.PRIMARY_YELLOW }} value={this.state.selectedDocument === null ? "הוספה" : "שמירת שינויים"} onClick={() => this.submit(false)} />
                            <input type="button" className="btn btn-user btn-custom btn-block" style={{ backgroundColor: Colors.PRIMARY_YELLOW }} value={this.state.selectedDocument === null ? "הוספה ובניית תבנית" : "שמירת שינויים ובניית תבנית"} onClick={() => this.submit(true)} />
                        </div>
                }

                <div className="mt-3 text-right mr-2"><span className="text-success">{this.state.successMessage}</span></div>
                <div className="mt-3 text-right mr-2"><span className="text-danger">{this.state.errorMessage}</span></div>
            </Fragment>
        );
    }

    makeTokenFromJson = async (docId) => {
        try{
            
            let user = await GetLoggedInUserInfo();
            let userId = user?.user?._id;

            let bodyObj = {
                docId,
                tokenId: null,
                userId,
                app: APP_OPTIONS.ESIGN,
                tmc: true
            }

            let url = `${ENV.API_URL}/${ENV.USERS_API_URL}/makeTokenFromJson`;
            let res = await HttpRequest(url, bodyObj, HttpRequestTypes.POST, GetLoggedInUserToken());
      
            if (res) {
              return res.token;
            }
        } catch(e) {
            console.error(e);
        }
    }

    render() {
        return (
            <>
                <div className="mt-3 d-flex justify-content-between align-items-center home-container">
                    <div>
                        {this.state.step === STEPS.DOCUMENTS_STEP &&
                            <button className="btn prim-btn no-animation custom-box-shadow" style={{}}
                                onClick={this.uploadDocument}>
                                <label style={{ margin: 0, padding: 0, fontWeight: "bold", cursor: "pointer" }}>
                                    <i className="fa fa-plus" style={{ marginRight: 5 }}></i>  העלאת מסמך
                                </label>
                            </button>
                        }
                    </div>
                    <div className="text-right">
                        <h4 style={{ fontWeight: 'bold' }}>מפת המסמכים - {this.getTitleBasedOnStep()}</h4>
                        {this.state.step !== STEPS.REGIONS_STEP && (
                            <>
                                <div>
                                    <label style={{ textAlign: "right", fontWeight: "bold", fontSize: 15 }}>
                                        <span><span className='underline-onhover' onClick={() => { this.setState({ step: STEPS.COUNCILS_STEP }) }}>{this.state.step > STEPS.REGIONS_STEP ? this.state.regionObj.name : ''}</span></span>
                                        <span>{this.state.step > STEPS.COUNCILS_STEP ? ' > ' : ''} <span className='underline-onhover' onClick={() => { this.setState({ step: STEPS.CATEGORIES_LEVEL1_STEP }) }}>{this.state.step > STEPS.COUNCILS_STEP ? this.state.council : ''}</span> </span>
                                        <span>{this.state.step > STEPS.CATEGORIES_LEVEL1_STEP ? ' > ' : ''} <span className='underline-onhover' onClick={() => { this.setState({ step: STEPS.CATEGORIES_LEVEL2_STEP }) }}>{this.state.step > STEPS.CATEGORIES_LEVEL1_STEP ? this.state.superCategoryName : ''}</span></span>
                                        <span>{this.state.step > STEPS.CATEGORIES_LEVEL2_STEP ? ' > ' : ''} <span className='underline-onhover' onClick={() => { this.setState({ step: STEPS.CATEGORIES_LEVEL3_STEP }) }}>{this.state.step > STEPS.CATEGORIES_LEVEL2_STEP ? this.state.subCategoryName : ''}</span> </span>
                                        <span>{this.state.step > STEPS.CATEGORIES_LEVEL3_STEP ? ' > ' : ''} <span className='underline-onhover' onClick={() => { this.setState({ step: STEPS.DOCUMENTS_STEP }) }}>{this.state.step > STEPS.CATEGORIES_LEVEL3_STEP ? this.state.category : ''}</span></span>
                                    </label>
                                </div>
                                <label onClick={() => this.setState({ step: this.state.step - 1 })} style={{ cursor: "pointer", textAlign: "right", fontWeight: "bold", fontSize: 17 }}>חזור <i className="fa fa-chevron-right"></i> </label>
                            </>
                        )}
                    </div>
                </div>

                <div className="mt-3 mb-3 dir-rtl steps-container">
                    {this.state.step === STEPS.DOCUMENTS_STEP ? (
                        this.renderDocumentsStep()
                    ) : (
                        this.renderFoldersSteps()
                    )}
                </div>


                <Modal
                    ariaHideApp={false}
                    isOpen={this.state.isModalVisible}
                    style={customStyles}>

                    <div className='modal-content-container'>
                        <div className="d-flex justify-content-between align-items-center">
                            <div></div>
                            <div>
                                <label className="labelModal">
                                    {this.state.selectedDocument === null ? "הוספת מסמך חדש" : "עריכת פרטי מסמך"}
                                </label>
                            </div>
                            <div>
                                <i className="fa fa-times pointer" onClick={this.closeModal}></i>
                            </div>
                        </div>

                        <div style={{ direction: 'rtl', marginTop: 20, textAlign: 'right' }}>
                            {this.renderModalContent()}
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}

export default FilesMap