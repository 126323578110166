import React, { Component, Fragment } from 'react';
import DataTable from 'react-data-table-component';

export default class ContactListDataTable extends Component{

    state = {
        columns: [
            {name: '', sortable: false, center: true, style: {fontSize: 14},
                cell: row => <Fragment>
                <button className="icon-button" data-tooltip="מחיקה" onClick={() => this.props.deleteContact(row._id)}>
                    <span className="tooltiptext">מחיקה</span>
                    <i className="eye fa fa-trash text-danger"></i>
                </button>
                <button className="icon-button ml-2" data-tooltip="עריכה" onClick={() => this.props.editContact(row._id, row.title, row.emails)}>
                    <span className="tooltiptext">עריכה</span>
                    <i className="download fa fa-pencil"></i>
                </button>
            </Fragment>
            },
            {name: 'דוא"ל', sortable: true, selector: 'emails', center: true, style: {fontSize: 14},
                cell: row => <label style={{textAlign: "center"}}>
                    {row.emails.join(', ')}
                </label>},
            {name: 'שם רשימת התפוצה', sortable: true, selector: 'title', center: true, style: {fontSize: 14}},
        ],
        emptyColumns: [
            {name: '', sortable: false, selector: 'actions', center: true},
            {name: 'דוא"ל', sortable: true, selector: 'emails', center: true, style: {fontSize: 14}},
            {name: 'שם רשימת התפוצה', sortable: true, selector: 'title', center: true, style: {fontSize: 14}},
        ],
        emptyColumnsData: [{_id: '', emails: '', title: '', actions: ''}],
    }

    componentDidMount(){

        //Change Text OF "Rows per page: " by it's class
        let rowsPerPageText = document.getElementsByClassName("gckGBs");
        if(rowsPerPageText && rowsPerPageText.length > 0){
            rowsPerPageText[0].innerHTML = "שורות בכל דף"
        }
    }

    render(){
        return (
            (!this.props.data || this.props.data.length === 0) ? 
            <div className="empty-container custom-box-shadow">

            <DataTable
                columns={this.state.emptyColumns}
                data={this.state.emptyColumnsData}
                pagination={true}
            />

                <div className="text-center empty-container-inner">
                    <h3>לא נמצאו רשימות תפוצה</h3>
                </div>
            </div>
            :
            <DataTable
                columns={this.state.columns}
                data={this.props.data}
                pagination={true}
            />
        );
    }
}