import React from 'react';
import {Route, Switch} from 'react-router-dom';
import { SCREEN_NAMES } from '../constants';
import Home from '../screens/Home';
import Login from '../screens/Login';
import NotFound from '../screens/NotFound';
import Settings from '../screens/Settings'
import Contact from '../screens/Contact'
import ForgetPassword from '../screens/ForgetPassword';
import Register from '../screens/Register';
import Profile from '../screens/Profile';
import ContactsList from '../screens/ContactsList';
import Admin from '../screens/Admin';
import Pricing from '../screens/Pricing';
import Success from '../screens/Success';
import Cancel from '../screens/Cancel';
import DragDrop from '../screens/DragDropPDF';

const AppRouter = (props) => {
    return (
            <Switch>
                <Route exact path={SCREEN_NAMES.HOME_SCREEN} component={Home} />
                <Route exact path={SCREEN_NAMES.LOGIN_SCREEN} component={Login} />
                <Route exact path={SCREEN_NAMES.SETTINGS_SCREEN} component={Settings} />
                <Route exact path={SCREEN_NAMES.CONTACT_SCREEN} component={Contact} />
                <Route exact path={SCREEN_NAMES.PROFILE_SCREEN} component={Profile} />
                <Route exact path={SCREEN_NAMES.FORGET_PASSWORD_SCREEN} component={ForgetPassword}/>
                <Route exact path={SCREEN_NAMES.REGISTER_SCREEN} component={Register}/>
                <Route exact path={SCREEN_NAMES.CONTACTS_LIST_SCREEN} component={ContactsList}/>
                <Route exact path={SCREEN_NAMES.ADMIN_SCREEN} component={Admin}/>
                <Route exact path={SCREEN_NAMES.PRICING_SCREEN} component={Pricing}/>
                <Route exact path={SCREEN_NAMES.PAYMENT_SUCCESS_SCREEN} component={Success}/>
                <Route exact path={SCREEN_NAMES.PAYMENT_CANCEL_SCREEN} component={Cancel}/>
                <Route exact path={SCREEN_NAMES.DROPZONE} component={DragDrop}/>

                <Route component={NotFound} />
            </Switch>
    );
}

export default AppRouter;
